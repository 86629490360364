import React, { Fragment, createContext, useContext } from "react";
import PropTypes from "prop-types";

import SignUpModal from "templates/customModals/SignUpModal";
import LoginModal from "templates/customModals/LoginModal";

import messageMap from "Utilities/MessageMaps";

export function askUserToSignUpFirst(setSignUpModal, setLoginModal, callback, defaultTitle = false, additionalSubheader) {
	setSignUpModal(
		<SignUpModal
			title={!defaultTitle ? messageMap("account.signUp.signUpFirst", "generic") : messageMap("account.signUp.header", "generic")}
			closeModal={e => closeSignUpModal(e, setSignUpModal, callback)}
			subHeader={(
				<Fragment>
					<div>
						{additionalSubheader}
						{messageMap("account.signUp.existingAccount", "generic")}
						<button onClick={() => openAnotherModal("login", 1, setSignUpModal, setLoginModal, callback)}
							style={{ border: "none", color: "#007bff", backgroundColor: "white" }}>
							{messageMap("links.login", "button")}
						</button>
					</div>
				</Fragment>
			)} />
	);
}

export function askUserToAuthFirst(setAuthModals) {
	const { setSignUpModal, setLoginModal } = setAuthModals;
	askUserToSignUpFirst(setSignUpModal, setLoginModal);
}

function openAnotherModal(type, layer, setSignUpModal, setLoginModal, callback) {
	if (type === "login") {
		setLoginModal(
			<LoginModal closeHandler={e => closeLoginModal(e, setSignUpModal, setLoginModal, callback)}
				submitHandler={e => closeLoginModal(e, setSignUpModal, setLoginModal, callback)} />
		);
	}
}

function closeLoginModal(e, setSignUpModal, setLoginModal, callback) {
	if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
		setLoginModal(null);
		callback && callback("login");
	}
	else if (e === null) {
		setLoginModal(null);
		setSignUpModal(null);
		callback && callback("login");
	}
}

function closeSignUpModal(e, setSignUpModal, callback) {
	const className = e.target.className;

	if (["modal-block", "cancel", "close-button", "submit", "icon"].includes(className)) {
		setSignUpModal(null);
		callback && callback("signUp");
	}
}