export const pageMenuNavigationMessageMap = {
	home: {
		learners: "Learners",
		tutors: "Tutors"
	},
	tutoring: {
		requests: {
			newRequest: "New request",
			openRequests: "Open requests",
			pendingRequests: "Pending requests"
		},
		sessions: {
			upcomingSessions: "Upcoming sessions",
			liveSessions: "Live sessions",
			pastSessions: "Past sessions"
		},
		profile: {
			overview: "Overview",
			reviews: "Reviews",
			scheduledSessions: "Scheduled sessions",
			liveSessions: "Live sessions",
			pastSessions: "Past sessions"
		},
		faq: {
			tutors: "Tutors",
			tutees: "Tutees"
		}
	}
};