import React, {useEffect, useRef, useState} from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import TabbedPages from "templates/TabbedPages";

import RequestList from "pages/Tutoring/subPages/subMenus/RequestList";
import NewRequest from "pages/Tutoring/subPages/subMenus/NewRequest";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import {
	TUTORING_REQUESTS_TABS, REQUESTS_NEW_REQUEST, REQUESTS_OPEN_REQUESTS
} from "templates/utilities/PageTabs";
import { OPEN_STATE, PENDING_STATE } from "pages/Tutoring/utilities/TutoringRequestConstants";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";



function RequestTutoring(props) {

	const history = useHistory();

	const [newRequestDom, setNewRequestDom] = useState(),
		[requestListDom, setRequestListDom] = useState(),
		[requestListPendingDom, setRequestListPendingDom] = useState(),
		[emptyListMessage, setEmptyListMessage] = useState();

	const newRequestDomRef = useRef(),
		requestListDomRef = useRef(),
		emptyListRef = useRef(false),
		emptyOpenListRef = useRef(false),
		emptyPendingListRef = useRef(false);

	useEffect(() => {
		reRenderList(0);
		reRenderNewRequestForm();

		const urlParams = new URLSearchParams(window.location.search);
		const sub = urlParams.get("sub");
		if (sub != null) {
			showSubMenuPage(sub);
		}
	}, [props.ownerId]);

	function showSubMenuPage(chosenMenuPage) {
		if (chosenMenuPage === REQUESTS_NEW_REQUEST) {
			newRequestDomRef.current.className = "new-request";
			history.push(`/tutoring?show=requests&sub=${REQUESTS_NEW_REQUEST}`, { shallow: true });

			if (emptyListRef.current) {
				requestListDomRef.current.className = "new-request hide";
			}
			else {
				requestListDomRef.current.className = "request-list hide";
			}
		}
		else if (chosenMenuPage === REQUESTS_OPEN_REQUESTS) {
			newRequestDomRef.current.className = "new-request hide";
			history.push(`/tutoring?show=requests&sub=${REQUESTS_OPEN_REQUESTS}`, { shallow: true });

			if (emptyListRef.current) {
				requestListDomRef.current.className = "new-request";
			}
			else {
				requestListDomRef.current.className = "request-list";
			}
		}
	}

	function reRenderNewRequestForm(requestListKey, navigateToTab) {
		setNewRequestDom(
			<NewRequest key={`${requestListKey}_new`} setAlert={props.setAlert} setModal={props.setModal} setModal1={props.setModal1}
				setLoginModal={props.setLoginModal} reRenderList={reRenderNewRequestForm} />
		);

		if (navigateToTab) {
			reRenderList(requestListKey, navigateToTab);
			showSubMenuPage(REQUESTS_OPEN_REQUESTS);
		}
		if (requestListKey === 0) {
			indicateEmptyList();
		}
		else {
			setEmptyListMessage(null);
			emptyListRef.current = false;
		}
	}

	function reRenderList(listCount, navigateToTab = false) {
		setRequestListDom(
			<RequestList key={`${listCount}_open`} listType={OPEN_STATE}
				emptyListHandler={e => indicateEmptyList("open")} refreshOpenRequestCount={reRenderNewRequestForm}
				setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
		);
		setRequestListPendingDom(
			<RequestList key={`${listCount}_pending`} listType={PENDING_STATE}
				emptyListHandler={e => indicateEmptyList("pending")} refreshOpenRequestCount={reRenderNewRequestForm}
				setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
		);
	}

	function indicateEmptyList(openOrEmpty) {
		let emptyMessage;

		if (props.ownerId) {
			if (emptyOpenListRef.current && emptyPendingListRef.current) {
				emptyMessage = (
					<div className="sign-up-or-empty">
						{messageMap("tutoringPage.tutorList.emptyOpenRequestsTutee", "generic")}
						<Link to="/tutoring?show=applications" className="here-link"
						onClick={defaultLinkAction}>
							{`${messageMap("here.text", "button")}.`}
						</Link>
					</div>
				);
			}
			else if (openOrEmpty) {
				const emptyMap = {
					emptyOpen: emptyOpenListRef,
					emptyPending: emptyPendingListRef
				};
				emptyMap[openOrEmpty].current = true;
			}
		}
		else {
			emptyMessage = (
				<div className="sign-up-or-empty">
					{messageMap("tutoringPage.tutorList.signUpFirstNewRequests", "generic")}
						<button className="sign-up-button"
							onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
							{messageMap("link.text", "button")}
						</button>
				</div>
			);
		}

		setEmptyListMessage(emptyMessage);
		emptyListRef.current = true;
	}

	return (
		<div>
			<TabbedPages tabType={TUTORING_REQUESTS_TABS} tabClickHandler={showSubMenuPage} />

			<div className="new-request" ref={newRequestDomRef}>
				{newRequestDom}
			</div>

			<div className="request-list hide" ref={requestListDomRef}>
				{requestListDom}
				{requestListPendingDom}
				{emptyListMessage}
			</div>
		</div>
	);
}

RequestTutoring.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(RequestTutoring);
