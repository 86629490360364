/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import { getFormData } from "Utilities/Upload";
import { $replace } from "Utilities/Strings";
import { STRIPE_CONNECT_ACCOUNT_FORM } from "Utilities/Constants/MediaConstants";

const BASE = "/services/payouts";

const SAVE_STRIPE_CONNECT_ACCOUNT = BASE + "/save/connectUser";
const USER_HAS_CONNECT_ACCOUNT = BASE + "/has/connectAccount";
export const GET_CONNECT_ACCOUNT_ADDITIONAL_REQUIREMENTS = BASE + "/get/connectAccount/requirementUpdates";
export const GET_COUNTRY_WITHDRAWAL_MINIMUM = BASE + "/get/withdrawalMinimum?countryCode=${countryCode}";
const WITHDRAW_EARNINGS = BASE + "/withdraw";

const GET_BANKING_REQUIREMENTS = BASE + "/get/bankingRequirements";


export function saveStripeConnectAccountAPI(formDataPayload, relevantFields, callback) {
	const formData = getFormData(formDataPayload, relevantFields, STRIPE_CONNECT_ACCOUNT_FORM);
	request(SAVE_STRIPE_CONNECT_ACCOUNT, POST, formData, callback, null, null, false);
}

/**
 * @param {String} payload = ownerId
 */
export function userHasConnectAccountAPI(payload, callback) {
	request(USER_HAS_CONNECT_ACCOUNT, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getConnectAccountAdditionalRequirementsAPI(payload, callback) {
	request(GET_CONNECT_ACCOUNT_ADDITIONAL_REQUIREMENTS, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	countryCode : String
 * }
 */
export function getCountryWithdrawalMinimumAPI(pathVariables, callback) {
	const api = $replace(GET_COUNTRY_WITHDRAWAL_MINIMUM, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function withdrawEarningsAPI(payload, callback) {
	request(WITHDRAW_EARNINGS, POST, payload, callback);
}

export function getBankingRequirementsAPI(callback) {
	request(GET_BANKING_REQUIREMENTS, GET, null, callback);
}