import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Alert, { ERROR } from "./Alert";

import messageMap from "Utilities/MessageMaps";
import { imageValidator } from "Utilities/Validators/InputValidators";

import uploadAsset from "assets/icons/common/upload.svg";
import { getFileFormatFromTargetFile } from "Utilities/FileUtility";

/**
 * @description assumes that this component will only be used for uploading an image
 * @param {Object} validation = {
 * 	fileFormats: [String] // mime types (i.e. .png, .jpg, etc.),
 * 	widthRange: [Number, Number],
 * 	heightRange: [Number, Number],
 * 	memoryLimit: Number // file size limit in MiB
 * } 
 */
function UploadDocumentPicture(props) {

	const [metaContainerState, setMetaContainerState] = useState();
	const [filename, setFilename] = useState();

	const borderRef = useRef();
	const imgRef = useRef();

	function analyzeUpload(e, acceptedFileFormats) {
		if (!e.target.files.length) {
			return;
		}

		const target = e.target;
		const targetFile = target.files[0];
		const fileFormat = getFileFormatFromTargetFile(targetFile);
		acceptedFileFormats = acceptedFileFormats.map(format => 
			format.replace(".", "")
		);
		if (!acceptedFileFormats.includes(fileFormat)) {
			const msg = `${messageMap("img.fileType.notAccepted", "validation") + fileFormat}. ${messageMap("img.fileType.whatIsAccepted", "validation") + acceptedFileFormats.join(", ")}`;
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={msg} />
			);

		}
		else {
			if (targetFile !== undefined && targetFile !== null && props.validation != null) {
				setMetaContainerState(
					<img src={URL.createObjectURL(targetFile)} alt="uploaded profile pic" style={{display: "none"}}
							onLoad={imgData => checkProfilePicConstraints(imgData, targetFile)}></img>
				);
			}
		}
	}

	function checkProfilePicConstraints(imgData, uploadedProfilePic) {
		// image requirements came from Stripe
		const {errorMsgs, hasViolations} = imageValidator(
			props.validation.fileFormats.map(format => format.replace(".", "")),
			props.validation.widthRange,
			props.validation.heightRange,
			props.validation.memoryLimit,
			imgData,
			uploadedProfilePic
		);

		if (hasViolations) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={errorMsgs} />
			);
			imgRef.current.src = uploadAsset;
		}
		else {
			imgRef.current.src = URL.createObjectURL(uploadedProfilePic);
			borderRef.current.className = "borders no-padding";
			imgRef.current.className = "picture-overlay";

			setFilename(uploadedProfilePic.name);
			props.uploadedFileSetter(uploadedProfilePic);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	return (
		<div className="upload-document-picture">
			{metaContainerState}

			<div className="picture-side">
				{props.label}
			</div>
			<div className="borders" ref={borderRef}>
				<label className="file-upload-container">
					<input type="file" accept={props.fileTypes.join(", ")}
						onChange={e => analyzeUpload(e, props.fileTypes)} className="upload-input"
						required="required" aria-required="true"/>
					<img src={uploadAsset} alt="camera" className="camera-icon" ref={imgRef} />
				</label>
			</div>
			<div className="uploaded-filename-container">
				{filename}
			</div>
		</div>
	);
}

UploadDocumentPicture.defaultProps = {
	fileTypes: []
};

UploadDocumentPicture.propTypes = {
	setAlert: PropTypes.func.isRequired,
	uploadedFileSetter: PropTypes.func.isRequired,

	label: PropTypes.string.isRequired,
	fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	validation: PropTypes.object
};

export default UploadDocumentPicture;

