import React from 'react';

import messageMap from 'Utilities/MessageMaps';

import bienAsset from "assets/images/home/bien.png";
import gustavoAsset from "assets/images/home/gustavo.png";
import paulineAsset from "assets/images/home/pauline.png";


function Team(props) {
	return (
		<div className="team">
			<h2 className="title">
				{messageMap("home.team.meet", "generic")}
			</h2>

			<div className="team-members">
				<div className="member-container">
					<img src={bienAsset}></img>
					<div className="member-name">
						Bienvinido Onia
					</div>
					<div className="member-title">
						Founder, CEO, CTO
					</div>
				</div>

				<div className="member-container">
					<img src={gustavoAsset}></img>
					<div className="member-name">
						Gustavo Ramirez
					</div>
					<div className="member-title">
						Co-Founder, CCO
					</div>
				</div>

				<div className="member-container">
					<img src={paulineAsset}></img>
					<div className="member-name">
						Pauline Nguyen
					</div>
					<div className="member-title">
						Co-Founder, CDEO
					</div>
				</div>
			</div>

		</div>
	);
}

export default Team;