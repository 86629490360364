import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";


function FooterCTA(props) {

	function openSignUpModal() {
		askUserToSignUpFirst(props.setSignUpModal, props.setLoginModal, null, true);
	}

	return (
		<div className="footer-cta-container">
			<div className="footer-header">
				{props.header}
			</div>

			<div className="footer-text">
				{props.subHeader}
			</div>

			<div className="footer-cta-actions">
				<button className="cta" onClick={openSignUpModal}>
					{props.signUpText}
				</button>

				{
					props.ctaRedirect &&
					(
						<Link to={props.ctaRedirect} className="cta-footer-link" onClick={defaultLinkAction}>
							{props.ctaText}
						</Link>
					)
				}
			</div>
		</div>
	);
}

FooterCTA.propTypes = {
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string.isRequired,
	signUpText: PropTypes.string.isRequired,
	ctaRedirect: PropTypes.string,
	ctaText: PropTypes.string,

	// parent state setters
	setSignUpModal: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired
};

export default FooterCTA;
