import React from "react";
import PropTypes from "prop-types";

import BenefitItem from "../components/BenefitItem";
import Testimonial from "../components/Testimonial";
import FooterCTA from "../components/FooterCTA";

import messageMap, {messageMapGeneric} from "Utilities/MessageMaps";

import tutorPictureAsset from "assets/images/home/discoverTutors.svg";
import videosArticlesAsset from "assets/images/home/videoArticles.svg";
import practiceProblemsAsset from "assets/images/home/practiceProblems.svg";
import rondaWilliamsAsset from "assets/images/testimonials/rondaWilliams.jpg";
import dallasCarterAsset from "assets/images/testimonials/dallasCarter.jpg";
import kevinQuinlanAsset from "assets/images/testimonials/kevinQuinlan.jpg";


function LandingForLearners(props) {

	const detailsMap = [
		{
			header: messageMapGeneric("landing.learners.detail1.header"),
			info: (
				<ul>
					{messageMapGeneric("landing.learners.detail1.info").map((info, key) =>
						<li key={`detail1_${key}`}>
							{info}
						</li>
					)}
				</ul>
			),
			linkText: messageMap("landing.learner.detail1", "button"),
			linkPath: "/tutoring?show=tutorsList",
			imgSrc: tutorPictureAsset,
			imgAlt: messageMap("landing.learner.discoverTutors", "image")
		}, {
			header: messageMapGeneric("landing.learners.detail2.header"),
			info: (
				<ul>
					{messageMapGeneric("landing.learners.detail2.info").map((info, key) =>
						<li key={`detail1_${key}`}>
							{info}
						</li>
					)}
				</ul>
			),
			linkText: messageMap("landing.learner.detail2", "button"),
			linkPath: "/video-list",
			imgSrc: videosArticlesAsset,
			imgAlt: messageMap("landing.learner.videosArticles", "image")
		}, {
			header: messageMapGeneric("landing.learners.detail3.header"),
			info: (
				<ul>
					{messageMapGeneric("landing.learners.detail3.info").map((info, key) =>
						<li key={`detail1_${key}`}>
							{info}
						</li>
					)}
				</ul>
			),
			linkText: messageMap("landing.learner.detail3", "button"),
			linkPath: "/practice-category",
			imgSrc: practiceProblemsAsset,
			imgAlt: messageMap("landing.learner.practiceProblems", "image")
		}
	];

	const testimonialsMap = [
		{
			name: messageMapGeneric("landing.testimonials.rondaWilliams.name"),
			title: messageMapGeneric("landing.testimonials.rondaWilliams.title"),
			testimonial: messageMapGeneric("landing.testimonials.rondaWilliams.review"),
			imgSrc: rondaWilliamsAsset,
			imgAlt: messageMap("landing.testimonials.rondaWilliams", "image")
		}, {
			name: messageMapGeneric("landing.testimonials.dallasCarter.name"),
			title: messageMapGeneric("landing.testimonials.dallasCarter.title"),
			testimonial: messageMapGeneric("landing.testimonials.dallasCarter.review"),
			imgSrc: dallasCarterAsset,
			imgAlt: messageMap("landing.testimonials.dallasCarter", "image")
		}, {
			name: messageMapGeneric("landing.testimonials.kevinQuinlan.name"),
			title: messageMapGeneric("landing.testimonials.kevinQuinlan.title"),
			testimonial: messageMapGeneric("landing.testimonials.kevinQuinlan.review"),
			imgSrc: kevinQuinlanAsset,
			imgAlt: messageMap("landing.testimonials.kevinQuinlan", "image")
		}
	];

	return (
		<div className="more-details-body-container">
			{
				detailsMap.map((detail, key) =>
					<BenefitItem key={`learnerBenefit_${key}`} 
						header={detail.header} info={detail.info}
						linkText={detail.linkText} linkPath={detail.linkPath}
						imgSrc={detail.imgSrc} imgAlt={detail.imgAlt} />
				)
			}

			<div className="testimonial-section">
				<div className="testimonial-header">
					{messageMapGeneric("landing.testimonials.header")}
				</div>

				<div className="testimonials">
					{
						testimonialsMap.map((review, key) =>
							<Testimonial key={`review_${key}`}
								imgSrc={review.imgSrc} imgAlt={review.imgAlt}
								name={review.name} title={review.title}
								testimonial={review.testimonial} />
						)
					}
				</div>
			</div>

			<FooterCTA header={messageMapGeneric("landing.learners.footer.header")}
				subHeader={messageMapGeneric("landing.learners.footer.subHeader")}
				signUpText={messageMap("landing.learner.joinFree", "button")}
				ctaRedirect="/subscription" ctaText={messageMap("landing.learner.viewPlans", "button")}
				setSignUpModal={props.setSignUpModal} setLoginModal={props.setLoginModal} />
		</div>
	);
}

LandingForLearners.propTypes = {
	setSignUpModal: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired
};

export default LandingForLearners;