import React, {useEffect, useState, Fragment} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setProfileSubNav } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Dropdown from "templates/Dropdown";
import Tooltip from "templates/Tooltip";
import Alert, {ERROR, SUCCESS, WARNING} from "templates/Alert";
import Modal from "templates/Modal";

import { TUTOR_REQUIREMENTS } from "pages/FAQs/TutoringFAQs/tutors/TutorRequirements";
import { TUTOR_APPLICATION_PROCESS } from "pages/FAQs/TutoringFAQs/tutors/ApplicationProcess";
import { TUTORING_PRICING_STRUCTURE } from "pages/FAQs/TutoringFAQs/tutors/PricingStructure";
import { TUTORING_PAYMENT_STRUCTURE } from "pages/FAQs/TutoringFAQs/tutors/PaymentStructure";
import { TUTORING_PROCESS } from "pages/FAQs/TutoringFAQs/tutors/TutoringProcess";

import FluentLanguages from "./tutorComponents/FluentLanguages";
import SubjectTopicDropdowns from "./tutorComponents/SubjectTopicDropdowns";
import FifteenMinuteTrials from "./tutorComponents/FifteenMinuteTrials";
import TutorNotifications from "./tutorComponents/TutorNotifications";
import Schedule from "./tutorComponents/Schedule";
import TeachingRates from "./tutorComponents/TeachingRates";
import CancellationPolicy from "./tutorComponents/CancellationPolicy";

import TutorPolicy from "pages/Policies/TeacherPolicy/TutorPolicy";

import { filenameReg } from "Utilities/Constants/RegExConstants";
import { checkObjectKeysExist } from "Utilities/ObjectUtility";
import {fileSizeConvert, KB} from "Utilities/FileSizeConverter";
import {imageValidator} from "Utilities/Validators/InputValidators";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {
	fullCountriesList, availableCountries, countriesToAlphaCode, countryAlphaCodeToName,
	teachableSubjects, topicsInSubjects, topicsDifficultyOrder,
	OVER, BETWEEN, UNDER,
	SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import {
	transformCancellationPoliciesStringToObject, transformTeachingRatesStringToObject
} from "pages/Tutoring/utilities/TutorProfileUtility";
import {
	COUNTRY, SCREENING_QUESTIONS_RESPONSE, PROFILE_PICTURE,
	FIRST_NAME, LAST_NAME, OCCUPATION, BIO, PHONE_NUMBER,
	NOTIFICATION_PREFERENCE, TRIAL_PREFERENCE,
	FLUENT_LANGUAGES, TEACHING_RATES, CANCELLATION_POLICIES, SCHEDULES,
	RESUME, TEACHING_CERTIFICATION, PROFESSIONAL_EXPERIENCE, PROOF_OF_EDUCATION,
	LINKED_IN_URL, WEBSITE_URL, SUBJECTS_TO_TOPICS_MAP, ACCEPTED_AGREEMENTS
} from "Utilities/Constants/MediaConstants";
import { ALERT_THIRTY_SECONDS_TIMEOUT } from "Utilities/Constants/TimeoutConstants";
import { ACCOUNT } from "templates/utilities/PageSubMenu";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { sendVerificationEmailAPI } from "apis/controllers/person/AccountsController";
import {
	userHasConnectAccountAPI, getConnectAccountAdditionalRequirementsAPI
} from "apis/controllers/transactions/PayoutsController";
import { saveTutorProfileAPI } from "apis/controllers/tutoring/TutorProfileController";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";
import blankProfilePhotoAsset from "assets/icons/common/blank-profile-photo.svg";
import chevronAsset from "assets/icons/common/chevron.svg";
import greenCheckAsset from "assets/icons/common/green-check.svg";
import { getFileFormatFromTargetFile } from "Utilities/FileUtility";


function TutorProfile(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const { 
		isApplication, accountVerified,
		profilePictureUploadLocation, firstName, lastName,
		phoneNumber, notificationPreference,
		fluentLanguages,
		country,
		trialPreference, teachingRates, cancellationPolicies, schedules,
		subjectTopics,
		screeningDocument, resumeDocument, teachingDocument, professionalDocument, educationProofDocument,
		requiredFields, accountNumberSample, routingNumberSample
	} = props;

	const history = useHistory();

	// input filenames
	const [screeningQuestionsFilename, setScreeningQuestionsFilename] = useState(""),
		[resumeFilename, setResumeFilename] = useState(""),
		[teachingCertificationFilename, setTeachingCertificationFilename] = useState(""),
		[experienceProofFilename, setExperienceProofFilename] = useState(""),
		[educationProofFilename, setEducationProofFilename] = useState(""),
		// dynamically rendered dropdowns
		[subjectTopicsDropdowns, setSubjectTopicsDropdowns] = useState(),
		// metadata loader
		[metaContainerState, setMetaContainerState] = useState(),
		// input text
		[firstNameInputValue, setFirstNameInputValue] = useState(firstName),
		[lastNameInputValue, setLastNameInputValue] = useState(lastName),
		[linkedInInputValue, setLinkedInInputValue] = useState(props.linkedInUrl),
		[websiteInputValue, setWebsiteInputValue] = useState(props.webUrl),
		[agreementFullName, setAgreementFullName] = useState((firstName || "") + " " + (lastName || "")),
		// profile photo source
		[profilePhotoSrc, setProfilePhotoSrc] = useState(blankProfilePhotoAsset),
		[validProfilePhoto, setValidProfilePhoto] = useState(true),
		// DOM status
		[renderTeachingRatesDOM, setRenderTeachingRatesDOM] = useState(),
		// for blocking inputs
		[disableInteraction, setDisableInteraction] = useState(false),
		// VALUES TO UPLOAD
		// input texts
		[occupation, setOccupation] = useState(props.occupation),
		[bio, setBio] = useState(props.bio),
		// input phone numbers
		[phoneNumberInputValue, setPhoneNumberInputValue] = useState(phoneNumber),
		// input checkboxes
		[notificationPreferenceValue, setNotificationPreferenceValue] = useState(transformNotificationsStringToObject(notificationPreference)),
		// input radio button
		[trialPreferenceValue, setTrialPreferenceValue] = useState(trialPreference),
		// dropdown values
		[countryValue, setCountryValue] = useState(country),
		[fluentLanguagesValue, setFluentLanguagesValue] = useState(transformFluentLanguagesStringToArray(fluentLanguages)),
		[teachingRatesValue, setTeachingRatesValue] = useState(transformTeachingRatesStringToObject(teachingRates)),
		[cancellationPoliciesValue, setCancellationPoliciesValue] = useState(transformCancellationPoliciesStringToObject(cancellationPolicies, "tutor")),
		[schedulesValue, setSchedulesValue] = useState(transformScheduleStringToObject(schedules)),
		// files
		[screeningQuestionsFile, setScreeningQuestionsFile] = useState(null),
		[profilePicFile, setProfilePicFile] = useState(null),
		[resumeFile, setResumeFile] = useState(null),
		[teachingCertificationFile, setTeachingCertificationFile] = useState(null),
		[experienceProofFile, setExperienceProofFile] = useState(null),
		[educationProofFile, setEducationProofFile] = useState(null),
		// CHEVRONS
		[requirementsClass, setRequirementsClass] = useState("requirements-dropdown open"),
		[requirementChevron, setRequirementChevron] = useState("chevron-up"),
		[applicationProcessClass, setApplicationProcessClass] = useState("application-process-dropdown open"),
		[applicationProcessChevron, setApplicationProcessChevron] = useState("chevron-up"),
		[pricingStructureClass, setPricingStructureClass] = useState("pricing-structure-dropdown open"),
		[pricingStructureChevron, setPricingStructureChevron] = useState("chevron-up"),
		[paymentStructureClass, setPaymentStructureClass] = useState("payment-structure-dropdown open"),
		[paymentStructureChevron, setPaymentStructureChevron] = useState("chevron-up"),
		[tutoringProcessClass, setTutoringProcessClass] = useState("tutoring-process-dropdown open"),
		[tutoringProcessChevron, setTutoringProcessChevron] = useState("chevron-up");

	// tooltips
	const [occupationTooltip, setOccupationTooltip] = useState();
	const [stripeConnectTooltip, setStripeConnectTooltip] = useState();

	const [subjectsToTopics, setSubjectsToTopics] = useState();
	const [userConnectAccountStatus, setUserConnectAccountStatus] = useState("unverified");
	const [userHasConnectAccount, setUserHasConnectAccount] = useState(false);

	useEffect(() => {
		setSavedFields();
		checkStripeConnectAccountStatus();
	}, []);


	function setSavedFields() {
		// filenames
		screeningDocument && setScreeningQuestionsFilename(screeningDocument.match(filenameReg)[0]);
		resumeDocument && setResumeFilename(resumeDocument.match(filenameReg)[0]);
		teachingDocument && setTeachingCertificationFilename(teachingDocument.match(filenameReg)[0]);
		professionalDocument && setExperienceProofFilename(professionalDocument.match(filenameReg)[0]);
		educationProofDocument && setEducationProofFilename(educationProofDocument.match(filenameReg)[0]);

		profilePictureUploadLocation && setProfilePhotoSrc(profilePictureUploadLocation);

		// set dropdown to pre-selected value
		if (subjectTopics) {
			const subjectTopicTuples = subjectTopics.split("|");
			let subjects = [];
			let topics = [];
			for (let i = 0; i < subjectTopicTuples.length; ++i) {
				const tuple = subjectTopicTuples[i].split("_");
				const subjectVal = tuple[0];
				const topicVal = tuple[1];

				if (!subjects.includes(subjectVal)) {
					subjects.push(subjectVal)
				}
				if (!topics.includes(topicVal)) {
					topics.push(topicVal);
				}
			}

			if (isApplication) {
				setSubjectTopicsDropdowns(
					<SubjectTopicDropdowns preSelectedSubject={subjects[subjects.length - 1]} preSelectedTopic={topics[topics.length - 1]}
						disableSubjectDropdown={disableInteraction} isApplication={isApplication}
						setSubjectsToTopics={setSubjectsToTopics} />
				);
			}
			else if (!isApplication) {
				let allowedTeachableTopics = structuredClone(topicsInSubjects);
				if (!isApplication) {
					let subjectTopicMap = {};
					let subjectsToTopicsMap = {};
					subjectTopics.split("|").forEach(el => {
						const subjectVal = el.split("_")[0];
						const topicVal = el.split("_")[1];

						if (subjectsToTopicsMap[subjectVal] == null) {
							subjectsToTopicsMap[subjectVal] = [];
						}
						subjectTopicMap[topicVal] = topicsInSubjects[subjectVal][topicVal];
						subjectsToTopicsMap[subjectVal].push(topicVal);
					});

					allowedTeachableTopics = subjectTopicMap;

					setSubjectsToTopics(subjectsToTopicsMap);
				}

				setRenderTeachingRatesDOM(
					<TeachingRates key={topics.join("_")} setModal={props.setModal} setAlert={props.setAlert}
						teachableTopics={allowedTeachableTopics} subjectsToTeachRef={subjects}
						teachingRates={teachingRatesValue} setTeachingRates={setTeachingRatesValue}
						hasActiveTutoringSessions={props.hasActiveTutoringSessions} />
				);
			}
		}
		else {
			setSubjectTopicsDropdowns(
				<SubjectTopicDropdowns setSubjectsToTopics={setSubjectsToTopics}
					disableSubjectDropdown={disableInteraction} isApplication={isApplication} />
			);
		}

		if (checkObjectKeysExist(props, ["accountVerified", "firstName", "lastName", "country", "screeningDocument", "resumeDocument", "educationProofDocument", "profilePictureUploadLocation", "subjectTopics"])) {
			setAgreementFullName(firstName + " " + lastName);
		}
	}

	function checkStripeConnectAccountStatus() {
		userHasConnectAccountAPI(ownerId, resp => {
			setUserHasConnectAccount(resp);

			if (resp) {
				getConnectAccountAdditionalRequirementsAPI(ownerId, resp => {
					if (resp.currentlyDue != null || resp.errors != null) {
						const verificationStatus = resp.verificationStatus;
						setUserConnectAccountStatus(verificationStatus);

						if (verificationStatus !== "verified") {
							props.setModal(
								<Modal closeType="xButton" closeHandler={closeModal} modalClass="become-tutor-modal"
									title={messageMap("profilePage.tutoring.modal.stripe.accountRequirements", "generic")}>
	
									<div className="stripe-connect">
										<div className="account-group">
											{`${messageMap("payout.connectAccount.created.accountStatus", "api")} ${resp.verificationStatus}`}
										</div>
										{
											resp.verificationDetails != null && (
												<div className="group-details">
													{resp.verificationDetails}
												</div>
											)
										}
	
										{
											resp.currentlyDue != null && (
												<Fragment>
													<div className="account-group">
														{`${messageMap("profilePage.tutoring.modal.stripe.personalInformationRequired", "generic")} ${resp.currentDeadline != null ? (new Date(resp.currentDeadline)).toLocaleDateString() : ""}`}
													</div>
													<ul className="group-details">
														{
															resp.currentlyDue.map((item, index) => 
																<li key={`due_${index}`}>
																	{item}
																</li>
															)
														}
													</ul>
												</Fragment>
											)
										}
	
										{
											resp.errors.length > 0 &&
											(
												<Fragment>
													<div className="account-group">
														{messageMap("profilePage.tutoring.modal.stripe.verificationErrors", "generic")}
													</div>
													<ul className="group-details">
														{
															resp.errors.map((item, index) => 
																<li key={`error_${index}`}>
																	{item}
																</li>
															)
														}
													</ul>
												</Fragment>
											)
										}
	
										<button onClick={goConnectToProfile} className="navigation-button left">
											{messageMap("stripeConnect.goToConnectProfile", "button")}
										</button>
									</div>
								</Modal>
							);
						}
					}
				});
			}
		});
	}
	function goConnectToProfile() {
		history.push({
			pathname: "/stripe-connect",
			state: {
				purpose: "update",
				country: country,
				requiredFields: requiredFields,
				banking: {
					accountNumberSample: accountNumberSample,
					routingNumberSample: routingNumberSample
				}
			}
		});
	}

	function checkCountryAvailability(e) {
		const chosenCountry = e.target.getAttribute("countrykey");

		if (chosenCountry === "chooseCountry") {
			return;
		}
		if (availableCountries.includes(chosenCountry)) {
			setCountryValue(countriesToAlphaCode[chosenCountry]);
			setDisableInteraction(false);
		}
		else {
			setDisableInteraction(true);
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={`${chosenCountry} ${messageMap("profilePage.tutoring.modal.country.notSupported", "generic")}`} />
			);
		}
	}

	function sendEmailAccountVerification() {
		sendVerificationEmailAPI(ownerId, resp => {
			let alertType;
			let alertMsg;
			if (resp.includes("success")) {
				const respMsg = resp.split("_");

				alertType = SUCCESS;
				alertMsg = messageMap(respMsg[0], "api") + respMsg[1];
			}
			else {
				alertType = ERROR;
				alertMsg = messageMap(resp, "api");
			}

			props.setAlert(
				<Alert type={alertType} closeHandler={closeAlert} msg={alertMsg} />
			);
		});
	}

	function analyzeUploadedFile(e, acceptedFileFormats) {
		const target = e.target;
		const targetFile = target.files[0];
		const targetKeyName = target.getAttribute("keyname");

		const keysToFilenameDisplayMap = {
			screeningQuestions: setScreeningQuestionsFilename,
			profilePic: setProfilePhotoSrc,
			resume: setResumeFilename,
			teachingCertification: setTeachingCertificationFilename,
			professionalProof: setExperienceProofFilename,
			educationProof: setEducationProofFilename
		};

		const keysToFileMap = {
			screeningQuestions: setScreeningQuestionsFile,
			profilePic: setProfilePicFile,
			resume: setResumeFile,
			teachingCertification: setTeachingCertificationFile,
			professionalProof: setExperienceProofFile,
			educationProof: setEducationProofFile
		};

		if (disableInteraction || !e.target.files.length) {
			keysToFilenameDisplayMap[targetKeyName](null);
			keysToFileMap[targetKeyName](null);

			if (targetKeyName === "profilePic") {
				setProfilePhotoSrc(blankProfilePhotoAsset);
			}

			return;
		}

		const allowedKeys = [
			"screeningQuestions",
			"profilePic",
			"resume",
			"teachingCertification",
			"professionalProof",
			"educationProof"
		];

		if (targetFile !== undefined && targetFile !== null && allowedKeys.includes(targetKeyName)) {
			props.setAlert(null);

			const fileFormat = getFileFormatFromTargetFile(targetFile);
			if (!acceptedFileFormats.includes(fileFormat)) {
				const msg = `${messageMap("img.fileType.notAccepted", "validation") + fileFormat}. ${messageMap("img.fileType.whatIsAccepted", "validation") + acceptedFileFormats}`;
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={msg} />
				);

				keysToFilenameDisplayMap[targetKeyName](null);
				keysToFileMap[targetKeyName](null);

				if (targetKeyName === "profilePic") {
					setProfilePhotoSrc(blankProfilePhotoAsset);
				}
			}
			else {
				if (targetKeyName === "profilePic") {
					const htmlTag = (
						<img src={URL.createObjectURL(targetFile)} alt="uploaded profile pic" style={{display: "none"}}
							onLoad={imgData => checkProfilePicConstraints(imgData, targetFile)}></img>
					);
	
					setMetaContainerState(htmlTag);
					keysToFilenameDisplayMap[targetKeyName](URL.createObjectURL(targetFile));
					keysToFileMap[targetKeyName](targetFile);
				}
				else {
					if (200 < fileSizeConvert(targetFile.size, KB)) {
						props.setAlert(
							<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("document.fileSize.over200KB", "validation")} />
						);
					}
					else {
						keysToFilenameDisplayMap[targetKeyName](targetFile.name);
						keysToFileMap[targetKeyName](targetFile);
					}
				}
			}
		}
	}

	function checkProfilePicConstraints(imgData, uploadedProfilePic) {
		const {errorMsgs, hasViolations} = imageValidator(["jpg", "jpeg", "png", "webp", "svg"], [320, null], [0, null], 1, imgData, uploadedProfilePic);

		if (hasViolations) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={errorMsgs} />
			);
			setValidProfilePhoto(errorMsgs);
			setProfilePhotoSrc(null);
		}
		else {
			setValidProfilePhoto(true);
		}
	}

	function saveName(e, namePart) {
		if (disableInteraction) {
			e.target.value = "";
			return;
		}

		if (namePart === "first") {
			setFirstNameInputValue(e.target.value);
		}
		else {
			setLastNameInputValue(e.target.value);
		}
	}

	function saveOccupation(e) {
		const occupationValue = e.target.value;

		if (checkHasExplicitWords(occupationValue)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			setOccupation(occupationValue);
		}
	}

	function saveBio(e) {
		const bioValue = e.target.value;

		if (checkHasExplicitWords(bioValue)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			setBio(bioValue);
		}
	}

	function saveURLs(e, urlType) {
		if (disableInteraction) {
			e.target.value = "";
			return;
		}

		if (urlType === "LinkedIn") {
			setLinkedInInputValue(e.target.value)
		}
		else {
			setWebsiteInputValue(e.target.value)
		}
	}

	function showTutorPolicy() {
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} modalClass="become-tutor-modal">
				<TutorPolicy contentType="modal" />
			</Modal>
		);
	}

	function saveAgreementFullName(e) {
		if (disableInteraction) {
			e.target.value = "";
			return;
		}
		
		setAgreementFullName(e.target.value);
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function navigateToSetupConnectAccount(event, purpose) {
		props.setProfileSubNav(ACCOUNT);
		window.scrollTo(0, 0);
		if (!props.areRequiredPersonalInformationFilled) {
			history.push("/profile?show=account&requireFill=true");
		}
		else {
			history.push({
				pathname: "/stripe-connect",
				state: {
					purpose: purpose,
					country: country,
					requiredFields: requiredFields,
					banking: {
						accountNumberSample: accountNumberSample,
						routingNumberSample: routingNumberSample
					}
				}
			});
		}
	}

	function transformSubjectsTopicsDataToString() {
		let currentSubjectsToTopics = "";
		if (subjectsToTopics) {
			currentSubjectsToTopics = [];

			for (const [key, value] of Object.entries(subjectsToTopics)) {
				value.forEach(el => {
					currentSubjectsToTopics.push(`${key}_${el}`);
				});
			}

			currentSubjectsToTopics = currentSubjectsToTopics.join("|");
		}

		return currentSubjectsToTopics;
	}

	function transformNotificationsDataToString() {
		let notificationPreferenceStr = "";
		if (notificationPreferenceValue && Object.keys(notificationPreferenceValue)) {
			notificationPreferenceStr = [];
			for (const [key, value] of Object.entries(notificationPreferenceValue)) {
				if (value) {
					notificationPreferenceStr.push(key);
				}
			}

			notificationPreferenceStr = notificationPreferenceStr.join("|");
		}

		return notificationPreferenceStr;
	}

	function transformNotificationsStringToObject(notificationsStr) {
		let notificationObject = {};

		if (notificationsStr != null) {
			notificationsStr.split("|").forEach(type => {
				notificationObject[type] = true;
			});	
		}

		return notificationObject;
	}

	function transformFluentLanguagesDataToString() {
		let fluentLanguagesStr = "";
		if (fluentLanguagesValue && fluentLanguagesValue.length) {
			fluentLanguagesStr = fluentLanguagesValue.join("|");
		}

		return fluentLanguagesStr;
	}

	function transformFluentLanguagesStringToArray(fluentLanguagesStr) {
		return fluentLanguagesStr ? fluentLanguagesStr.split("|") : null;
	}

	function transformTeachingRatesDataToString() {
		let teachingRatesStr = "";
		if (Object.keys(teachingRatesValue).length) {
			teachingRatesStr = Object.keys(teachingRatesValue).includes("flatRate") ? [`flatRate_${teachingRatesValue["flatRate"]}`] :[];

			if (!Object.keys(teachingRatesValue).includes("flatRate")) {
				let topics = [];
				let highestPrice = -1;

				for (const [key, value] of Object.entries(teachingRatesValue)) {
					teachingRatesStr.push(`${key}_${value}`);
					topics.push(key);
	
					if (highestPrice < value) {
						highestPrice = value;
					}
				}

				for (const [key, value] of Object.entries(subjectsToTopics)) {
					for (let i = 0; i < value.length; ++i) {
						if (!topics.includes(value[i])) {
							teachingRatesStr.push(`${value[i]}_${highestPrice}`);
						}
					}
				}
			}

			teachingRatesStr = teachingRatesStr.join("|");
		}

		return teachingRatesStr;
	}

	function transformCancellationPoliciesDataToString() {
		let cancellationPolicyStr = "";
		let invalidIssues = new Set();

		if (Object.keys(cancellationPoliciesValue).length) {
			let availableRanges = [OVER, BETWEEN, UNDER];
			cancellationPolicyStr = [];
			let hoursSet = new Set();

			for (const [key, value] of Object.entries(cancellationPoliciesValue)) {
				if (OVER === key) {
					cancellationPolicyStr.push(`${key}:${value["hours"]}_${value["percentage"]}`);
					hoursSet.add(Number(value["hours"]));
				}
				else if (key === BETWEEN) {
					const upperLimit = Number(value["2"]["hours"]);

					if (upperLimit > hoursSet.keys().toArray()[0]) {
						invalidIssues.add("discontinuousHours");
					}
					else {
						cancellationPolicyStr.push(`${BETWEEN}:${value["1"]["hours"]}_${upperLimit}_${value["percentage"]}`);
						hoursSet.add(Number(value["1"]["hours"]));
						hoursSet.add(upperLimit);
					}
				}
				else if (UNDER === key) {
					const upperLimit = Number(value["hours"]);

					if (upperLimit > hoursSet.keys().toArray()[1]) {
						invalidIssues.add("discontinuousHours");
					}
					else {
						cancellationPolicyStr.push(`${key}:${upperLimit}_${value["percentage"]}`);
						hoursSet.add(upperLimit);
					}
				}

				availableRanges.splice(availableRanges.indexOf(key), 1);
			}

			availableRanges.length && invalidIssues.add("missingPolicies");
			availableRanges.forEach(range => {
				if ([OVER, UNDER].includes(range)) {
					cancellationPolicyStr.push(`${range}:0_100`);
				}
				else if (BETWEEN === range) {
					cancellationPolicyStr.push(`${range}:0_0_100`);
				}
			});

			cancellationPolicyStr = cancellationPolicyStr.join("|");
		}
		else {
			invalidIssues.add("missingPolicies");
		}

		return [cancellationPolicyStr, invalidIssues];
	}

	function transformScheduleDataToString() {
		let schedulesStr = "";
		if (Object.keys(schedulesValue).length || !isApplication) {
			let availableDays = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];
			schedulesStr = [];

			for (const [key, value] of Object.entries(schedulesValue)) {
				const valueKeys = Object.keys(value);

				if (valueKeys.includes("unavailable")) {
					schedulesStr.push(`${key}:unavailable`);
				}
				else if (["from", "to"].some(range => valueKeys.includes(range))) {
					schedulesStr.push(`${key}:${value["from"]}_${value["to"]}`);
				}

				availableDays.splice(availableDays.indexOf(key), 1);
			}

			availableDays.forEach(day => {
				schedulesStr.push(`${day}:unavailable`);
			});

			schedulesStr = schedulesStr.join("|");
		}
		
		return schedulesStr;
	}

	function transformScheduleStringToObject(scheduleString) {
		let scheduleObject = {};

		if (scheduleString && typeof scheduleString === "string") {
			scheduleString.split("|").forEach(daySchedule => {
				const dayAvailability = daySchedule.split(":");
	
				// day is unavailable
				if (dayAvailability.length === 2) {
					scheduleObject[dayAvailability[0]] = {
						unavailable: true
					}
				}
				else if (dayAvailability.length === 4) {
					scheduleObject[dayAvailability[0]] = {
						from: `${dayAvailability[1]}:${dayAvailability[2].split("_")[0]}`,
						to: `${dayAvailability[2].split("_")[1]}:${dayAvailability[3]}`
					}
				}
			});
		}

		return scheduleObject;
	}

	function saveTutorProfile(e) {
		if (disableInteraction || !accountVerified || (e.key != null && e.key !== "Enter")) {
			return;
		}
		if ((firstNameInputValue + " " + lastNameInputValue) !== agreementFullName) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} timeout={ALERT_THIRTY_SECONDS_TIMEOUT}
					msg={messageMap("officialVerification.notMatch", "validation")} />
			);

			return;
		}

		let cancellationData = null;
		if (!isApplication) {
			cancellationData = transformCancellationPoliciesDataToString();
			if (cancellationData[1].size) {
				let errorMsgs = [];
				cancellationData[1].forEach(msg => {
					errorMsgs.push(messageMap(`input.cancellationPolicy.${msg}`, "validation"));
				});
				props.setAlert(
					<Alert type={WARNING} closeHandler={closeAlert} msg={errorMsgs.join(" ")} />
				);
	
				return;
			}
		}
		if (validProfilePhoto !== true) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={validProfilePhoto} />
			);

			return;
		}

		// need to transform the data, because we're using formData, which uses basic key-value tuples
		let formDataPayload = {
			// tutor application fields
			[COUNTRY]: countryValue,
			[SCREENING_QUESTIONS_RESPONSE]: screeningQuestionsFile,
			[PROFILE_PICTURE]: profilePicFile,
			[FIRST_NAME]: firstNameInputValue,
			[LAST_NAME]: lastNameInputValue,
			[RESUME]: resumeFile,
			[TEACHING_CERTIFICATION]: teachingCertificationFile,
			[PROFESSIONAL_EXPERIENCE]: experienceProofFile,
			[PROOF_OF_EDUCATION]: educationProofFile,
			[LINKED_IN_URL]: linkedInInputValue,
			[WEBSITE_URL]: websiteInputValue,
			[SUBJECTS_TO_TOPICS_MAP]: transformSubjectsTopicsDataToString(),

			// active profile fields
			[OCCUPATION]: occupation,
			[BIO]: bio,
			[PHONE_NUMBER]: phoneNumberInputValue,
			// TODO: uncomment when we're allowing SMS
			// [NOTIFICATION_PREFERENCE]: transformNotificationsDataToString(),
			[TRIAL_PREFERENCE]: trialPreferenceValue,
			[FLUENT_LANGUAGES]: transformFluentLanguagesDataToString(),
			[TEACHING_RATES]: transformTeachingRatesDataToString(),
			[CANCELLATION_POLICIES]: cancellationData != null ? cancellationData[0] : null,
			[SCHEDULES]: transformScheduleDataToString(),
			[ACCEPTED_AGREEMENTS]: agreementFullName
		};

		let fieldsToValidate = [];
		for (const [key, value] of Object.entries(formDataPayload)) {
			(value || value === false) && fieldsToValidate.push(key);
		}

		saveTutorProfileAPI({ownerId: ownerId}, formDataPayload, fieldsToValidate, resp => {
			let allMsgs = [];
			let alertType = "";

			if (resp["SUCCESS"]) {
				alertType = SUCCESS;
				resp["SUCCESS"].split("_").forEach(msg => {
					if (isApplication) {
						if (!msg.includes("profile.visibility")) {
							allMsgs.push(messageMap(msg, "api"));
						}
					}
					else {
						if (!msg.includes("profile.interview")) {
							allMsgs.push(messageMap(msg, "api"));
						}
					}
				});
			}
			else if (resp["ERROR"]) {
				alertType = ERROR;
				resp["ERROR"].split("_").forEach(msg => {
					allMsgs.push(messageMap(msg, "api"));
				});
			}

			if (allMsgs.length) {
				props.setAlert(
					<Alert type={alertType} closeHandler={closeAlert} msg={allMsgs.join(" ")} />
				);
			}

			if (resp["SUCCESS"]) {
				props.refreshProfile && props.refreshProfile();
			}
		});
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function toggleDetailsDropdown(detailGroup) {
		const toggleStatesMap = {
			requirements: [requirementsClass, setRequirementsClass, setRequirementChevron, "requirements"],
			applicationProcess: [applicationProcessClass, setApplicationProcessClass, setApplicationProcessChevron, "application-process"],
			pricingStructure: [pricingStructureClass, setPricingStructureClass, setPricingStructureChevron, "pricing-structure"],
			paymentStructure: [paymentStructureClass, setPaymentStructureClass, setPaymentStructureChevron, "payment-structure"],
			tutoringProcess: [tutoringProcessClass, setTutoringProcessClass, setTutoringProcessChevron, "tutoring-process"]
		};

		const detailGroupClass = `${toggleStatesMap[detailGroup][3]}-dropdown`;
		if (toggleStatesMap[detailGroup][0] === `${detailGroupClass} open`) {
			toggleStatesMap[detailGroup][1](`${detailGroupClass} closed`);
			toggleStatesMap[detailGroup][2]("chevron-down");
		}
		else {
			toggleStatesMap[detailGroup][1](`${detailGroupClass} open`);
			toggleStatesMap[detailGroup][2]("chevron-up");
		}
	}

	function toggleOccupationTooltip(showOrHide) {
		if (showOrHide === "show") {
			setOccupationTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring" subheader={messageMap("tutor.occupation", "tooltip")} />
			);
		}
		else {
			setOccupationTooltip(null);
		}
	}
	function toggleStripeConnectTooltip(showOrHide) {
		if (showOrHide === "show") {
			setStripeConnectTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring" subheader={messageMap("tutor.stripeConnectPayments", "tooltip")} />
			);
		}
		else {
			setStripeConnectTooltip(null);
		}
	}

	return (
		<Fragment>
			{metaContainerState}

			{
				isApplication &&
				(
					<Fragment>
						<div className="requirements-container">
							<div className="requirements-header" tabIndex={0} role="button"
									onClick={e => toggleDetailsDropdown("requirements")}
									onKeyDown={e => onKeyDown(e, toggleDetailsDropdown, ["requirements"])}>
								<div className="sub-header">
									{messageMap("profilePage.tutoring.modal.requirements.header", "generic")}
								</div>
								<img src={chevronAsset} alt="chevron" className={requirementChevron}></img>
							</div>

							<ul className={`application-requirements ${requirementsClass}`}>
								{
									TUTOR_REQUIREMENTS.map((requirement, index) =>
										<li key={`requirement_${index}`}>
											{requirement}
										</li>
									)
								}
							</ul>
						</div>

						<div className="requirements-container">
							<div className="requirements-header" tabIndex={0} role="button"
									onClick={e => toggleDetailsDropdown("applicationProcess")}
									onKeyDown={e => onKeyDown(e, toggleDetailsDropdown, ["applicationProcess"])}>
								<div className="sub-header">
									{messageMap("profilePage.tutoring.modal.requirements.header1", "generic")}
								</div>
								<img src={chevronAsset} alt="chevron" className={applicationProcessChevron}></img>
							</div>

							<ul className={`application-requirements ${applicationProcessClass}`}>
								{
									TUTOR_APPLICATION_PROCESS.map((requirement, index) =>
										<li key={`process_${index}`}>
											{requirement}
										</li>
									)
								}
							</ul>
						</div>

						<div className="requirements-container">
							<div className="requirements-header" tabIndex={0} role="button"
									onClick={e => toggleDetailsDropdown("pricingStructure")}
									onKeyDown={e => onKeyDown(e, toggleDetailsDropdown, ["pricingStructure"])}>
								<div className="sub-header">
									{messageMap("profilePage.tutoring.modal.requirements.header2", "generic")}
								</div>
								<img src={chevronAsset} alt="chevron" className={pricingStructureChevron}></img>
							</div>

							<ul className={`application-requirements ${pricingStructureClass}`}>
								{
									TUTORING_PRICING_STRUCTURE.map((requirement, index) =>
										<li key={`pricing_${index}`}>
											{requirement}
										</li>
									)
								}
							</ul>
						</div>

						<div className="requirements-container">
							<div className="requirements-header" tabIndex={0} role="button"
									onClick={e => toggleDetailsDropdown("paymentStructure")}
									onKeyDown={e => onKeyDown(e, toggleDetailsDropdown, ["paymentStructure"])}>
								<div className="sub-header">
									{messageMap("profilePage.tutoring.modal.requirements.header3", "generic")}
								</div>
								<img src={chevronAsset} alt="chevron" className={paymentStructureChevron}></img>
							</div>

							<ul className={`application-requirements ${paymentStructureClass}`}>
								{
									TUTORING_PAYMENT_STRUCTURE.map((requirement, index) =>
										<li key={`payment_${index}`}>
											{requirement}
										</li>
									)
								}
							</ul>
						</div>

						<div className="requirements-container">
							<div className="requirements-header" tabIndex={0} role="button"
									onClick={e => toggleDetailsDropdown("tutoringProcess")}
									onKeyDown={e => onKeyDown(e, toggleDetailsDropdown, ["tutoringProcess"])}>
								<div className="sub-header">
									{messageMap("profilePage.tutoring.modal.requirements.header4", "generic")}
								</div>
								<img src={chevronAsset} alt="chevron" className={tutoringProcessChevron}></img>
							</div>

							<ul className={`application-requirements ${tutoringProcessClass}`}>
								{
									TUTORING_PROCESS.map((requirement, index) =>
										<li key={`tutoring_process_${index}`}>
											{requirement}
										</li>
									)
								}
							</ul>
						</div>
					</Fragment>
				)
			}

			{
				!isApplication &&
				(
					<Fragment>
						<div className="requirements-container info">
							{messageMap("profilePage.tutoring.modal.requirements.p38", "generic")}
						</div>
					</Fragment>
				)
			}

			<div className="tutor-profile-container">
				{
					isApplication &&
					(
						<div className="sub-header">
							{messageMap("profilePage.tutoring.modal.application.header", "generic")}
						</div>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.location", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								<Dropdown customDropdownItemAttribute="countrykey" customContainerClass="tutoring-application no-right-margin"
									dropdownOptions={fullCountriesList} dropdownItemClickHandler={checkCountryAvailability}
									preselectedKey={countryValue ? countryAlphaCodeToName[countryValue] : null} />
							</div>
						</div>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.screeningQuestions", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								<a href={process.env.PUBLIC_URL + "/documents/Tutor Technical - Public.docx"} className="download-screening-questions">
									{messageMap("tutoring.screeningQuestions.download", "button")}
								</a>
								<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
									<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
										accept=".docx, .doc, .pdf"
										keyname="screeningQuestions"
										onChange={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										onClick={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										required="required" aria-required="true"/>
									{messageMap("tutoring.screeningQuestions.upload", "button")}
								</label>
								<div className="filename">
									{screeningQuestionsFilename}
								</div>
							</div>
						</div>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.accountStatus", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								{
									accountVerified
									?
									<div className="verified-account">
										{messageMap("profilePage.tutoring.modal.accountVerification.verified", "generic")}
									</div>
									:
									<button className={`status-container ${disableInteraction && "not-allowed"}`}
										onClick={sendEmailAccountVerification}>
										{messageMap("tutoring.accountVerification.unverified", "button")}
									</button>
								}
							</div>
						</div>
					)
				}

				<div className="page-field">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("profilePage.tutoring.modal.application.profilePicture", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements">
						<img src={profilePhotoSrc} alt={messageMap("account.profilePicture", "image")} className="profile-picture"></img>
						<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
							<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
								accept=".png, .jpeg, .jpg, .webp"
								keyname="profilePic"
								onChange={e => analyzeUploadedFile(e, ["png", "jpeg", "jpg", "webp"])}
								onClick={e => analyzeUploadedFile(e, ["png", "jpeg", "jpg", "webp"])}
								required="required" aria-required="true" />

							{
								isApplication ?
								(
									messageMap("profilePage.tutoring.modal.uploads.photo", "generic")
								)
								: (
									messageMap("profilePage.tutoring.modal.uploads.changePhoto", "generic")
								)
							}
						</label>
					</div>
				</div>

				{
					!isApplication &&
					(
						<Fragment>
							<div className="page-field">
								<label className="page-field-label" htmlFor="occupation">
									<div className="div-label">
										{messageMap("profilePage.tutoring.modal.application.occupation", "generic")}
									</div>
									*
									<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
										onMouseOver={e => toggleOccupationTooltip("show")} onFocus={e => toggleOccupationTooltip("show")}
										onMouseLeave={e => toggleOccupationTooltip("hide")} onBlur={e => toggleOccupationTooltip("hide")} ></img>
									{occupationTooltip}
								</label>
								<div className="inline-elements">
									<input id="occupation" className="page-field-value" type="text"
										required aria-required="true"
										maxLength={200}
										onChange={saveOccupation}
										value={occupation}>
									</input>
								</div>
							</div>

							<div className="page-field">
								<label className="page-field-label" htmlFor="bio">
									<div className="div-label">
										{messageMap("profilePage.tutoring.modal.application.bio", "generic")}
									</div>
									*
								</label>
								<div className="inline-elements">
									<textarea id="bio" className="page-field-value textarea" type="text"
										required aria-required="true"
										maxLength={500}
										onChange={saveBio}
										value={bio}>
									</textarea>
								</div>
							</div>
						</Fragment>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.fullName", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								<input className={`page-field-value right-margin-2 ${disableInteraction && "not-allowed"}`} type="text"
									placeholder={firstNameInputValue ? firstNameInputValue : messageMap("tutoring.profile.firstName", "labelPlaceholder")}
									required aria-required="true"
									maxLength={25}
									onChange={e => saveName(e, "first")}
									value={firstNameInputValue}>
								</input>
								<input className={`page-field-value ${disableInteraction && "not-allowed"}`} type="text"
									placeholder={lastNameInputValue ? lastNameInputValue : messageMap("tutoring.profile.lastName", "labelPlaceholder")}
									required aria-required="true"
									maxLength={25}
									onChange={e => saveName(e, "last")}
									value={lastNameInputValue}>
								</input>
							</div>
						</div>
					)
				}

				{/* TODO: implement in TUTORING-II */}
				{
					// !isApplication &&
					// <TutorNotifications setAlert={props.setAlert}
					// 	phoneNumber={phoneNumber} setPhoneNumberInputValue={setPhoneNumberInputValue}
					// 	notificationPreference={transformNotificationsStringToObject(notificationPreferenceValue)} setNotificationPreference={setNotificationPreferenceValue} />
				}

				{
					!isApplication &&
					<FluentLanguages fluentLanguages={fluentLanguagesValue} setFluentLanguages={setFluentLanguagesValue} />
				}

				<div className="page-field">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("profilePage.tutoring.modal.application.resume", "generic")}
						</div>
						{isApplication ? "*" : ""}
					</label>
					<div className="inline-elements">
						<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
							<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
								accept=".pdf"
								keyname="resume"
								onChange={e => analyzeUploadedFile(e, ["pdf"])}
								onClick={e => analyzeUploadedFile(e, ["pdf"])}
								required="required" aria-required="true"/>
							{messageMap("profilePage.tutoring.modal.uploads.resume", "generic")}
						</label>
						<div className="filename">
							{resumeFilename}
						</div>
					</div>
				</div>

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								{messageMap("profilePage.tutoring.modal.application.teachingCredentials", "generic")}
							</label>
							<div className="inline-elements">
								<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
									<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
										accept=".docx, .doc, .pdf"
										keyname="teachingCertification"
										onChange={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										onClick={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}/>
									{messageMap("profilePage.tutoring.modal.uploads.licenseCertification", "generic")}
								</label>
								<div className="filename">
									{teachingCertificationFilename}
								</div>
							</div>
						</div>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								{messageMap("profilePage.tutoring.modal.application.professionalExperience", "generic")}
							</label>
							<div className="inline-elements">
								<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
									<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
										accept=".docx, .doc, .pdf"
										keyname="professionalProof"
										onChange={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										onClick={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])} />
									{messageMap("profilePage.tutoring.modal.uploads.experience", "generic")}
								</label>
								<div className="filename">
									{experienceProofFilename}
								</div>
							</div>
						</div>
					)
				}

				{
					isApplication &&
					(
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.education", "generic")}
								</div>
							</label>
							<div className="inline-elements">
								<label className={`upload-container ${disableInteraction && "not-allowed"}`}>
									<input className={`file-upload ${disableInteraction && "not-allowed"}`} type="file"
										accept=".docx, .doc, .pdf"
										keyname="educationProof"
										onChange={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										onClick={e => analyzeUploadedFile(e, ["docx", "doc", "pdf"])}
										required="required" aria-required="true"/>
									{messageMap("profilePage.tutoring.modal.uploads.education", "generic")}
								</label>
								<div className="filename">
									{educationProofFilename}
								</div>
							</div>
						</div>
					)
				}

				<div className="page-field">
					<label className="page-field-label" htmlFor="linkedInUrl">
						{messageMap("profilePage.tutoring.modal.application.linkedIn", "generic")}
					</label>
					<div className="inline-elements">
						<input id="linkedInUrl " className={`page-field-value ${disableInteraction && "not-allowed"}`} type="url"
							placeholder={linkedInInputValue ? linkedInInputValue : messageMap("tutoring.profile.linkedIn", "labelPlaceholder")}
							maxLength={75}
							onChange={e => saveURLs(e, "LinkedIn")}
							value={linkedInInputValue}>
						</input>
					</div>
				</div>

				<div className="page-field">
					<label className="page-field-label" htmlFor="websiteUrl">
						{messageMap("profilePage.tutoring.modal.application.website", "generic")}
					</label>
					<div className="inline-elements">
						<input id="websiteUrl" className={`page-field-value ${disableInteraction && "not-allowed"}`} type="url"
							placeholder={websiteInputValue ? websiteInputValue : messageMap("tutoring.profile.website", "labelPlaceholder")}
							maxLength={75} onChange={e => saveURLs(e, "WebUrl")}
							value={websiteInputValue}>
						</input>
					</div>
				</div>

				{
					isApplication && subjectTopicsDropdowns
				}

				{renderTeachingRatesDOM}

				{
					!isApplication &&
					<FifteenMinuteTrials trialPreference={trialPreferenceValue} setTrialPreference={setTrialPreferenceValue} />
				}

				{
					!isApplication &&
					<CancellationPolicy setModal={props.setModal} setAlert={props.setAlert}
						cancellationPolicies={cancellationPoliciesValue} setCancellationPolicies={setCancellationPoliciesValue} />
				}

				{
					!isApplication &&
					<Schedule setModal={props.setModal} setAlert={props.setAlert}
						schedules={schedulesValue} setSchedules={setSchedulesValue} />
				}

				{
					!isApplication &&
						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("profilePage.tutoring.modal.application.acceptPaymentsWStripeConnect", "generic")}
								</div>
								<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
									onMouseOver={e => toggleStripeConnectTooltip("show")} onFocus={e => toggleStripeConnectTooltip("show")}
									onMouseLeave={e => toggleStripeConnectTooltip("hide")} onBlur={e => toggleStripeConnectTooltip("hide")} ></img>
								{stripeConnectTooltip}
							</label>
							<div className="inline-elements">
								{
									userHasConnectAccount
									? (
										userConnectAccountStatus === "verified"
										? (
											<div>
												{messageMap("profilePage.tutoring.modal.stripe.linkedAccount", "generic")}
												<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
											</div>
										)
										: (
											<button className="status-container" onClick={e => navigateToSetupConnectAccount(e, "update")}>
												{messageMap("profilePage.tutoring.modal.stripe.updateAccount", "generic")}
											</button>
										)
									)
									: (
										<button className="status-container" onClick={e => navigateToSetupConnectAccount(e, "create")}>
											{messageMap("profilePage.tutoring.modal.stripe.linkAccount", "generic")}
										</button>
									)
								}
							</div>
						</div>
				}

				{
					isApplication &&
					(
						<div className="page-field agreement-container">
							<label htmlFor="agreement" className="agreement-label-container">
								<div className="agreement-text margin-right-8">
									{`${messageMap("profilePage.tutoring.modal.agreement.title", "generic")} `}
								</div>
								<button className="agreement-text bold agreement-policy"
									onClick={showTutorPolicy}>
									{`${messageMap("profilePage.tutoring.modal.agreement.contract", "generic")} `}
								</button>
								<a href="https://stripe.com/legal/connect-account" target="_blank" rel="noreferrer">
									{messageMap("profilePage.tutoring.modal.agreement.stripServicesAgreement", "generic")}
								</a>
								<div className="agreement-text">
									{messageMap("account.signUp.agreement.and", "generic")}
								</div>
								<a href="https://stripe.com/legal/connect-account/recipient" target="_blank" rel="noreferrer">
								{messageMap("profilePage.tutoring.modal.agreement.stripeRecipientAgreement", "generic")}
								</a>
								*
							</label>
							<input id="agreement" className={`page-field-value ${disableInteraction && "not-allowed"}`} type="text"
								placeholder={messageMap("tutoring.profile.fullName", "labelPlaceholder")}
								required aria-required="true"
								maxLength={55}
								onChange={saveAgreementFullName}>
							</input>
						</div>
					)
				}
			</div>

			<button className={`save-button ${(disableInteraction || !accountVerified) && "not-allowed"}`}
				onClick={saveTutorProfile}>
				{
					isApplication 
					?
					messageMap("save.tutoringProfile", "button")
					:
					messageMap("save.edits", "button")
				}
			</button>
			{
				isApplication &&
				(
					<button className="cancel-button" onClick={props.closeModal}>
						{messageMap("cancel.text", "button")}
					</button>
				)
			}
		</Fragment>
	);
}

TutorProfile.propTypes = {
	// person profile props
	isApplication: PropTypes.bool.isRequired,
	accountVerified: PropTypes.bool.isRequired,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	phoneNumber: PropTypes.string,
	country: PropTypes.string,
	profilePictureUploadLocation: PropTypes.string,
	areRequiredPersonalInformationFilled: PropTypes.bool.isRequired,
	requiredFields: PropTypes.arrayOf(PropTypes.string),
	accountNumberSample: PropTypes.string,
	routingNumberSample: PropTypes.string,

	// tutor profile props
	occupation: PropTypes.string,
	bio: PropTypes.string,
	notificationPreference: PropTypes.string,
	trialPreference: PropTypes.string,
	fluentLanguages: PropTypes.string,
	teachingRates: PropTypes.string,
	cancellationPolicies: PropTypes.string,
	schedules: PropTypes.string,
	subjectTopics: PropTypes.string,

	screeningDocument: PropTypes.string,
	resumeDocument: PropTypes.string,
	teachingDocument: PropTypes.string,
	professionalDocument: PropTypes.string,
	educationProofDocument: PropTypes.string,

	linkedInUrl: PropTypes.string,
	webUrl: PropTypes.string,

	hasActiveTutoringSessions: PropTypes.bool,

	// parent function handlers
	refreshProfile: PropTypes.func,
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string,
	setProfileSubNav: PropTypes.func.isRequired
};

export default connect(
	account,
	{
		setProfileSubNav
	}
)(TutorProfile);

