import React, {Fragment, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import TabbedPages from "templates/TabbedPages";
import TopicDetailsPriceBreakdown from "./TopicDetailsPriceBreakdown";

import { getTutorScheduledSessionsAPI } from "apis/controllers/tutoring/SessionRequestController";
import { getUserChargeablePaymentMethodAPI } from "apis/controllers/person/PersonPaymentInfoController";


function SubjectTopicPriceBreakdown(props) {

	const [topicRatesDom, setTopicRatesDom] = useState();

	const chargeablePaymentMethodsRef = useRef(null);

	useEffect(() => {
		if (props.showBookOption) {
			if (props.ownerId) {
				getUserChargeablePaymentMethodAPI(props.ownerId, resp => {
					chargeablePaymentMethodsRef.current = resp;
					getScheduledSessionsAndCreatePriceBreakdown();
				});
			}
			else {
				getScheduledSessionsAndCreatePriceBreakdown();
			}
		}
		else {
			getScheduledSessionsAndCreatePriceBreakdown();
		}
	}, []);

	function getScheduledSessionsAndCreatePriceBreakdown() {
		getTutorsScheduledSessions(showSubMenuPage);
	}

	function showSubMenuPage(bookedSlotSessions) {
		let topicRatesMap = {};
		let teachingRates = props.teachingRates;
		if (teachingRates.includes("flatRate")) {
			const flatRate = teachingRates.split("_")[1];
			let flatRates = [];
			props.subjectToTopicsMap.split("|").forEach(subjectTopicStr => {
				flatRates.push(
					`${subjectTopicStr.split("_")[1]}_${flatRate}`
				);
			});

			teachingRates = flatRates.join("|");
		}
		teachingRates.split("|").forEach(rate => {
			const topicRate = rate.split("_");
			topicRatesMap[topicRate[0]]  = topicRate[1];
		});

		let subjectToTopicObj = {};
		let subjectTabMenu = {};
		const isUsingFlatRate = Object.keys(topicRatesMap).includes("flatRate");
		props.subjectToTopicsMap.split("|").forEach(subjectTopicStr => {
			const subjectTopic = subjectTopicStr.split("_");
			const subject = subjectTopic[0];

			if (subjectToTopicObj[subject] == null) {
				subjectToTopicObj[subject] = [];
			}

			let rate = topicRatesMap[subjectTopic[1]];
			if (rate != null) {
				if (isUsingFlatRate) {
					rate = topicRatesMap["flatRate"];
				}
	
				subjectToTopicObj[subject].push({
					topic: subjectTopic[1],
					rate: rate
				});
				subjectTabMenu[subject] = subject;
			}
		});

		let topicRates = [];
		for (const [key, value] of Object.entries(subjectToTopicObj)) {
			topicRates.push(
				<div key={key} className="subject-topic-group">
					<div className="subject-label">
						{key}
					</div>
					<div>
						{createTopicRatesBreakdown(key, subjectToTopicObj, bookedSlotSessions)}
					</div>
				</div>
			);
		}

		setTopicRatesDom(topicRates);
	}

	function getTutorsScheduledSessions(callback) {
		if (props.showBookOption) {
			const pathVariables = {
				ownerId: props.tutorOwnerId
			};
	
			getTutorScheduledSessionsAPI(pathVariables, resp => {
				let bookedSlotSessions = [];
				resp.forEach(session => {
					if (session.urgency !== "now") {
						bookedSlotSessions.push(
							{
								date: session.urgency,
								duration: session.duration
							}
						);
					}
				});

				callback(bookedSlotSessions);
			});
		}
		else {
			callback();
		}
	}

	function createTopicRatesBreakdown(chosenSubject, subjectToTopicsMap, bookedSlotSessions) {
		let topicRates = [];

		const initialTopicRatesList = subjectToTopicsMap[chosenSubject];
		initialTopicRatesList.forEach((topicRateTuple, index) => {
			topicRates.push(
				<Fragment key={topicRateTuple.topic}>
					<TopicDetailsPriceBreakdown setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert}
						topic={topicRateTuple.topic} rate={topicRateTuple.rate} subject={chosenSubject} 
						showBookOption={props.showBookOption} bookedSlotSessions={bookedSlotSessions} schedule={props.schedule}
						// TODO: update this from @Chris's code
						isSubscriber={true}
						tutorProfileId={props.tutorProfileId}
						tutorTier={props.tutorTier} tutorFirstName={props.tutorFirstName}
						tutorTimezone={props.tutorTimezone}
						userHasSavedPaymentMethod={chargeablePaymentMethodsRef.current} />
					{
						(index !== initialTopicRatesList.length - 1) && <hr />
					}
				</Fragment>
			);
		});

		return topicRates;
	}

	return (
		<div className="subject-topic-details">
			{topicRatesDom}
		</div>
	);
}

SubjectTopicPriceBreakdown.propTypes = {
	tutorFirstName: PropTypes.string.isRequired,
	tutorProfileId: PropTypes.string.isRequired,
	tutorOwnerId: PropTypes.string.isRequired,
	schedule: PropTypes.string.isRequired,
	showBookOption: PropTypes.bool,
	teachingRates: PropTypes.string.isRequired,
	subjectToTopicsMap: PropTypes.string.isRequired,
	tutorTier: PropTypes.string.isRequired,
	tutorTimezone: PropTypes.string.isRequired,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(SubjectTopicPriceBreakdown);

