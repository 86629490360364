import React from "react";

import messageMap from "Utilities/MessageMaps";

export const dayOfWeekToIntMap = {
	Sunday: 0,
	Monday: 1,
	Tuesday: 2,
	Wednesday: 3,
	Thursday: 4,
	Friday: 5,
	Saturday: 6
};

export const intToDayOfWeekMap = {
	0: messageMap("calendar.days.sunday", "generic"),
	1: messageMap("calendar.days.monday", "generic"),
	2: messageMap("calendar.days.tuesday", "generic"),
	3: messageMap("calendar.days.wednesday", "generic"),
	4: messageMap("calendar.days.thursday", "generic"),
	5: messageMap("calendar.days.friday", "generic"),
	6: messageMap("calendar.days.saturday", "generic")
};

export const monthMap = {
	0: messageMap("calendar.months.january", "generic"),
	1: messageMap("calendar.months.february", "generic"),
	2: messageMap("calendar.months.march", "generic"),
	3: messageMap("calendar.months.april", "generic"),
	4: messageMap("calendar.months.may", "generic"),
	5: messageMap("calendar.months.june", "generic"),
	6: messageMap("calendar.months.july", "generic"),
	7: messageMap("calendar.months.august", "generic"),
	8: messageMap("calendar.months.september", "generic"),
	9: messageMap("calendar.months.october", "generic"),
	10: messageMap("calendar.months.november", "generic"),
	11: messageMap("calendar.months.december", "generic")
};

// 1 hour
export const FIFTEEN_MINUTES = 15;
export const THIRTY_MINUTES = 30;
export const FORTY_FIVE_MINUTES = 45;
export const SIXTY_MINUTES = 60;
// 2 hours
export const SEVENTY_FIVE_MINUTES = 75;
export const NINETY_MINUTES = 90;
export const HUNDRED_FIVE_MINUTES = 105;
export const HUNDRED_TWENTY_MINUTES = 120;
// 3 hours
export const HUNDRED_THIRTY_FIVE_MINUTES = 135;
export const HUNDRED_FIFTY_MINUTES = 150;
export const HUNDRED_SIXTY_FIVE_MINUTES = 165;
export const HUNDRED_EIGHTY_MINUTES = 180;
// 4 hours
export const HUNDRED_NINETY_FIVE_MINUTES = 195;
export const TWO_HUNDRED_TEN_MINUTES = 210;
export const TWO_HUNDRED_TWENTY_FIVE_MINUTES = 225;
export const TWO_HUNDRED_FORTY_MINUTES = 240;

export const timeSlotsMap = {
	// 1 hour
	[FIFTEEN_MINUTES]: `${FIFTEEN_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[THIRTY_MINUTES]: `${THIRTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[FORTY_FIVE_MINUTES]: `${FORTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[SIXTY_MINUTES]: `${SIXTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	// 2 hours
	[SEVENTY_FIVE_MINUTES]: `${SEVENTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[NINETY_MINUTES]: `${NINETY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[HUNDRED_FIVE_MINUTES]: `${HUNDRED_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[HUNDRED_TWENTY_MINUTES]: `${HUNDRED_TWENTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	// 3 hours
	[HUNDRED_THIRTY_FIVE_MINUTES]: `${HUNDRED_THIRTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[HUNDRED_FIFTY_MINUTES]: `${HUNDRED_FIFTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[HUNDRED_SIXTY_FIVE_MINUTES]: `${HUNDRED_SIXTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[HUNDRED_EIGHTY_MINUTES]: `${HUNDRED_EIGHTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	// 4 hours
	[HUNDRED_NINETY_FIVE_MINUTES]: `${HUNDRED_NINETY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[TWO_HUNDRED_TEN_MINUTES]: `${TWO_HUNDRED_TEN_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[TWO_HUNDRED_TWENTY_FIVE_MINUTES]: `${TWO_HUNDRED_TWENTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[TWO_HUNDRED_FORTY_MINUTES]: `${TWO_HUNDRED_FORTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
};

// gets user's offset and converting to UTC (same as GTM)
// we're not really using the GMT part here, but leaving it to be explicit
export function getTimezoneOffset() {
	function calc(n) {
		return (n < 10 ? '0' : '') + n;
	}

	let offset = new Date().getTimezoneOffset();
	const sign = offset < 0? '+' : '-';
	offset = Math.abs(offset);

	return "GMT" + sign + calc(offset / 60 | 0) + ":" + calc(offset % 60);
}

export function createBookingTimeSlots(
		adjustedStartingMinutes, adjustedEndingMinutes,
		sessionInterval, daysBookedSessionsSlots,
		monthDate, dayOfMonth, newDate,
		isDayAvailable,
		setChosenTimeSlot, saveSelectedTimeSlot
	) {
	let timeSlots = [];

	let preSelectedSlotCounter = 1;
	let i = adjustedStartingMinutes;
	while (i + sessionInterval <= adjustedEndingMinutes) {
		// determines if next interval includes an already booked slot
		const nextIntervalSlots = sessionInterval / 15;
		let invalidSlots = [];
		for (let j = 0; j < nextIntervalSlots; ++j) {
			invalidSlots.push(i + j * 15);
		}
		const includesBookedSlots = invalidSlots.some(el => daysBookedSessionsSlots.includes(el));

		if (!daysBookedSessionsSlots.includes(i) && !includesBookedSlots) {
			const amPm = i < 720 ? "AM" : "PM";
			const origHour = Math.floor(i / 60);
			let adjustedHour = origHour > 12 ? origHour - 12 : origHour;
			adjustedHour = adjustedHour === 0 ? 12 : adjustedHour;
			const minStr = String(i % 60);
			const time = `${adjustedHour}:${minStr.length === 1 ? `0${minStr}` :minStr} ${amPm}`;
			// decided to do this, so I don't have to create a Date() for every button - that's sloooowww
			const timestamp = monthDate + "_" + dayOfMonth + "_" + newDate.getDay() + "_" + adjustedHour + "_" + minStr + "_" + amPm;

			let slotClassName = "session-slot";
			if (preSelectedSlotCounter === 1) {
				slotClassName = "session-slot clicked";
				--preSelectedSlotCounter;

				if (isDayAvailable) {
					setChosenTimeSlot(timestamp);
				}
			}

			timeSlots.push(
				<button key={time} className={slotClassName}
					onClick={e => saveSelectedTimeSlot(e, timestamp)}>
					{time}
				</button>
			);

			i += sessionInterval;
		}
		else {
			i += 15;
		}
	}

	return timeSlots;
}

function adjustMinutes(unadjustedMinutes, startingOrEnding) {
	if (unadjustedMinutes % 15 !== 0) {
		if (startingOrEnding === "starting") {
			unadjustedMinutes = unadjustedMinutes + (15 - unadjustedMinutes % 15)
		}
		else {
			unadjustedMinutes = unadjustedMinutes - (unadjustedMinutes % 15);
		}
	}

	return unadjustedMinutes;
}
export function getAdjustedMinutes(hours, minutes, startingOrEnding) {
	const startingMinutes = adjustMinutes(Number(minutes), startingOrEnding);
	let startingHour = (Number(hours) * 60);
	startingHour = startingHour === 1440 && startingMinutes > 0 ? startingHour - 1440 : startingHour;
	let adjustedStartingMinutes = startingHour + startingMinutes;

	return adjustedStartingMinutes > 1440 ? adjustedStartingMinutes - (adjustedStartingMinutes - 1440) : adjustedStartingMinutes;
}
