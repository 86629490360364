import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";

import defaultLinkAction from 'Utilities/LinkActions';
import { CURRENCIES } from 'Utilities/Constants/PaymentConstants';
import { importShinyNeuron, getAlt } from 'Utilities/ShinyNeuronImages';
import messageMap from 'Utilities/MessageMaps';

/**
 * @description
 * @param {Number} quantity The quantity of neurons
 * @param {String} currencySymbol Currency symbol (ex. $ for USD)
 * @param {Number} price The price for a given quanity.
 * @param {Number} bonus? Should be an integer.
 * @param {String} bonusString? Should be a string that indicates what the bonus applies to.
 */
function ShinyNeuronCard(props) {
	const [isHovered, setIsHovered] = useState(false);

	function handleMouseEnter() {
		setIsHovered(true);
	}

	function handleMouseLeave() {
		setIsHovered(false);
	}

	function prepForCheckout(event) {
		defaultLinkAction();
		let callback = props.continueCallbackWrapper();

		if (callback) {
			event.preventDefault();
			callback();
		}
		else {
			props.setPaymentInfo({
				...props,
				description: messageMap("payments.quotes.paymentDescription", "generic")
			});
		}
	}

	return (
		<Link to={`/checkout?price=${props.price}&quantity=${props.quantity}`}
			className="neuron-card-container" onClick={prepForCheckout}
			onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className="card">
				<div className="neuron-quantity-container">
					<div className="neuron-quantity">
						{props.quantity + " Neurons"}
					</div>
				</div>

				<div className="neuron-img-container">
					{
						props.bonus && props.bonusString
							? // Returns a bonus ribbon or placeholder div.
							(
								<div className="neuron-bonus-ribbon">
									{props.bonus + props.bonusString}
								</div>
							)
							: <div />
					}
					<img className="img" src={importShinyNeuron(props.imgRef)} alt={getAlt(props.imgRef)}></img>
				</div>

				<div className="neuron-price">
					{props.currencySymbol + props.price}
				</div>
			</div>

		</Link>
	);
}

ShinyNeuronCard.defaultProps = {
	purpose: "shinyNeurons"
};

ShinyNeuronCard.propTypes = {
	// Pricing
	quantity: PropTypes.number.isRequired,
	currencySymbol: PropTypes.oneOf(Object.values(CURRENCIES)).isRequired,//TO-DO: Return currency from BE.
	price: PropTypes.number.isRequired,
	bonus: PropTypes.number,
	bonusString: PropTypes.string,
	purpose: PropTypes.string,
	// // Image
	imgRef: PropTypes.number.isRequired,
	// Continue Button
	continue: PropTypes.bool,
	continueCallbackWrapper: PropTypes.func,
	setPaymentInfo: PropTypes.func.isRequired
};

export default connect(
	null,
	{ setPaymentInfo }
)(ShinyNeuronCard);