import React, { useEffect, useState, useRef, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Alert, { ERROR, INFORMATION } from "templates/Alert";
import Spinner from "templates/Spinner";
import Tooltip from "templates/Tooltip";
import Dropdown from "templates/Dropdown";
import Modal from "templates/Modal";
import UploadDocumentPicture from "templates/UploadDocumentPicture";

import { numberValidator } from "Utilities/Validators/InputValidators";
import { onKeyDown } from "Utilities/Accessibility";
import messageMap from "Utilities/MessageMaps";
import { idDocumentExamples, addressDocumentExamples } from "./utilities/StripeConnectUtilities";
import { 
	availableCountriesObject, countryAlphaCodeToName, countriesToAlphaCode
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import {
	OWNER_ID, 
	URL_BUSINESS_PROFILE, GOVT_ID_NUMBER, SSN,
	CARD_NUMBER, CARD_EXP_MONTH, CARD_EXP_YEAR, CVC,
	BANK_COUNTRY, CHECKING_ACCOUNT_NUMBER, ROUTING_NUMBER,
	ID_DOCUMENT_1, ID_DOCUMENT_2, ADDRESS_DOCUMENT_1, ADDRESS_DOCUMENT_2,
	ID_DOCUMENT_NAME, ADDRESS_DOCUMENT_NAME,
	IP_TOS, USER_AGENT_TOS
} from "Utilities/Constants/MediaConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { saveStripeConnectAccountAPI } from "apis/controllers/transactions/PayoutsController";
import { getUserPaymentInfoAPI } from "apis/controllers/person/PersonPaymentInfoController";
import { getIPAPI } from "apis/controllers/IPsController";
import { getBankingRequirementsAPI } from "apis/controllers/transactions/PayoutsController";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";


function StripeConnectAccount(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const {country, requiredFields, banking, purpose} = props.routerProp.location.state;
	const {accountNumberSample, routingNumberSample} = banking;

	const history = useHistory();

	const [alert, setAlert] = useState();
	const [modal, setModal] = useState();
	const [spinner, setSpinner] = useState();
	const [textInputs, setTextInputs] = useState();
	const [businessUrlTooltip, setBusinessUrlTooltip] = useState(),
		[bankingTooltip, setBankingTooltip] = useState(),
		[documentTooltip1, setDocumentTooltip1] = useState(null),
		[documentTooltip2, setDocumentTooltip2] = useState(null);

	// pass value to BE
	const [urlBusinessProfile, setUrlBusinessProfile] = useState(),
		[govtIdNumber, setGovtIdNumber] = useState(),
		[ssn, setSsn] = useState();
	// use tokens for these
	const [cardFormDisplay, setCardFormDisplay] = useState("hide"),
		// setting this to be shown by default while we're allowing bank payouts
		[bankFormDisplay, setBankFormDisplay] = useState();
	const [cardLabel, setCardLabel] = useState(messageMap("payouts.card", "labelPlaceholder")),
		[bankLabel, setBankLabel] = useState(messageMap("payouts.bank", "labelPlaceholder"));
	const [cardNumber, setCardNumber] = useState(null),
		[cardMonthYearExpiration, setCardMonthYearExpiration] = useState(null),
		[cardCvc, setCardCvc] = useState(null);
	const [bankAcctNumber, setBankAcctNumber] = useState(null),
		[bankCountry, setBankCountry] = useState(country),
		[bankRoutingNumber, setBankRoutingNumber] = useState(null),
		[sampleBankAcctNumber, setSampleBankAcctNumber] = useState(accountNumberSample),
		[sampleBankRoutingNumber, setSampleBankRoutingNumber] = useState(routingNumberSample);
	// for submitting
	const [allFieldsFilled, setAllFieldsFilled] = useState(false);

	// DOM states for id & address documents
	const [idRadioOptions, setIdRadioOptions] = useState(),
		[addressRadioOptions, setAddressRadioOptions] = useState(),
		[idUploadInputs, setIdUploadInputs] = useState([]),
		[addressUploadInputs, setAddressUploadInputs] = useState([]);
	// value states for id & address documents
	const [idUploads1, setIdUploads1] = useState(),
		[idUploads2, setIdUploads2] = useState(),
		[addressUploads1, setAddressUploads1] = useState(),
		[addressUploads2, setAddressUploads2] = useState(),
		[idDocumentName, setIdDocumentName] = useState(),
		[addressDocumentName, setAddressDocumentName] = useState();

	const cardOptionRef = useRef(),
		bankOptionRef = useRef();

	const idDocumentsRefs = useRef([]),
		addressDocumentRefs = useRef([]),
		bankingRequirementsRef = useRef();

	const requiredFieldsMapRef = useRef();

	useEffect(() => {
		getSavedSensitiveInformation(callbackArgs => {
			createTextInputs();
			createIdAndAddressOptions({
				idDocument: callbackArgs.suppliedIdVerification,
				addressDocument: callbackArgs.suppliedAddressVerification
			})
		});
		createRequiredFieldsMap();

		getBankingRequirements();
	}, []);

	function getSavedSensitiveInformation(callback) {
		getUserPaymentInfoAPI(ownerId, resp => {
			setUrlBusinessProfile(resp.businessProfileUrl);
			callback({
				idDocument: resp.suppliedIdVerification,
				addressDocument: resp.suppliedAddressVerification
			});

			if (resp.lastFourCardNumber != null) {
				setCardLabel(
					`${messageMap("payouts.card", "labelPlaceholder")} (${resp.lastFourCardNumber})`
				);
			}
			else if (resp.lastFourAccountNumber != null) {
				setBankLabel(
					`${messageMap("payouts.bank", "labelPlaceholder")} (${resp.lastFourAccountNumber})`
				);
			}
		});
	}

	function createTextInputs(businessProfileUrl) {
		const govtIdNumberRequirement = requiredFields.filter(field => field.includes("govtIdNumber"));
		let govtIdNumberRange = govtIdNumberRequirement.length ? govtIdNumberRequirement[0].split(":")[1] : null;
		govtIdNumberRange = govtIdNumberRange === null ? null : govtIdNumberRange.includes("_") ? govtIdNumberRange.split("_") : [govtIdNumberRange, govtIdNumberRange];
		/**
		 * TESTING VALUES FROM STRIPE:
		 * 1.) 000000000	Successful verification. 0000 also works for SSN last 4 verification.
		 * 2.) 111111111	Unsuccessful verification (identity mismatch).
		 * 3.) 222222222	Successful, immediate verification. The verification result is returned directly in the response, not as part of a webhook event.
		 */
		const formDom1 = {
			govtIdNumber: {
				key: "govtIdNumber",
				inputType: "text",
				inputMinLength: govtIdNumberRange === null ? -1 : govtIdNumberRange[0],
				inputMaxLength: govtIdNumberRange === null ? -1 :govtIdNumberRange[1],
				isRequired: govtIdNumberRange != null
			},
			ssn: {
				key: "ssn",
				inputType: "number",
				inputMinLength: 4,
				inputMaxLength: 4,
				placeholder: "xxxx",
				isRequired: requiredFields.includes("ssn")
			},
		};

		let inputTexts = Object.keys(formDom1).map(formField =>
			<div className={`page-field ${formField === "ssn" ? (country !== "US" ? "hide" : "") : ""}`} key={formField} >
				<label className="page-field-label" htmlFor={formDom1[formField]["key"]} >
					<div className="div-label">
						{messageMap(`payouts.stripeConnect.fields.${formDom1[formField]["key"]}`, "generic")}
					</div>
					{
						formDom1[formField]["isRequired"] && "*"
					}
				</label>
				<div className="inline-elements">
					<input id={formDom1[formField]["key"]} className="page-field-value" type="text"
						required aria-required="true"
						placeholder={formDom1[formField]["placeholder"] ? formDom1[formField]["placeholder"] : ""}
						minLength={formDom1[formField]["inputMinLength"]} maxLength={formDom1[formField]["inputMaxLength"]}
						onChange={e => onChangeInput(e, formDom1[formField]["key"], formDom1[formField]["inputType"] === "number")} >
					</input>
				</div>
			</div>
		);

		setTextInputs(inputTexts);
	}
	function createIdAndAddressOptions(uploadedDocuments) {
		let idOptions = [],
			addressOptions = [];

		if (uploadedDocuments.idDocument != null) {
			idOptions.push(
				<div key="supplied-id-doc" className="verification-document-container">
					{`${messageMap("payouts.stripeConnect.provided", "generic")} ${uploadedDocuments.idDocument}`}
				</div>
			);
		}
		else {
			idDocumentExamples[country].forEach((example, index) => {
				idOptions.push(
					<div key={example.key} className="verification-document-container">
						<input id={example.key} className="page-field-value radio-input max-width-50"
							onClick={e => showDocUploads(e, "id", example.sides, example.key)} type="radio"
							onKeyPress={e => onKeyDown(e, showDocUploads, [e, "id", example.sides, example.key], true)}
							ref={el => idDocumentsRefs.current[index] = el}>
						</input>
						<label htmlFor={example.key} className="page-field-label radio-label">
							{example.key}
						</label>
					</div>
				);
			});
		}

		if (uploadedDocuments.addressDocument != null) {
			addressOptions.push(
				<div key="supplied-address-doc" className="verification-document-container">
					{`${messageMap("payouts.stripeConnect.provided", "generic")} ${uploadedDocuments.addressDocument}`}
				</div>
			);
		}
		else {
			addressDocumentExamples[country] != null && addressDocumentExamples[country].forEach((example, index) => {
				addressOptions.push(
					<div key={example.key} className="verification-document-container">
						<input id={example.key} className="page-field-value radio-input max-width-50"
							onClick={e => showDocUploads(e, "address", example.sides, example.key)} type="radio"
							onKeyPress={e => onKeyDown(e, showDocUploads, [e, "address", example.sides, example.key], true)}
							ref={el => addressDocumentRefs.current[index] = el}>
						</input>
						<label htmlFor={example.key} className="page-field-label radio-label">
							{example.key}
						</label>
					</div>
				);
			});
		}

		setIdRadioOptions(idOptions);
		setAddressRadioOptions(addressOptions);
	}

	function createRequiredFieldsMap() {
		// set everything to false to indicate that the required fields aren't populated yet
		let requiredFieldsObj = {};
		requiredFields.forEach(requirement => {
			if (["urlBusinessProfile", "ssn"].includes(requirement)) {
				requiredFieldsObj[requirement] = false;
			}
			else if (requirement.includes("govtIdNumber")) {
				requiredFieldsObj["govtIdNumber"] = false;
			}
			else if (requirement === "addressVerification") {
				requiredFieldsObj["address"] = false;
			}
			else if (requirement === "idVerification") {
				requiredFieldsObj["id"] = false;
			}
		}); 

		// include card & bank fields by default
		requiredFieldsObj["cardNumber"] = false;
		requiredFieldsObj["monthYearExp"] = false;
		requiredFieldsObj["cvc"] = false;
		requiredFieldsObj["accountNumber"] = false;
		requiredFieldsObj["routingNumber"] = false;

		requiredFieldsMapRef.current = requiredFieldsObj;
	}

	function getBankingRequirements() {
		getBankingRequirementsAPI(resp => {
			bankingRequirementsRef.current = resp;
		});
	}

	function showDocUploads(e, idOrAddress, numSides, docName) {
		const docUploadInputsMap = {
			id: setIdUploadInputs,
			address: setAddressUploadInputs
		};
		const docNameMap = {
			id: setIdDocumentName,
			address: setAddressDocumentName
		};
		const docUploadRefMap = {
			id: idDocumentsRefs,
			address: addressDocumentRefs
		};
		const validationArgs = {
			fileFormats: [".png", ".jpg", ".jpeg"],
			widthRange: [0, 8000],
			heightRange: [0, 8000],
			memoryLimit: 10
		};

		docUploadRefMap[idOrAddress].current.forEach(docOption => {
			docOption.checked = false;
		});
		e.target.checked = true;

		let uploadInputs = [];
		uploadInputs.push(
			<UploadDocumentPicture key="front" setAlert={setAlert}
				uploadedFileSetter={filename => saveUploadedFile("front", idOrAddress, filename, numSides === 2)}
				label="Front" validation={validationArgs} fileTypes={validationArgs.fileFormats} />
		);

		if (numSides === 2) {
			uploadInputs.push(
				<UploadDocumentPicture key="back" setAlert={setAlert}
				uploadedFileSetter={filename => saveUploadedFile("front", idOrAddress, filename, numSides === 2)}
					label="Back" validation={validationArgs} fileTypes={validationArgs.fileFormats} />
			);
		}

		docUploadInputsMap[idOrAddress](uploadInputs);
		docNameMap[idOrAddress](docName);
	}

	function saveUploadedFile(frontOrBack, idOrAddress, uploadedFile, hasTwoSides) {
		const docUploadMap = {
			id: {
				front: setIdUploads1,
				back: setIdUploads2
			},
			address: {
				front: setAddressUploads1,
				back: setAddressUploads2
			}
		};

		docUploadMap[idOrAddress][frontOrBack](uploadedFile);

		if ((purpose === "create" && Object.keys(requiredFieldsMapRef.current).includes(idOrAddress))
			|| (["update", "updatePayout"].includes(purpose))) {
			requiredFieldsMapRef.current[idOrAddress] = true;
			setAllFieldsFilled(purpose === "create" ? areAllRequiredFieldsFilled(requiredFieldsMapRef.current) : true);
		}
	}

	function showCardForm() {
		setCardFormDisplay();

		setBankFormDisplay("hide");
		bankOptionRef.current.checked = false;
	}

	function showBankForm() {
		setBankFormDisplay();

		setCardFormDisplay("hide");
		// hiding this for now, since we're only allowing users to receive payouts to their bank
		// cardOptionRef.current.checked = false;
	}

	const onChangeMap = {
		urlBusinessProfile: setUrlBusinessProfile,
		govtIdNumber: setGovtIdNumber,
		ssn: setSsn,
		cardNumber: setCardNumber,
		monthYearExp: setCardMonthYearExpiration,
		cvc: setCardCvc,
		accountNumber: setBankAcctNumber,
		routingNumber: setBankRoutingNumber
	};
	function onChangeInput(e, changeType, isNumber) {
		const target = e.target;
		if (changeType === "countrykey") {
			const country = target.getAttribute("countrykey");
			const countryAlphaCode = countriesToAlphaCode[country];
			setBankCountry(country);

			const countryBankRequirements = bankingRequirementsRef.current[countryAlphaCode];
			setSampleBankAcctNumber(countryBankRequirements["accountNumberSample"]);
			const newBankRoutingSample = countryBankRequirements["routingNumberSample"];
			setSampleBankRoutingNumber(newBankRoutingSample);
			newBankRoutingSample === "-1" && onChangeMap["routingNumber"](null);
		}
		else {
			if (isNumber && !numberValidator(target.value)) {
				setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.notNumber", "validation")} />
				);
			}
			else {
				onChangeMap[changeType](target.value);

				if ((purpose === "create" && Object.keys(requiredFieldsMapRef.current).includes(changeType))
					|| (["update", "updatePayout"].includes(purpose))) {
					requiredFieldsMapRef.current[changeType] = true;
					setAllFieldsFilled(purpose === "create" ? areAllRequiredFieldsFilled(requiredFieldsMapRef.current) : true);
				}
			}
		}
	}
	function closeAlert() {
		setAlert(null);
	}

	function toggleBusinessUrlTooltip(showOrHide) {
		if (showOrHide === "show") {
			setBusinessUrlTooltip(
				<Tooltip classStr="tooltip-bottom-left stripe-connect-business-url" subheader={messageMap("stripe.connect.businessUrl", "tooltip")} />
			);
		}
		else {
			setBusinessUrlTooltip(null);
		}
	}
	function toggleBankingTooltip(showOrHide) {
		if (showOrHide === "show") {
			const tooltipArray = sampleBankRoutingNumber !== "-1" ? ["account", "routing"] : ["account"];
			const tooltipPoints = tooltipArray.map(point => {
				const sample = point === "account" ? sampleBankAcctNumber : sampleBankRoutingNumber

				return {
					key: point,
					text: `${messageMap(`stripe.connect.banking.${point}`, "tooltip")} ${sample}`
				};
			});
			setBankingTooltip(
				<Tooltip classStr="tooltip-bottom-left stripe-connect-banking" list={tooltipPoints} />
			);
		}
		else {
			setBankingTooltip(null);
		}
	}
	function toggleDocumentTooltip(whichToolTip, showOrHide) {
		const tooltipMap = {
			1: setDocumentTooltip1,
			2: setDocumentTooltip2
		};

		if (showOrHide === "show") {
			const tooltipPoints = ["d0", "d1", "d2", "d3", "d4", "d5"].map(point => {
				return {
					key: point,
					text: messageMap(`stripe.connect.documents.${point}`, "tooltip")
				};
			});
			tooltipMap[whichToolTip](
				<Tooltip classStr="tooltip-bottom-left stripe-connect-documents" list={tooltipPoints} />
			);
		}
		else {
			tooltipMap[whichToolTip](null);
		}
	}

	function areAllRequiredFieldsFilled(currentRequiredFieldsMap) {
		let allFilled = true;
		let filledCardFields = 0;
		let filledBankFields = 0;
		const cardFields = ["cardNumber", "monthYearExp", "cvc"];
		const bankFields = ["accountNumber", "routingNumber"];

		for (const [key, value] of Object.entries(currentRequiredFieldsMap)) {
			if (cardFields.includes(key)) {
				value && ++filledCardFields;
			}
			else if (bankFields.includes(key)) {
				value && ++filledBankFields;
			}
			else if (value === false) {
				allFilled = false;
				break;
			}
		}

		const requiredBankFields = sampleBankRoutingNumber === "-1" ? 1 : 2;
		return allFilled && (filledCardFields === 3 || filledBankFields === requiredBankFields);
	}
	function createConnectAccount() {
		if (allFieldsFilled) {
			getIPAPI(ip => {
				const monthYearCardExp = cardMonthYearExpiration != null ? cardMonthYearExpiration.split("-") : null;

				const payload = {
					[OWNER_ID]: ownerId ? ownerId : null,
					/************ Personal Information ***************/
					[URL_BUSINESS_PROFILE]: urlBusinessProfile,
					[GOVT_ID_NUMBER]: govtIdNumber,
					[SSN]: ssn,
					/************ Payout Method ***************/
					// hiding this for now while we're allowing bank payouts
					// // Card
					// [CARD_NUMBER]: cardOptionRef.current.checked ? cardNumber : null,
					// [CARD_EXP_MONTH]: cardOptionRef.current.checked && monthYearCardExp !== null ? monthYearCardExp[1] : null,
					// [CARD_EXP_YEAR]: cardOptionRef.current.checked && monthYearCardExp !== null ? monthYearCardExp[0] : null,
					// [CVC]: cardOptionRef.current.checked ? cardCvc : null,
					// Bank
					// the 3 following fields will need to look at 'bankOptionRef.current.checked' once we start supporting card payouts
					[BANK_COUNTRY]: bankCountry,
					[CHECKING_ACCOUNT_NUMBER]: bankAcctNumber,
					[ROUTING_NUMBER]: bankRoutingNumber,
					/************ ID & Address Document Verification ***************/
					[ID_DOCUMENT_1]: idUploads1,
					[ID_DOCUMENT_2]: idUploads2,
					[ADDRESS_DOCUMENT_1]: addressUploads1,
					[ADDRESS_DOCUMENT_2]: addressUploads2,
					[ID_DOCUMENT_NAME]: idDocumentName,
					[ADDRESS_DOCUMENT_NAME]: addressDocumentName,
					/************ Terms of service information ***************/
					[IP_TOS]: ip,
					[USER_AGENT_TOS]: window.navigator.userAgent
				};

				let fieldsToValidate = [];
				for (const [key, value] of Object.entries(payload)) {
					value && fieldsToValidate.push(key);
				}

				setSpinner(
					<Spinner containerClass="stripe-connect" />
				);

				saveStripeConnectAccountAPI(payload, fieldsToValidate, response => {
					let title;
					let msg = null;

					if (response.error != null) {
						title = messageMap(response.responseCode, "api");
						msg = (
							<Fragment>
								<div className="account-group">
									{messageMap("payout.connectAccount.created.failed1", "api")}
								</div>
								<div className="group-details">
									{response.error}
								</div>
								<div className="account-group">
									{messageMap("payout.connectAccount.created.failed2", "api")}
								</div>
							</Fragment>
						);
					}
					else if (response.responseCode != null) {
						if (response.responseCode.includes("success")) {
							title = messageMap(response.responseCode, "api");
							let requirementMsgs;

							if (response.requirements != null && response.requirements.currentDeadline != null) {
								let requirements = [];
								response.requirements.currentlyDue.forEach((req, index) => {
									requirements.push(
										<li key={`due_${index}`}>
											{req}
										</li>
									);
								});
								requirementMsgs = requirements.length > 0 && (
									<Fragment>
										<div className="account-group">
											{messageMap("payout.connectAccount.created.additionalRequirements", "api")}
										</div>
										<div className="group-details">
											<ul>
												{requirements}
											</ul>
										</div>
									</Fragment>
								);
							}

							msg = (
								<Fragment>
									<div className="account-group">
										{`${messageMap("payout.connectAccount.created.accountStatus", "api")} ${response.requirements.verificationStatus}`}
									</div>
									{
										response.requirements.verificationDetails != null && (
											<div>
												{response.requirements.verificationDetails}
											</div>
										)
									}

									{requirementMsgs}
								</Fragment>
							);
						}
						else {
							setAlert(
								<Alert type={INFORMATION} closeHandler={closeAlert} msg={messageMap(response.responseCode, "api")} />
							);
						}
					}

					if (msg != null) {
						setModal(
							<Modal modalClass="become-tutor-modal" title={title}
								closeType="xButton" closeHandler={closeModal}>

								<div className="stripe-connect">
									{msg}

									<div className="inline-elements">
										<button onClick={e => goToProfile("account")} className="navigation-button left">
											{messageMap("stripeConnect.goToAccountProfile", "button")}
										</button>
										<button onClick={e => goToProfile("tutoring")} className="navigation-button">
											{messageMap("stripeConnect.goToTutorProfile", "button")}
										</button>
									</div>
								</div>
							</Modal>
						);
					}

					setSpinner(null);
				});
			});
		}
	}
	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button", "cancel-button"].includes(e.target.className))) {
			setTimeout(() => {
				setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}
	function goToProfile(accountOrTutor) {
		history.push(`/profile?show=${accountOrTutor}`);
	}

	let modifiedFullCountriesList = structuredClone(availableCountriesObject);
	modifiedFullCountriesList["chooseCountry"] = messageMap("tutoring.dropdowns.bankCountry", "button");
	return (
		<Fragment>
			{alert}
			{modal}
			{spinner}

			<div className="stripe-connect-account-page">
				<Helmet>
					<title>{messageMap("stripeConnect.title", "headerTag")}</title>
					<meta name="description" content={messageMap("stripeConnect.description", "headerTag")}></meta>
				</Helmet>

				<div className="title">
					{messageMap("payouts.stripeConnect.title", "generic")}
				</div>

				{
					["create", "update"].includes(purpose) && (
						<Fragment>
							<div className="sub-headers">
								{
									["1", "2", "3", "4", "5"].map(count =>
										<p className="sub-header-spacing" key={count}>
											{messageMap(`payouts.stripeConnect.subheader${count}`, "generic")}
										</p>
									)
								}
							</div>

							<div className="page-field" >
								<label className="page-field-label" htmlFor="urlBusinessProfile" >
									<div className="div-label">
										{messageMap("payouts.stripeConnect.fields.urlBusinessProfile", "generic")}
									</div>
									{
										requiredFields.includes("urlBusinessProfile") && "*"
									}
									<img src={labelInfoAsset} alt="information" tabIndex={0} className="info-icon banking-info"
										onMouseOver={e => toggleBusinessUrlTooltip("show")} onFocus={e => toggleBusinessUrlTooltip("show")}
										onMouseLeave={e => toggleBusinessUrlTooltip("hide")} onBlur={e => toggleBusinessUrlTooltip("hide")} ></img>
									{businessUrlTooltip}
								</label>
								<div className="inline-elements">
									<input id="urlBusinessProfile" className="page-field-value" type="text"
										required aria-required="true"
										placeholder={urlBusinessProfile} minLength={0} maxLength={100}
										onChange={e => onChangeInput(e, "urlBusinessProfile", false)} >
									</input>
								</div>
							</div>

							{textInputs}
						</Fragment>
					)
				}

				<div className="page-field multi-lines">
					<label className="page-field-label align-to-form">
						<div className="div-label">
							{messageMap("payouts.stripeConnect.fields.payoutMethod", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements">
						<div className="multi-line-inlines">
							<div className="inline-elements">
								{/* Allowing users to receive payouts to card requires creating a client-side token which would then
									need to be passed to the BE and creating client-side tokens requires too many steps at this point*/}
								{/* <div className="radio-checkbox-container">
									<input id="cardOption" className="page-field-value radio-input"
										onClick={showCardForm}
										onKeyPress={e => onKeyDown(e, showCardForm, [e], true)}
										type="radio" ref={cardOptionRef}>
									</input>
									<label htmlFor="cardOption" className="page-field-label radio-label">
										{cardLabel}
									</label>
								</div> */}
								<div className="radio-checkbox-container">
									{/* hiding this while we're allowing bank payouts */}
									{/* <input id="bankOption" className="page-field-value radio-input"
										onClick={showBankForm}
										onKeyPress={e => onKeyDown(e, showBankForm, [e], true)}
										type="radio" ref={bankOptionRef}>
									</input> */}
									<label htmlFor="bankOption" className="page-field-label radio-label banking">
										{bankLabel}
									</label>
									<img src={labelInfoAsset} alt="information" tabIndex={0} className="info-icon banking-info"
										onMouseOver={e => toggleBankingTooltip("show")} onFocus={e => toggleBankingTooltip("show")}
										onMouseLeave={e => toggleBankingTooltip("hide")} onBlur={e => toggleBankingTooltip("hide")} ></img>
									{bankingTooltip}
								</div>
							</div>

							{/* 
								NOTE*: Stripe does not provide a test card when creating Connect Accounts. Strangely, 
								they provide test cards for other scenarios
							*/}
							<div className={`dynamic-forms ${cardFormDisplay}`}>
								<input className="page-field-value input-div " type="text"
									placeholder={messageMap("payouts.cardNumber", "labelPlaceholder")}
									required aria-required="true" minLength={0} maxLength={16}
									onChange={e => onChangeInput(e, "cardNumber", true)} >
								</input>
								<div className="inline-elements">
									{/* Different countries have different date format, so no need to be fancy here */}
									<input className="page-field-value right-margin-2" type="month"
										required aria-required="true"
										onChange={e => onChangeInput(e, "monthYearExp")} >
									</input>
									<input className="page-field-value" type="text"
										placeholder={messageMap("payouts.securityCode", "labelPlaceholder")}
										required aria-required="true" minLength={3} maxLength={4}
										onChange={e => onChangeInput(e, "cvc", true)} >
									</input>
								</div>
							</div>
							<div className={`dynamic-forms ${bankFormDisplay}`}>
								<Dropdown customDropdownItemAttribute="countrykey" customContainerClass="stripe-connect no-right-margin"
									dropdownOptions={modifiedFullCountriesList} dropdownItemClickHandler={onChangeInput}
									preselectedKey={countryAlphaCodeToName[country]} />
								{/* 
									TESTING VALUES FROM STRIPE:
									1.) 000999999991	Triggers and completes the bank account ownership verification process after a short delay
									2.) 000999999992	Triggers the bank account ownership verification process after a short delay and requests for document upload
								 */}
								<input className="page-field-value input-div " type="text"
									placeholder={messageMap("payouts.checkingAccount", "labelPlaceholder")}
									required aria-required="true"
									maxLength={sampleBankAcctNumber.length}
									// explicitly passing 'false' argument, since it turns out, checking account numbers for some countries include letters
									onChange={e => onChangeInput(e, "accountNumber", false)} >
								</input>
								{
									// TESTING VALUE FROM STRIPE: 110000000
									<input className={`page-field-value input-div ${sampleBankRoutingNumber === "-1" && "hide"}`}
										type="text" placeholder={messageMap("payouts.routingNumber", "labelPlaceholder")}
										required aria-required="true"
										// explicitly passing 'false' argument, since it turns out, routing account numbers for some countries include letters
										onChange={e => onChangeInput(e, "routingNumber", false)} >
									</input>
								}
							</div>
						</div>
					</div>
				</div>

				{
					["create", "update"].includes(purpose) && (
						<Fragment>
							<div className="page-field multi-lines">
								<label className="page-field-label align-to-form">
									<div className="div-label">
										{messageMap("payouts.stripeConnect.fields.idVerification", "generic")}
									</div>
									{
										requiredFields.includes("idVerification") && "*"
									}
									<img src={labelInfoAsset} alt="information" tabIndex={0} className="info-icon"
										onMouseOver={e => toggleDocumentTooltip(1, "show")} onFocus={e => toggleDocumentTooltip(1, "show")}
										onMouseLeave={e => toggleDocumentTooltip(1, "hide")} onBlur={e => toggleDocumentTooltip(1, "hide")} ></img>
									{documentTooltip1}
								</label>
								<div className="inline-elements">
									<div className="multi-line-inlines">
										<div className="inline-elements with-wrapping">
											{idRadioOptions}
										</div>
										<div className="picture-uploads-container">
											{idUploadInputs}
										</div>
									</div>
								</div>
							</div>

							<div className={`page-field multi-lines ${addressDocumentExamples[country] != null ? "" : "hide"}`}>
								<label className="page-field-label align-to-form">
									<div className="div-label">
										{messageMap("payouts.stripeConnect.fields.addressVerification", "generic")}
									</div>
									{
										requiredFields.includes("addressVerification") && "*"
									}
									<img src={labelInfoAsset} alt="information" tabIndex={0} className="info-icon"
										onMouseOver={e => toggleDocumentTooltip(2, "show")} onFocus={e => toggleDocumentTooltip(2, "show")}
										onMouseLeave={e => toggleDocumentTooltip(2, "hide")} onBlur={e => toggleDocumentTooltip(2, "hide")} ></img>
									{documentTooltip2}
								</label>
								<div className="inline-elements">
									<div className="multi-line-inlines">
										<div className="inline-elements with-wrapping">
											{addressRadioOptions}
										</div>
										<div className="picture-uploads-container">
											{addressUploadInputs}
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
				}

				<button className={`submit-button ${!allFieldsFilled && "not-allowed"}`}
					onClick={createConnectAccount}>
					{
						purpose === "create" ? 
						messageMap("stripeConnect.createConnectAccount", "button")
						:
						messageMap("stripeConnect.updateConnectAccount", "button")
					}
				</button>

			</div>
		</Fragment>
	);
}

StripeConnectAccount.propTypes = {
	routerProp: PropTypes.object.isRequired,

	// redux states
	ownerId: PropTypes.string.isRequired
};

export default connect(
	account
)(StripeConnectAccount);
