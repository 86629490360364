import React, { useState, useEffect, Fragment }  from "react";
import PropTypes from "prop-types";

import ComparisonRow from "pages/Tutoring/subPages/components/ComparisonRow";

import messageMap from "Utilities/MessageMaps";
import { 
	normalizeExternalLink,
	getTutorRatesRange, calculateTutorAvgRating, getTutorSchedule,
	transformCancellationPoliciesStringToObject, getTutorCancellationPolicy
} from "pages/Tutoring/utilities/TutorProfileUtility";
import { showPdfFileInTab } from "Utilities/FileUtility";
import { TUTOR_USER } from "pages/Tutoring/utilities/TutoringRequestConstants";

import SubjectTopicPriceBreakdown from "pages/Tutoring/subPages/components/SubjectTopicPriceBreakdown";

import linkedInAsset from "assets/icons/thirdParty/linkedIn.svg";
import websiteAsset from "assets/icons/thirdParty/website.svg";
import attachmentAsset from "assets/icons/common/attachment.svg";


function TutorComparisonModal(props) {

	const [overview1, setOverview1] = useState(),
		[overview2, setOverview2] = useState(),
		[externalLinks1, setExternalLinks1] = useState(),
		[externalLinks2, setExternalLinks2] = useState(),
		[priceBreakdown1, setPriceBreakdown1] = useState(),
		[priceBreakdown2, setPriceBreakdown2] = useState(),
		[schedule1, setSchedule1] = useState(),
		[schedule2, setSchedule2] = useState(),
		[cancellation1, setCancellation1] = useState(),
		[cancellation2, setCancellation2] = useState();

	useEffect(() => {
		renderComparisonRows();
	}, []);

	function renderComparisonRows() {
		let overviews = [];
		let externalLinks = [];
		let priceBreakdowns = [];
		let schedules = [];
		let policies = [];

		props.tutorProfiles.forEach(profile => {
			let bio = [];
			profile.bio.split(/[\r\n]+/).forEach((bioDetails, indx) => {
				bio.push(
					<Fragment key={`bio_${indx}`}>
						<div>
							{bioDetails}
						</div>
						<br/>
					</Fragment>
				);
			});

			overviews.push(
				<div key={profile.tutorProfileId} className="tutor-headline">
					<div className="header">
						<img className="tutor-picture" src={profile.profilePictureUploadLocation} alt={messageMap("tutoring.tutorPicture", "image")} />
						<div className="tutor-header">
							<div className="full-name">
								{`${profile.firstName} ${profile.lastName}`}
							</div>
							<div className="occupation">
								{profile.occupation}
							</div>
						</div>
					</div>
					<div className="price-range">
						{getTutorRatesRange(profile.teachingRates)}
					</div>
					<div className="star-container">
						{calculateTutorAvgRating(props.tutoringReviews[profile.tutorOwnerId])}
					</div>
					<div className="bio">
						{bio}
					</div>
				</div>
			);

			externalLinks.push(
				<div key={profile.tutorProfileId} className="external-credentials">
					{
						(profile.linkedInUrl != null && profile.linkedInUrl !== "")
						&& (
							<a href={normalizeExternalLink(profile.linkedInUrl)} target="_blank" rel="noreferrer">
								<button className="link-button">
									<img className="linked-in" src={linkedInAsset} alt={messageMap("tutoring.tutorList.linkedIn", "image")}/>
								</button>
							</a>
						)
					}
					{
						(profile.websiteUrl != null && profile.websiteUrl !== "")
						&& (
							<a href={normalizeExternalLink(profile.websiteUrl)} target="_blank" rel="noreferrer">
								<button className="link-button">
									<img className="web-link" src={websiteAsset} alt={messageMap("tutoring.tutorList.website", "image")}/>
								</button>
							</a>
						)
					}
					{
						(profile.resumeUploadLocationUploadLocation != null && profile.resumeUploadLocationUploadLocation !== "")
						&& (
							<button className="link-button attachment"
								onClick={e => showPdfFileInTab(profile.resumeUploadLocationUploadLocation)}>
									<img className="attachment-icon" src={attachmentAsset} alt={messageMap("tutoring.tutorList.resume", "image")}/>
									{messageMap("tutoringPage.tutorList.pane.resume", "generic")}
							</button>
						)
					}
				</div>
			);

			priceBreakdowns.push(
				<div key={profile.tutorProfileId} className="price-breakdown">
					<SubjectTopicPriceBreakdown tutorFirstName={profile.firstName} tutorTimezone={profile.timezone}
						tutorTier={profile.tutorTier} schedule={profile.schedules}
						showBookOption={false} tutorProfileId={profile.tutorProfileId} tutorOwnerId={profile.tutorOwnerId}
						teachingRates={profile.teachingRates} subjectToTopicsMap={profile.subjectToTopicsMap}
						setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
				</div>
			);

			schedules.push(
				<div key={profile.tutorProfileId} className="schedule-container">
					{getTutorSchedule(profile.schedules, profile.timezone)}
				</div>
			);

			policies.push(
				<div key={profile.tutorProfileId} className="policy-container">
					{
						getTutorCancellationPolicy(
							transformCancellationPoliciesStringToObject(profile.cancellationPolicies, TUTOR_USER)
						)
					}
				</div>
			);
		});

		setOverview1(overviews[0]);
		setOverview2(overviews[1]);
		setExternalLinks1(externalLinks[0]);
		setExternalLinks2(externalLinks[1]);
		setPriceBreakdown1(priceBreakdowns[0]);
		setPriceBreakdown2(priceBreakdowns[1]);
		setSchedule1(schedules[0]);
		setSchedule2(schedules[1]);
		setCancellation1(policies[0]);
		setCancellation2(policies[1]);
	}

	return (
		<div className="side-by-side-container">
			<ComparisonRow label={messageMap("tutoringPage.tutorList.modal.tutorOverview", "generic")} tutor1Contents={overview1} tutor2Contents={overview2}/>
			<ComparisonRow label={messageMap("tutoringPage.tutorList.modal.credentials", "generic")} tutor1Contents={externalLinks1} tutor2Contents={externalLinks2}/>
			<ComparisonRow label={messageMap("tutoringPage.tutorList.modal.offerings", "generic")} tutor1Contents={priceBreakdown1} tutor2Contents={priceBreakdown2}/>
			<ComparisonRow label={messageMap("tutoringPage.tutorList.modal.schedule", "generic")} tutor1Contents={schedule1} tutor2Contents={schedule2}/>
			<ComparisonRow label={messageMap("tutoringPage.tutorList.modal.policy", "generic")} tutor1Contents={cancellation1} tutor2Contents={cancellation2}/>
		</div>
	);
}

TutorComparisonModal.propTypes = {
	tutorProfiles: PropTypes.array.isRequired,
	tutoringReviews: PropTypes.object.isRequired,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired
};

export default TutorComparisonModal;

