import React, { useState, useEffect, Fragment }  from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import { PubNubProvider } from "pubnub-react";
import { Chat, MessageList, MessageInput, TypingIndicator } from "@pubnub/react-chat-components";

import Spinner from "templates/Spinner";
import Alert, {ERROR, SUCCESS} from "templates/Alert";
import ConfirmModal from "templates/customModals/ConfirmModal";

import { 
	AUTO_REDIRECT_TIMEOUT, ALERT_THIRTY_SECONDS_TIMEOUT, MODAL_CLOSE_TIMEOUT
} from "Utilities/Constants/TimeoutConstants";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import { $replace } from "Utilities/Strings";
import { promiseAll, POST, GET } from "Utilities/Fetches";
import { CHAT_THEME_SUPPORT } from "pages/Tutoring/utilities/TutoringChatConstants";
import { topicsInSubjects } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { 
	PENDING_STATE, OPEN_STATE,
	ACCEPT_DECISION, DECLINE_DECISION
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { parseDecisionResponse } from "../utilities/TutorRequestUtility";
import TutorProfilePane from "pages/Tutoring/subPages/panes/TutorProfilePane";

import { acceptTutorRequestApplicationAPI, declineTutorRequestApplicationAPI } from "apis/controllers/tutoring/SessionRequestController";
import { GET_TUTOR_PROFILE } from "apis/controllers/tutoring/TutorProfileController";
import { GET_ACCOUNT_SETTINGS } from "apis/controllers/person/AccountsController";
import { deleteChatChannelAPI } from "apis/controllers/tutoring/TutoringRecordController";
import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";

import ellipsisVerticalAsset from "assets/icons/common/ellipsis_vertical.svg";


function TutoringMessages(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const history = useHistory();

	const [newChannel, setNewChannel] = useState(props.channels[0].id),
		[chosenChannel, setChosenChannel] = useState(props.channels[0]),
		[canShowActionButton, setCanShowActionButton] = useState(false),
		[clickableChannelName, setClickableChannelName] = useState(""),
		// DOM states
		[channelName, setChannelName] = useState(props.channels[0].name),
		[channelSubjectTopic, setChannelSubjectTopic] = useState(),
		[channelList, setChannelList] = useState();

	const [spinner, setSpinner] = useState(),
		[tutorProfilePane, setTutorProfilePane] = useState();

	useEffect(() => {
		checkPaymentRedirection();
		renderChannels();
	}, [props.ownerId]);

	// NOTE*: if you're updating this block, you need to update the same block in TutorApplications & RequestItem
	function checkPaymentRedirection() {
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get("tutorId") != null && urlParams.get("sessionId") != null) {
			const pathVariables = {
				tutorProfileId: urlParams.get("tutorId"),
				sessionRequestId: urlParams.get("sessionId")
			};
			acceptTutorRequestApplicationAPI(pathVariables, ownerId, resp => {
				const alertDetails = parseDecisionResponse(resp);

				props.setAlert(
					<Alert type={alertDetails.executionStatus} closeHandler={closeAlert} customClass="tutoring" 
						msg={alertDetails.msg} timeout={ALERT_THIRTY_SECONDS_TIMEOUT} />
				);
			});
		}
	}

	function renderChannels() {
		let onNames = [];
		props.users.forEach(user => {
			if (props.onUsers.includes(user.id)) {
				onNames.push(user.name);
			}
		});

		let channelListDom = [];
		props.channels.forEach(channel => {
			const channelClass = channelListDom.length === 0 ? "channel current" : "channel";

			let descriptionStr = channel.description;
			const splitDesc = descriptionStr.split(":");
			const subject = splitDesc[0];
			const topicState = splitDesc[1].split("_");
			const topic = topicsInSubjects[subject][topicState[0]];
			const state = topicState[1];
			const subjectTopicArrangement = `${subject} : ${topic}`;

			if (channelListDom.length === 0) {
				setChannelSubjectTopic(subjectTopicArrangement);
				setCanShowActionButton(showOrHideActionButtons(state, channel));
				setClickableChannelName(channel.tutorId != null ? "clickable" : "");
			}

			channelListDom.push(
				<div className={channelClass} key={channel.id} tabIndex={0}
					role="button"
					onClick={e => changeChannel(e, channel)}
					onKeyDown={e => onKeyDown(e, changeChannel, [e, channel])}>
					<div className="picture-name-container">
						<div className="picture-container">
							{
								channel.custom.profileUrl ?
								(
									<img className="profile-picture" src={channel.custom.profileUrl} alt="Tutor head shot" />
								)
								: (
									<div className="user-initial-container">
										<div className="user-initial">
											{channel.name[0]}
										</div>
									</div>
								)
							}
							{
								onNames.includes(channel.name)
								?
								<div className={channel.custom.profileUrl ? "on-dot" : "on-dot initial-dot"}></div>
								: ""
							}
						</div>
						<div className="name-description">
							<div className="tutor-name">
								<div className="channel-name">
									{channel.name}
								</div>
							</div>
							<div className="channel-summary">
								<div className="subject-topic">
									{subjectTopicArrangement}
								</div>
								<div className="state">
									{state}
								</div>
							</div>
						</div>
					</div>
					
					<div className="channel-options">
						<img src={ellipsisVerticalAsset} alt={messageMap("ellipsisVertical", "image")}
							className="item-component no-right-space ellipsis-button"
							onClick={toggleItemOptions}
							onKeyDown={e => onKeyDown(e, toggleItemOptions, [e])}
							role="button" tabIndex={0}/>
						<div className="topic-options hide">
							<button className="option"
								onClick={e => showDeleteConfirmationModal(channel.id)}>
								{messageMap("tutoring.chat.deleteChannel", "button")}
							</button>
						</div>
					</div>
				</div>
			);
		});

		setChannelList(channelListDom);
	}

	function showOrHideActionButtons(state, channel) {
		let showActionButtons = false;
		if ([OPEN_STATE, PENDING_STATE].includes(state) && channel.tutorId != null) {
			showActionButtons = true;
		}

		return showActionButtons;
	}

	function changeChannel(e, channelEntity) {
		document.querySelectorAll(`div[class="channel current"]`).forEach(div => {
			div.className = "channel";
		});
		document.querySelectorAll(`img[class="channel current"]`).forEach(img => {
			img.className = "item-component no-right-space ellipsis-button";
		});
		let domTag = e.target;
		if (domTag.tagName === "IMG") {
			domTag = domTag.parentNode;
		}
		let maxLoop = 6;
		while (domTag.getAttribute("role") !== "button" && maxLoop > -1) {
			domTag = domTag.parentNode;
			--maxLoop;
		}
		domTag.className = "channel current";

		const subjectTopicState = channelEntity.description.split("_");
		let subjectTopic = subjectTopicState[0].split(":");
		const subject = subjectTopic[0];
		subjectTopic = `${subject} : ${topicsInSubjects[subject][subjectTopic[1]]}`;

		setCanShowActionButton(showOrHideActionButtons(subjectTopicState[1], channelEntity));
		setClickableChannelName(channelEntity.tutorId != null ? "clickable" : "");
		setNewChannel(channelEntity.id);
		setChannelName(channelEntity.name);
		setChannelSubjectTopic(subjectTopic);
		setChosenChannel(channelEntity);
	}

	function showTutorPane() {
		if (clickableChannelName === "clickable") {
			const pathVariables = {
				tutorOwnerId: chosenChannel.ownerId
			};
	
			const apiArr = [
				{
					api: $replace(GET_TUTOR_PROFILE, pathVariables),
					type: GET
				}, {
					api: GET_ACCOUNT_SETTINGS,
					type: POST,
					payload: {
						ownerId: ownerId
					}
				}
			];
	
			promiseAll(apiArr, resp => {
				const tutorProfile = resp[0].data;
				const personProfile = resp[1].data;
	
				let isSaved = false;
				if (personProfile.savedTutors.includes(tutorProfile.tutorProfileId)) {
					isSaved = true;
				}
	
				setTutorProfilePane(
					<TutorProfilePane tutorDetails={tutorProfile} isSaved={isSaved} closePane={closePane} showBookOption={false}
						setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
				);
			});
		}
	}

	function closePane() {
		setTutorProfilePane(null);
	}

	function showConfirmationModal(decision) {
		let confirmModalTitle = decision === ACCEPT_DECISION ? messageMap("tutoringPage.request.modal.titleAcceptTutors", "generic") : messageMap("tutoringPage.request.modal.titleDeclineTutors", "generic");
		const sessionCost = messageMap("tutoringPage.request.modal.acceptSessionCost", "generic") + chosenChannel.sessionPrice.toFixed(2);

		props.setModal(
			<ConfirmModal title={confirmModalTitle} subHeader={sessionCost}
				closeArgs={decision}
				closeModal={executeDecision} confirmType="ensure" />
		);
	}

	function executeDecision(e, decision) {
		if ([ACCEPT_DECISION, DECLINE_DECISION].includes(decision)) {
			setSpinner(
				<Spinner key="processing" containerClass="processing-payment-method"
				loadingText={`${messageMap("tutoringPage.request.processingPayment1", "generic")} $${chosenChannel.sessionPrice.toFixed(2)} ${messageMap("tutoringPage.request.processingPayment2", "generic")}`} />
			);

			// NOTE*: if you're updating this block, you need to update the same block in TutoringCard & RequestItem
			if (decision === ACCEPT_DECISION) {
				const paymentDescription = `${messageMap("payments.checkout.tutoringSession", "generic")} ${channelName}`;

				const payload = {
					ownerId: props.ownerId,
					paymentAmount: chosenChannel.sessionPrice,
					quantityPurchased: 1,
					paymentDescription: paymentDescription,
					paymentPurpose: "tutoringSession",
					isPaymentOffSession: true,
					sessionId: chosenChannel.sessionId,
					tutorOwnerId: chosenChannel.tutorOwnerId
				};
				createPaymentIntentAPI(payload, resp => {
					if (resp.status === "succeeded") {
						saveDecision(decision);
						setSpinner(null);
					}
					// offload work to Stripe for any other payment status
					else {
						setSpinner(
							<Spinner key="invalidPayment" containerClass="invalid-payment-method"
								loadingText={messageMap("tutoringPage.request.invalidPaymentOrAuthNeeded", "generic")} />
						);

						setTimeout(() => {
							const sessionPrice = chosenChannel.sessionPrice;
							const price = props.isSubscriber ? sessionPrice - (sessionPrice * .1) : sessionPrice;

							props.setPaymentInfo({
								imgRef: 5,
								price: price,
								quantity: 1,
								description: paymentDescription,
								purpose: "tutoringSession"
							});
							history.push({
								pathname: "/checkout",
								state: {
									stripePubKey: resp.stripePubKey,
									returnUrl: "tutoring_show:messages",
									clientSecret: resp.clientSecret,
									tutorId: chosenChannel.tutorId,
									sessionId: chosenChannel.sessionId
								}
							});
							setSpinner(null);
						}, AUTO_REDIRECT_TIMEOUT * 1.25);
					}
				});
			}
			else {
				saveDecision(decision);
				setSpinner(null);
			}
		}
		else {
			closeModal();
		}
	}
	function saveDecision(decision) {
		const decisionMap = {
			[ACCEPT_DECISION]: acceptTutorRequestApplicationAPI,
			[DECLINE_DECISION]: declineTutorRequestApplicationAPI
		};
		const pathVariables = {
			tutorProfileId: chosenChannel.tutorId,
			sessionRequestId: chosenChannel.sessionId
		};

		decisionMap[decision](pathVariables, ownerId, resp => {
			const {msg, executionStatus} = parseDecisionResponse(resp);

			props.setAlert(
				<Alert type={executionStatus} closeHandler={closeAlert} customClass="tutoring" 
					msg={msg} timeout={ALERT_THIRTY_SECONDS_TIMEOUT} />
			);

			if (executionStatus === SUCCESS) {
				props.reRenderChat();
			}
		});
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function toggleItemOptions(e) {
		const siblingsClassName = e.target.nextSibling.className;
		e.target.nextSibling.className = siblingsClassName === "topic-options hide" ? "topic-options" : "topic-options hide";
	}

	function showDeleteConfirmationModal(channelId) {
		props.setModal(
			<ConfirmModal title={messageMap("tutoringPage.request.modal.titleDeleteChat", "generic")}
				closeArgs={channelId} closeModal={removeChannel} confirmType="ensure" />
		);
	}

	function removeChannel(e, channelId) {
		const pathVariables = {
			channelId: channelId
		};
		if (channelId !== "no") {
			deleteChatChannelAPI(pathVariables, ownerId, resp => {
				const alertType = resp.includes("success") ? SUCCESS : ERROR;
				props.setAlert(
					<Alert type={alertType} customClass="tutoring"
						closeHandler={closeAlert} msg={messageMap(resp, "api")} />
				);
	
				if (alertType === SUCCESS) {
					props.reRenderChat();
				}
			});
		}
		else {
			closeModal();
		}
	}

	return (
		<Fragment>
			{tutorProfilePane}
			{spinner}

			<PubNubProvider client={props.clientCredentials}>
				<Chat currentChannel={newChannel} theme={CHAT_THEME_SUPPORT} users={props.users} >
					<div className="channel-container">
						{channelList}
					</div>

					<div className="messages-container">
						<div className="chat-header">
							<div className="name-subject-topic-header">
								<button className={`name-button ${clickableChannelName}`} onClick={showTutorPane}>
									{channelName}
								</button>
								<div className="subject-topic">
									{channelSubjectTopic}
								</div>
							</div>
							<div className="accept-decline-container">
								{
									canShowActionButton
									? (
										<Fragment>
											<button className="accept-button"
												onClick={e => showConfirmationModal(ACCEPT_DECISION)}>
												{messageMap("tutoring.request.hire", "button")}
											</button>
											<button className="decline-button"
												onClick={e => showConfirmationModal(DECLINE_DECISION)}>
												{messageMap("tutoring.request.decline", "button")}
											</button>
										</Fragment>
									): ""
								}
							</div>
						</div>

						<MessageList fetchMessages={25}>
							<TypingIndicator showAsMessage={true} />
						</MessageList>

						<MessageInput typingIndicator={true} fileUpload={"all"} />
					</div>
				</Chat>
			</PubNubProvider>
		</Fragment>
	);
}

TutoringMessages.propTypes = {
	clientCredentials: PropTypes.object.isRequired,
	channels: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	onUsers: PropTypes.array.isRequired,

	reRenderChat: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string,
	isSubscriber: PropTypes.bool.isRequired,
	setPaymentInfo: PropTypes.func.isRequired
};

export default connect(
	account,
	{setPaymentInfo}
)(TutoringMessages);

