import React, {useRef} from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { 
	TUTORING_POLICIES_TABS, TUTORS_TAB, TUTEES_TAB
} from "templates/utilities/PageTabs";
import TabbedPages from "templates/TabbedPages";
import Modal from "templates/Modal";

import TuteeSessionPolicy from "pages/Policies/TuteePolicy/components/TuteeSessionPolicy";
import TuteeCancellationPolicy from "pages/Policies/TuteePolicy/components/TuteeCancellationPolicy";
import TuteePolicyViolations from "pages/Policies/TuteePolicy/components/TuteePolicyViolations";
import TuteePolicy from "pages/Policies/TuteePolicy/TuteePolicy";

import TutorSessionPolicy from "pages/Policies/TeacherPolicy/components/TutorSessionPolicy";
import TutorCancellationPolicy from "pages/Policies/TeacherPolicy/components/TutorCancellationPolicy";
import TutorPolicyViolations from "pages/Policies/TeacherPolicy/components/TutorPolicyViolations";
import TutorPolicy from "pages/Policies/TeacherPolicy/TutorPolicy";

import messageMap, { messageMapGeneric, messageMapButton } from "Utilities/MessageMaps";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";


function TutoringPolicies(props) {

	const tuteePolicyArray = [
		{
			titleKey: "sessionPolicy",
			component: TuteeSessionPolicy
		}, {
			titleKey: "cancellationPolicy",
			component: TuteeCancellationPolicy
		}, {
			titleKey: "policyViolations",
			component: TuteePolicyViolations
		}, {
			titleKey: "tuteePolicy",
			component: TuteePolicy
		}
	];
	const tutorPolicyArray = [
		{
			titleKey: "sessionPolicy",
			component: TutorSessionPolicy
		}, {
			titleKey: "cancellationPolicy",
			component: TutorCancellationPolicy
		}, {
			titleKey: "policyViolations",
			component: TutorPolicyViolations
		}, {
			titleKey: "independentTutorPolicy",
			component: TutorPolicy
		}
	];

	const tuteeTabRef = useRef(),
		tutorTabRef = useRef();

	function showSubMenuPage(chosenMenuPage) {
		if (chosenMenuPage === TUTEES_TAB) {
			tuteeTabRef.current.className = "policies-for-users";
			tutorTabRef.current.className = "policies-for-users hide";
		}
		else if (chosenMenuPage === TUTORS_TAB) {
			tuteeTabRef.current.className = "policies-for-users hide";
			tutorTabRef.current.className = "policies-for-users";
		}
	}

	function showPolicy(key, component) {
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal}
				modalClass={`tutoring policy-modal ${key !== "independentTutorPolicy" ? "partial" : ""}`} >
				{
					React.createElement(component)
				}
			</Modal>
		);
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<div className="tutoring-policies">
			<Helmet>
				<title>{messageMap("tutoringPolicies.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringPolicies.description", "headerTag")}></meta>
			</Helmet>

			<div className="header">
				{messageMapGeneric("tutoringPage.policies.header")}
			</div>

			<TabbedPages customClass="tutoring-policies" tabType={TUTORING_POLICIES_TABS} tabClickHandler={showSubMenuPage} />
			
			<div className="policies-for-users" ref={tuteeTabRef}>
				{
					tuteePolicyArray.map(card =>
						<div key={card.titleKey} className="policy-card">
							<div className="card-title">
								{messageMapGeneric(`tutoringPage.policies.tutees.${card.titleKey}`)}
							</div>
							<div className="card-description">
								{messageMapGeneric(`tutoringPage.policies.tutees.${card.titleKey}Description`)}
							</div>
							<button className="read-more-button" onClick={e => showPolicy(card.titleKey, card.component)}>
								{messageMapButton("tutoring.readMore")}
							</button>
						</div>
					)
				}
			</div>

			<div className="policies-for-users hide" ref={tutorTabRef}>
				{
					tutorPolicyArray.map(card =>
						<div key={card.titleKey} className="policy-card">
							<div className="card-title">
								{messageMapGeneric(`tutoringPage.policies.tutors.${card.titleKey}`)}
							</div>
							<div className="card-description">
								{messageMapGeneric(`tutoringPage.policies.tutors.${card.titleKey}Description`)}
							</div>
							<button className="read-more-button" onClick={e => showPolicy(card.titleKey, card.component)}>
								{messageMapButton("tutoring.readMore")}
							</button>
						</div>
					)
				}
			</div>
		</div>
	);
}

TutoringPolicies.propTypes = {
	setModal: PropTypes.func.isRequired
};

export default TutoringPolicies;

