import React from "react";

import PropTypes from "prop-types";


function Testimonial(props) {
	return (
		<div className="testimonial-container">
			<div className="headshot-name-title-container">
				<img className="headshot" src={props.imgSrc} alt={props.imgAlt} />
				<div className="name-title-container">
					<div className="name">
						{props.name}
					</div>
					<div className="title">
						{props.title}
					</div>
				</div>
			</div>

			<div className="testimonial">
				{props.testimonial}
			</div>
		</div>
	);
}

Testimonial.propTypes = {
	imgSrc: PropTypes.string.isRequired,
	imgAlt: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	testimonial: PropTypes.string.isRequired,
};

export default Testimonial;
