import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { TABS_MAP } from "./utilities/PageTabs";


function TabbedPages(props) {

	const history = useHistory();

	const [tabDOMs, setTabDOMs] = useState();

	useEffect(() => {
		const unlisten =  history.listen((location) => {
			const subMenuItem = checkDirectSubMenuNavigation();
			generateSubMenus(subMenuItem);
		});

		const subMenuItem = checkDirectSubMenuNavigation();
		generateSubMenus(subMenuItem);

		return () => {
			unlisten();
		}
	}, []);

	function checkDirectSubMenuNavigation() {
		const urlLocation = window.location.search;
		let subMenuItem;

		if (urlLocation.includes("show")) {
			const urlParams = new URLSearchParams(urlLocation);

			if (urlParams.get("sub") != null) {
				subMenuItem = urlParams.get("sub");
			}
		}
		else if (urlLocation.includes("sub") && !urlLocation.includes("show")) {
			const urlParams = new URLSearchParams(urlLocation);
			subMenuItem = urlParams.get("sub");
		}

		return subMenuItem;
	}

	function generateSubMenus(subMenuItem) {
		const tabMap = props.customTabs ? props.customTabs : TABS_MAP[props.tabType]
		let domTabs = [];
		let btnCount = 0;

		for (const [key, value] of Object.entries(tabMap)) {
			let className = "tab-button";
			if (subMenuItem != null) {
				if (key === subMenuItem) {
					className = "tab-button current";
				}
			}
			else if (btnCount === 0) {
				className = "tab-button current";
			}

			domTabs.push(
				// 'buttongroup' is to prevent handleButtonClick() from considering tab groups in other pages
				<button key={key} domkey={key} buttongroup={props.tabType} className={className}
					onClick={e => handleButtonClick(key)}>
					{value}
				</button>
			);

			++btnCount;
		}

		setTabDOMs(domTabs);
	}

	function handleButtonClick(key) {
		// this is a very non-React way of handling it, but it's quick, dirty and shorter
		// compared to dynamically creating refs per button for a class component
		document.querySelectorAll(`button[buttongroup=${props.tabType}]`).forEach(btn => {
			if (btn.getAttribute("domkey") === key) {
				btn.className = "tab-button current";
			}
			else {
				btn.className = "tab-button";
			}
		});

		props.tabClickHandler(key);
	}

	return (
		<div className={`tabbed-pages ${props.customClass ? props.customClass : ""}`}>
			<div className="tab-buttons-container">
				{tabDOMs}
			</div>
			<hr className="hr"/>
		</div>
	);
}

TabbedPages.propTypes = {
	customClass: PropTypes.string,
	customTabs: PropTypes.object,
	preselectedTab: PropTypes.string,
	tabType: PropTypes.string,
	tabClickHandler: PropTypes.func.isRequired
};

export default TabbedPages;

