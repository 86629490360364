// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const apiWordMap = {
	accounts: {
		access: {
			earlyRelease: {
				duplicate: "Found duplicate early release access keys. Please reach out to support to get a new access key.",
				granted: "Early release access granted. Happy Learning!!!",
				notFound: "Early release access key not found.",
				used: "Early release access key already in use.",
				emailed: "Access key emailed to ",
				notEnough: "Run out of access keys."
			}
		},
		created: {
			failed: {
				email: {
					exists: "This email is already used by another account. Please use a different one."
				},
				username: {
					exists: "Username already exists. Please try another one."
				},
				usernameAndEmail: {
					exists: "Username and email already exists. Please try another one."
				}
			},
			success: "Account created! Please verify your account with the message we sent to your email: ${email}. Glad to have you in our community! 😄"
		},
		verified: {
			success: "Thank you for verifying your account.",
			failed: "Invalid token. Unverified account."
		},
		loggedIn: {
			success: "Logged in successfully",
			failed: "Wrong username or password."
		},
		update: {
			failed: `Account update failed. Please contact support@exerinstitute.com by clicking on 'Report an issue' at the bottom of this page.`,
			login: {
				username: {
					success: "Username has been set."
				},
				password: {
					success: "Password has been set."
				},
			},
			personalInfo: {
				firstName: {
					success: "First name has been set."
				},
				lastName: {
					success: "Last name has been set."
				},
				firstNameKana: {
					success: "First name kana has been set."
				},
				lastNameKana: {
					success: "Last name kana has been set."
				},
				firstNameKanji: {
					success: "First name kanji has been set."
				},
				lastNameKanji: {
					success: "Last name kanji has been set."
				},
				dob: {
					success: "Date of birth has been set."
				}
			},
			location: {
				streetAddress1: {
					success: "Street address, or PO Box, has been set."
				},
				streetAddress2: {
					success: "Apartment, suite, unit, or building has been set."
				},
				postalCode: {
					success: "Postal code has been set."
				},
				postalCodeKana: {
					success: "Postal code kana has been set."
				},
				postalCodeKanji: {
					success: "Postal code kanji has been set."
				},
				city: {
					success: "City has been set."
				},
				state: {
					success: "State has been set."
				},
				country: {
					success: "Country has been set."
				},
			},
			contact: {
				email: {
					success: "Email address has been set."
				},
				phoneNumber: {
					success: "Phone number has been set."
				},
			},
			savedTutor: {
				success: "Saved tutor.",
				limitReached: "Failed to save tutor. You reached the limit of 20 saved tutors."
			},
			unsavedTutor: {
				success: "Removed saved tutor."
			},
			tutorSessionLimit: {
				success: "Updated tutor limit per session."
			},
			// does not come from BE, but placing it here since it's used along with the strings in this object
			reload: "Reloading the page..."
		},
		recovery: {
			linkCreated: "If you have an account with us, a recovery link has been sent to your email.",
			passwordUpdated: {
				success: "Your password has been updated. Reloading page...",
				failed: `Failed to update password. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
					at the bottom of this page.`
			}
		},
		verification: {
			sent: {
				failed: `Failed to send verification token. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
					at the bottom of this page.`,
				success: "Sent verification token to: "
			}
		},
		withdrawal: {
			lessThanMinimumWithdrawal: "Requested withdrawal amount is less than minimum withdrawal amount.",
			withdrawalInProgress: `Transfer initiated. Please allow up to 6 business days for the payout to settle. 
				If this is your first payout, please allow up to 14 business days.`
		}
	},
	bookmark: {
		saved: {
			success: "Saved bookmark.",
			failed: `Failed to save bookmark. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		deleted: {
			success: "Deleted bookmark.",
			failed: `Deleted bookmark failed. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		}
	},
	feedback: {
		success: {
			general: "Thank you for your input. For every feedback you provide, you're helping us better serve you and the community.",
			bugReport: "Thank you for reporting this issue. You're helping us better serve you and the community. Our support and engineering team will resolve the issue. You'll receive an email notification once the issue is resolved.",
			clazz: "Thank you for your feedback on this class. We'll use this to improve our classes and our relationship with content creators.",
			topic: {
				missing: "We really appreciate your feedback! 😁 Our team will look into this."
			}
		},
		failed: {
			type: "Incorrect, or missing, feedback type.",
			ownerId: "Please login first.",
			classId: "Missing class ID.",
			message: "Missing feedback message.",
			invalidAccount: "Invalid account."
		},
	},
	groups: {
		create: {
			success: "Successfully created group.",
			failure: "Failed to create group."
		},
		join: {
			alreadyInGroup: "Already in group.",
			invalidGroupCode: "Invalid group code.",
			success: "Successfully joined group."
		},
		leave: {
			adminsCantLeave: "Admins can't leave the group.",
			success: "Successfully left group."
		},
		remove: {
			failAdmin: "Admins can't be removed from the group.",
			success: "Successfully removed member. Refresh page to view the latest members list."
		},
		// does not come from BE, but placing it here since it's used along with the strings in this object
		reload: "Reloading the page..."
	},
	knowledge: {
		view: {
			save: {
				success: "view saved.",
				failed: {
					missing: {
						viewName: "Missing view name.",
						workbookName: "Missing workbook name.",
						sheetName: "Missing sheet name.",
						rootName: "Missing root name",
						depth: "Missing depth.",
						zoomLevel: "Missing zoom level."
					},
					alreadyExists: "View already exists."
				}
			},
			delete: {
				success: "view deleted.",
				failed: "Failed to delete"
			}
		}
	},
	note: {
		update: {
			updated: "Note updated",
			failed: `Failed to update note. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		save: {
			saved: "Note saved",
			failed: `Failed to save note. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		delete: {
			deleted: "Note deleted",
			failed: `Failed to delete note. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		}
	},
	notifications: {
		delete: {
			deleted: "Notification deleted.",
			failed: `Failed to delete notification. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		}
	},
	paymentConfirmation: {
		complete: {
			thankYou: "Thank you for your payment!"
		},
		invalid: "Invalid transaction.",
		failure: "Encountered issue finalizing payment"
	},
	paymentMethod: {
		saved: {
			success: "Payment method saved.",
			failed: "Payment method failed."
		}
	},
	payout: {
		// not everything in this 'createdConnectAccount' object came from the BE, but I placed them here
		// to just have them all in one place
		connectAccount: {
			created: {
				failed: "Failed to create Stripe Connect account",
				failed1: "Received the following errors from Stripe:",
				failed2: `If you need assistance to solve these issues, please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`,
				success: "Created Stripe Connect account",
				accountStatus: "Verification status: ",
				additionalRequirements: "Additional account requirements:"
			},
			updated: {
				success: "Updated Stripe Connect account.",
				failed: "Failed to update Stripe Connect account."
			}
		},
		createdConnectAccount: {
			failed: "Failed to create Stripe Connect account",
			failed1: "Received the following errors from Stripe:",
			failed2: `If you need assistance to solve these issues, please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`,
			success: "Created Stripe Connect account",
			accountStatus: "Verification status: ",
			additionalRequirements: "Additional account requirements:",
			updated: "Updated Stripe Connect account.",
		}
	},
	practice: {
		expIncrease: {
			success: "Experience increased.",
			failed: `Failed to increase experience. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		levelIncrease: {
			success: "Level increased.",
			failed: `Failed to increase level. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		progress: {
			success: "Progress saved.",
			failed: `Failed to save progress. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		inventory: {
			success: "Inventory updated.",
			failed: {
				text: `Failed to update inventory. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
					at the bottom of this page.`,
				invalidItem: "Invalid inventory item."
			}
		}
	},
	promotion: {
		earlyAccess: "Awesome! We'll email you as soon as we go live."
	},
	promo: {
		educator: {
			incentives: {
				comm: `Automatically opted into limited time bonus for commissions. Please email us the video's title
							you uploaded, including your username and email, to support@exerinstitute.com to receive these
							bonuses. Once confirmed, your video will receive either the 80%, or 90%, bonus, depending on your
							video's quality for a whole year. You can upload multiple videos and all of them could qualify
							for the bonus. The year countdown begins once the commissions system is deployed in February.`
			}
		}
	},
	subscription: {
		started: {
			success: "Subscription successfully created.",
			failed: "Failed to create a subscription, please contact support@exerinstitute.com by clicking on 'Report an issue' at the bottom of this page."
		},
		canceled: {
			success: "Successfully canceled subscription. Reloading the page...",
			failed: "Failed to cancel subscription. Please contact support@exerinstitute.com by clicking on 'Report an issue' at the bottom of this page."
		},
		contactSales: {
			success: "Thank you for reaching out! Our sales team has received your request, and you will receive a confirmation email shortly. We look forward to working with you.",
			failure: `Submission failed. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		}
	},
	upload: {
		success: {
			// video
			video: "Video uploaded.",
			thumbnail: "Thumbnail uploaded.",
			mentalModel: "Mental model(s) uploaded.",
			subtitle: "Subtitle uploaded.",
			transcoding: "Video is now being encoded for adaptive streaming; once finished, the video will be available to the public.",
			// tutor
			profilePicture: "Profile picture uploaded.",
			screeningResponse: "Screening question response uploaded.",
			resume: "Resume uploaded.",
			teachingCertification: "Teaching certifications uploaded.",
			professionalExperience: "Proof of professional experience uploaded.",
			educationProof: "Proof of education uploaded.",
		},
		failed: {
			video: {
				duplicateName: "Upload failed. Found duplicate video title."
			}
		},
		missing: {
			fields: "Please correct the following fields: ",
			videoDescription: "Couldn't find video description.",
			videoFile: "Couldn't find video file.",
			videoTitle: "Couldn't find video title",
			thumbnail: "Couldn't find thumbnail.",
			thumbnailDescription: "Couldn't find thumbnail description.",
			mentalModel: "Couldn't find mental model.",
			subtitle: "Couldn't find subtitle."
		}
	},
	tutor: {
		application: {
			accepted: {
				success: {
					text: "Accepted tutor application. The session will be scheduled to go live on: ",
					text1: "Accepted tutor application. The session will be scheduled now. ",
					scheduleNextSteps: `Scheduled sessions can be viewed in 'Tutor sessions' under the 'Upcoming session' 
						tab. We will send an email if the other party decides to cancel the session. Please note that not 
						appearing in the first 5 minutes of the session would count as a no-show.`,
					acceptedBySavedRequestTutor: `Failed to accept tutor application. Session request was already 
						accepted by either a saved tutor or from a direct booking request. If this is not the case, please
						contact support@exerinstitute.com by clicking on 'Report an issue' at the bottom of this page.`
				},
				failed: `Failed to accept tutor application. Please contact support@exerinstitute.com by clicking on 'Report 
					an issue' at the bottom of this page.`
			},
			declined: {
				success: "Declined tutor application.",
				failed: `Failed to decline tutor application. Please contact support@exerinstitute.com by clicking on 'Report 
					an issue' at the bottom of this page.`
			},
			failed: `Failed to decide on tutor application. Please contact support@exerinstitute.com by clicking on 'Report 
				an issue' at the bottom of this page.`
		},
		profile: {
			save: {
				success: "Successfully saved profile.",
				isPublic: "Congratulations! You've completed all the required fields. Your profile is now available to the public.",
				failed: `Failed to save profile. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
					at the bottom of this page.`,
				linkedInUrl: "LinkedIn url saved.",
				websiteUrl: "Website url saved.",
				subjectTopics: "Subject and topics saved.",
				occupation: "Occupation saved.",
				bio: "Bio saved.",
				notificationPreference: "Notification preference saved.",
				trialPreference: "Trial preference saved.",
				fluentLanguages: "Fluent languages saved.",
				teachingRates: "Teaching rates saved.",
				cancellationPolicies: "Cancellation policies saved.",
				schedules: "Schedule saved.",
				acceptedAgreements: "Acceptance of agreements saved.",
				noChanges: "No profile changes."
			},
			visibility: {
				hid: "Your tutor profile is hidden from the public.",
				shown: "Your tutor profile is now available to the public.",
				failed: `Failed to update tutor profile visibility. Please contact support@exerinstitute.com by clicking on 
					'Report an issue' at the bottom of this page.`
			},
			interview: {
				can: "You can now schedule an interview.",
				cant: "Please fill all required fields to schedule an interview."
			}
		},
		requests: {
			success: `Your request is now visible to tutors. Once a tutor applies to your request, you will receive an email 
				notification and you can review their application in the 'Tutor applications' sub page.`,
			directSuccess: `Your booking request was sent to the tutor. You will receive an email notification regarding the 
				tutor's decision. If the tutor accepts your request, a new tutor application would appear for you to hire the 
				tutor. If the tutor rejects your request, it'll automatically get deleted.`,
			bookingSuccess: "Booking request sent.",
			failed: `Failed to create a tutoring request. Please email support at support@exerinstitute.com by clicking 
				on 'Report an issue' at the bottom of this page.`,
			edited: {
				subjectTopic: "Updated the subject and topic.",
				topic: "Updated the topic.",
				sessionTime: "Updated the session's time.",
				duration: "Updated the session's duration.",
				receiver: "Updated the group of tutors receiving the request.",
				description: "Updated the request description.",
				failed: `Edit failed. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
					at the bottom of this page.`,
				noDifference: "Found no difference.",
				pageReload: "Reloading the page..."
			},
			deleted: {
				success: "Successfully deleted the request.",
				failed: `Failed to delete the request. Please contact support@exerinstitute.com by clicking on 
					'Report an issue' at the bottom of this page.`
			},
			appliedTo: {
				success: `Successfully applied to the session request. You can now speak to the tutee in the 'Messages' page 
					and track their decision in the 'Tutor applications' page. If a tutee accepts your application, the request 
					will appear in the 'Tutor session' page under the 'Upcoming session' tab. If a tutee declines your application, 
					the request would not be visible to you in the 'Tutor requests' and 'Tutor applications' pages, and you would 
					no longer be able to message them.`,
				failed: {
					text: `Failed to apply to the session request. Please contact support@exerinstitute.com by clicking 
						on 'Report an issue' at the bottom of this page.`,
					notOpen: "Failed to apply to the session request. Session is no longer available to the public. Please refresh the list to find available sessions to apply to."
				}
			},
			receivedRequest: {
				accepted: {
					success: "Accepted session request. Once the tutee pays for the session, the session will be scheduled in the 'Tutor session' page under the 'Upcoming sessions' tab.",
					scheduleNextSteps: "Scheduled sessions can be viewed in 'Tutor sessions' under the 'Upcoming session' tab. We will send an email if the other party decides to cancel the session.",
					failed: `Failed to accept session request. Please contact support@exerinstitute.com by clicking on 
						'Report an issue' at the bottom of this page.`
				},
				declined: {
					success: "Declined session request.",
					failed: `Failed to decline session request. Please contact support@exerinstitute.com by clicking 
						on 'Report an issue' at the bottom of this page.`
				},
				failed: `Failed to decide on session request. Please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`
			},
			canceled: {
				success: "Canceled session request. Sending email notification to both parties...",
				failed: `Failed to cancel session request. Please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`
			}
		},
		sessions: {
			left: {
				success: "Successfully left the session.",
				successAutomatic: "Passed the session duration. Leaving the session.",
				failed: `Failed to leave the session. Please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`,
				failedEarly: "Failed to leave the session. You cannot leave the session 5 minutes early."
			},
			report: {
				success: "Thank you for filing this report. Our team will investigate this further and we'll provide updates regarding the resolution via your email.",
				failed: `Failed to file the report. Please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`
			},
			reviewed: {
				success: "Thank you for posting a review. Your review helps other students understand the tutor's capacity to teach and it helps the tutor become a better teacher.",
				failed: `Failed to file to post a review. Please contact support@exerinstitute.com by clicking 
					on 'Report an issue' at the bottom of this page.`
			}
		},
		chat: {
			deletedChannel: {
				success: "Successfully deleted chat.",
				failed: `Failed to delete chat please email support@exerinstitute.com regarding the issue by clicking 
					on 'Report an issue' at the bottom of this page.`
			}
		}
	},
	scheduling: {
		calendly: {
			emailNotification: {
				success: `Thanks for scheduling the interview. If you need to reschedule or cancel, please email 
					support@exerinstitute.com. You should have also received an email confirmation of the meeting.`,
				failed: `Failed to schedule interview. Please try again later, or send an email to 
					support@exerinstitute.com by clicking on 'Report an issue' at the bottom of this page.`
			}
		}
	},
	pubnub: {
		keyset: {
			failed: `Failed to connect to PubNub. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		}
	},
	module: {
		success: {
			created: "Module created.",
			edited: "Updated module",
			deleted: "Module deleted."
		},
		failed: {
			invalidIds: "Module members need to be videos.",
			edit: `Failed to edit module. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`,
			delete: `Failed to delete module. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		missing: {
			name: "Please provide a module name.",
			description: "Please provide a module description",
			videoIds: "Please provide at least 2 module members."
		}
	},
	edit: {
		success: {
			videoTitle: "Video title edited.",
			video: "Modified video.",
			thumbnail: "Modified thumbnail.",
			thumbnailDescription: "Thumbnail description edited.",
			subtitle: "Modified subtitles.",
			mentalModel: "Modified mental model(s).",
			price: "Modified price.",
			videoDescription: "Video description edited.",
			videoTopic: "Video topic edited."
		}
	},
	delete: {
		success: "Video, thumbnail, subtitles, mental model(s), and description deleted."
	},
	purchases: {
		transaction: {
			invalid: {
				cost: "Invalid cost.",
				transactionType: "Invalid transaction type."
			},
			cost: {
				insufficientShinyNeurons: "Not enough shiny neurons to purchase.",
			},
			missing: {
				shinyNeurons: "No shiny neurons were used.",
				payor: "Your identity could not be found.",
				payee: "The content creator's identity could not be found.",
				transactionCurrency: "The content creator did not specify a currency.",
				transactionType: "Transaction details are missing.",
				transactedItemId: "The video could not be found."
			}
		},
		hints: {
			success: "Hint obtained",
			failed: `Failed to obtain hint. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		answers: {
			success: "Answer obtained",
			failed: `Failed to obtain answer. Please contact support@exerinstitute.com. by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		levels: {
			success: "Level increased",
			failed: `Failed to obtain level. Please contact support@exerinstitute.com by clicking on 'Report an issue' 
				at the bottom of this page.`
		},
		videos: {
			failed: {
				unnecessaryPurchase: "Invalid purchase of owned video."
			},
			success: "Congrats, your video purchase was successful. You are now being redirected to your video."
		}
	}
};