import React, {Fragment} from "react";

import { messageMapGeneric, messageMapImage } from "Utilities/MessageMaps";

import penOnPaperAsset from "assets/images/marketing/penOnPaper.svg";
import ribbonAsset from "assets/images/marketing/ribbon.svg";
import togaHatAsset from "assets/images/marketing/togaHat.svg";
import securityAsset from "assets/images/marketing/security.svg";
import greenCheckAsset from "assets/icons/common/green-check.svg";
import tuteesVideoAsset from "assets/videos/tuteeExplainer.mp4";


function ExerForTutees(props) {
	const iconWithDetails = [
		{
			key: "penOnPaperAsset",
			icon: penOnPaperAsset,
			alt: messageMapImage("tutoring.whyExer.penOnPaper"),
			text: messageMapGeneric("tutoringPage.whyExer.qualityTutors.screening")
		}, {
			key: "ribbonAsset",
			icon: ribbonAsset,
			alt: messageMapImage("tutoring.whyExer.ribbon"),
			text: messageMapGeneric("tutoringPage.whyExer.qualityTutors.pedigree")
		}, {
			key: "togaHatAsset",
			icon: togaHatAsset,
			alt: messageMapImage("tutoring.whyExer.togaHat"),
			text: messageMapGeneric("tutoringPage.whyExer.qualityTutors.degree")
		}, {
			key: "securityAsset",
			icon: securityAsset,
			alt: messageMapImage("tutoring.whyExer.security"),
			text: messageMapGeneric("tutoringPage.whyExer.qualityTutors.backgroundCheck")
		}
	];

	return (
		<Fragment>
			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.tutoringExperience")}
			</div>
			<div className="video-container">
				<video className="video" controls controlsList="nodownload" disablePictureInPicture>
					<source src={tuteesVideoAsset} type="video/mp4"/>
					<track default kind="captions" srcLang="en"/>
				</video>
			</div>

			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.qualityTutors.text")}
			</div>
			<div className="details-container">
				{
					iconWithDetails.map(iconDetails => 
						<div key={iconDetails.key}>
							<img src={iconDetails.icon} alt={iconDetails.alt} className="detail-image"/>
							<div className="details">
								{iconDetails.text}
							</div>
						</div>
					)
				}
			</div>

			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.studentBenefits.text")}
			</div>
			<div className="benefits-improving-container">
				{
					["grades", "progress", "acceleratedLearning", "assistance", "highDosage"].map(rowText => 
						<div key={rowText} className="benefits-improving">
							<img className="green-check" src={greenCheckAsset} alt={messageMapImage("tutoring.greenCheck")} />
							{messageMapGeneric(`tutoringPage.whyExer.studentBenefits.${rowText}`)}
						</div>
					)
				}
			</div>

			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.wereBetter.text")}
			</div>
			<div className="benefits-improving-container">
				{
					["collaboration", "analytics"].map(rowText => 
						<div key={rowText} className="benefits-improving">
							{messageMapGeneric(`tutoringPage.whyExer.wereBetter.${rowText}`)}
						</div>
					)
				}
			</div>
		</Fragment>
	);
}

export default ExerForTutees;

