import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import messageMap, {messageMapDocument} from "Utilities/MessageMaps";
import TutorSessionPolicy from "./components/TutorSessionPolicy";
import TutorCancellationPolicy from "./components/TutorCancellationPolicy";
import TutorPolicyViolations from "./components/TutorPolicyViolations";


function TutorPolicy(props) {

	const classStr = props.contentType === "modal" ? "modal-container-tutor-tutee-policy" : "agreement-container";

	return (
		<div className="tutor-tutee-policy">
			<Helmet>
				<title>{messageMap("tutorPolicy.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutorPolicy.description", "headerTag")}></meta>
			</Helmet>

			<div className={classStr}>
				<h1 className="title">
					{messageMapDocument("teacherPolicy.title")}
				</h1>
				<div className="agreement">
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.header.date")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.header.toTeach1")}
						<span>
							{messageMapDocument("teacherPolicy.header.toTeach2")}
						</span>
						{messageMapDocument("teacherPolicy.header.toTeach3")}
						<a href="https://exerinstitute.com/terms-and-conditions">https://exerinstitute.com/terms-and-conditions</a>
						{messageMapDocument("teacherPolicy.header.toTeach5")}
						<span>
							{messageMapDocument("teacherPolicy.header.toTeach6")}
						</span>
						{messageMapDocument("teacherPolicy.header.toTeach7")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.header.unauthorizedUse")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.header.policyGoal")}
					</p>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section1.title")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section1.point1.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section1.point1.point1.text1")}
								<span>
									{messageMapDocument("teacherPolicy.section1.point1.point1.text2")}
								</span>
								{messageMapDocument("teacherPolicy.section1.point1.point1.text3")}
								<span>
									{messageMapDocument("teacherPolicy.section1.point1.point1.text4")}
								</span>
								{messageMapDocument("teacherPolicy.section1.point1.point1.text5")}
								<span>
									{messageMapDocument("teacherPolicy.section1.point1.point1.text6")}
								</span>
								{messageMapDocument("teacherPolicy.section1.point1.point1.text7")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section1.point1.point2.text")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section1.point2.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section1.point2.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section1.point2.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section1.point2.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section1.point2.point4.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section1.point2.point5.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section1.point2.point6.text")}
									</p>
								</li>
							</ul>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section2.title")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section2.point1.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point1.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point1.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point1.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point1.point4.text")}
									</p>
								</li>
							</ul>
						</div>

						<TutorSessionPolicy />

						<TutorCancellationPolicy />

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section2.point4.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point4.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point4.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point4.point3.text")}
									</p>
								</li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point4.point4.text")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point4.point5.text")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point4.point6.text")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section2.point5.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section2.point5.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point5.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point5.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point5.point4.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point5.point5.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section2.point5.point6.text")}
									</p>
								</li>
							</ul>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section3.title")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section3.point1.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section3.point1.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section3.point1.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section3.point1.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("teacherPolicy.section3.point1.point4.text")}
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section3.point2.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section3.point2.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("teacherPolicy.section3.point2.point2.text1")}
										</span>
										{messageMapDocument("teacherPolicy.section3.point2.point2.text2")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("teacherPolicy.section3.point2.point3.text1")}
										</span>
										{messageMapDocument("teacherPolicy.section3.point2.point3.text2")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("teacherPolicy.section3.point2.point4.text1")}
										</span>
										{messageMapDocument("teacherPolicy.section3.point2.point4.text2")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("teacherPolicy.section3.point2.point5.text1")}
										</span>
										{messageMapDocument("teacherPolicy.section3.point2.point5.text2")}
									</p>
								</li>
							</ul>
						</div>
					</div>

					<TutorPolicyViolations />

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section5.title")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMapDocument("teacherPolicy.section5.point1.text")}
						</p>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section5.point2.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section5.point2.point1.text")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section5.point3.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section5.point3.point1.text")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("teacherPolicy.section5.point4.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("teacherPolicy.section5.point4.point1.text")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section6.title")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMapDocument("teacherPolicy.section6.point1.text")}
						</p>
						<ul>
							<li>
								<p className="margin-bottom-spacing">
									{messageMapDocument("teacherPolicy.section6.point2.text")}
								</p>
							</li>
							<li>
								<p className="margin-bottom-spacing">
									{messageMapDocument("teacherPolicy.section6.point3.text")}
								</p>
							</li>
							<li>
								<p className="margin-bottom-spacing">
									{messageMapDocument("teacherPolicy.section6.point4.text")}
								</p>
							</li>
							<li>
								<p className="margin-bottom-spacing">
									{messageMapDocument("teacherPolicy.section6.point5.text")}
								</p>
							</li>
							<li>
								<p className="margin-bottom-spacing">
									{messageMapDocument("teacherPolicy.section6.point6.text")}
								</p>
							</li>
						</ul>
						<p className="margin-bottom-spacing">
							{messageMapDocument("teacherPolicy.section6.point7.text")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("teacherPolicy.section7.title")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMapDocument("teacherPolicy.section7.point1.text")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMapDocument("teacherPolicy.section7.point2.text")}
						</p>
					</div>

				</div>
			</div>
		</div>
	);
}

TutorPolicy.propTypes = {
	contentType: PropTypes.string
};

export default TutorPolicy;


