import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import Tooltip from "templates/Tooltip";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";


function FifteenMinuteTrials(props) {

	const [trialTooltip, setTrialTooltip] = useState();

	const yesTrialRef = useRef(),
	noTrialRef = useRef();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.trialPreference && props.trialPreference.length) {
			if (props.trialPreference === "true") {
				noTrialRef.current.checked = false;
				yesTrialRef.current.checked = true;
			}
			else if (props.trialPreference === "false") {
				yesTrialRef.current.checked = false;
				noTrialRef.current.checked = true;
			}
		}
	}

	function setTrialPreferenceChoice(trialPreference) {
		if (trialPreference === "yes") {
			noTrialRef.current.checked = false;
			yesTrialRef.current.checked = true;
			props.setTrialPreference(true);
		}
		else if (trialPreference === "no") {
			yesTrialRef.current.checked = false;
			noTrialRef.current.checked = true;
			props.setTrialPreference(false);
		}
	}

	function toggleTrialTooltip(showOrHide) {
		if (showOrHide === "show") {
			setTrialTooltip(
				<Tooltip classStr="tooltip-bottom-left fifteen-minute-trial-tooltip" subheader={messageMap("tutor.fifteenMinuteTrial", "tooltip")} />
			);
		}
		else {
			setTrialTooltip(null);
		}
	}

	return (
		<div className="page-field">
			<label className="page-field-label">
				<div className="div-label">
					{messageMap("profilePage.tutoring.modal.application.fifteenMinuteTrials", "generic")}
				</div>
				*
				<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
					onMouseOver={e => toggleTrialTooltip("show")} onFocus={e => toggleTrialTooltip("show")}
					onMouseLeave={e => toggleTrialTooltip("hide")} onBlur={e => toggleTrialTooltip("hide")} ></img>
				{trialTooltip}
			</label>
			<div className="inline-elements">
				<div className="radio-checkbox-container">
					<input id="yesTrial"className="page-field-value radio-input" type="radio"
						onClick={e => setTrialPreferenceChoice("yes")}
						onKeyDown={e => onKeyDown(e, setTrialPreferenceChoice, ["yes"], true)}
						ref={yesTrialRef}/>
					<label htmlFor="yesTrial" className="page-field-label trial-radio">
						{messageMap("tutoring.profile.yes", "labelPlaceholder")}
					</label>
				</div>

				<div className="radio-checkbox-container">
					<input id="noTrial" className="page-field-value radio-input" type="radio" 
						onClick={e => setTrialPreferenceChoice("no")}
						onKeyDown={e => onKeyDown(e, setTrialPreferenceChoice, ["no"], true)}
						ref={noTrialRef}/>
					<label htmlFor="noTrial" className="page-field-label trial-radio">
						{messageMap("tutoring.profile.no", "labelPlaceholder")}
					</label>
				</div>
			</div>
		</div>
	);
}

FifteenMinuteTrials.propTypes = {
	trialPreference: PropTypes.string,
	setTrialPreference: PropTypes.func.isRequired
};

export default FifteenMinuteTrials;

