import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// common
import NavBar from "templates/NavBar";
import Footer from "templates/Footer";

import LandingPage from "pages/Home/LandingPage";
import Home from "pages/Home/Home";
import About from "pages/About/About";
import Profile from "pages/Profile/Profile";

// socials
import Groups from "pages/Groups/Groups";

// header
import FullAccount from "pages/CreateFullAccount/CreateFullAccount";
import KnowledgeTree from "pages/KnowledgeTree/KnowledgeTree";
import Topic from "pages/KnowledgeTree/Topic/Topic";
import TopicsInfoList from "pages/Classes/TopicsInfoList/TopicsInfoList";
import TopicInfo from "pages/Classes/TopicsInfoList/TopicInfo/TopicInfo";
import Tutoring from "pages/Tutoring/Tutoring";
import Practice from "pages/Classes/Practice/Practice";

// videos
import UploadVideo from "pages/Classes/Videos/UploadVideo";
import VideoList from "pages/Classes/Videos/VideoList";
import EditVideosList from "pages/Classes/Videos/EditVideosList";

// class
import ClassList from "pages/Classes/ClassList";
import ClassOverview from "pages/Classes/Overview/ClassOverview";
import CreateClass from "pages/Classes/Create/CreateClass";
import Learn from "pages/Classes/Learn/Learn";
import ClassRequest from "pages/ClassRequest/ClassRequest";

// Payments, payouts & transaction pages
import Quotes from "pages/Quotes/Quotes";
import Checkout from "pages/Checkout/Checkout";
import PaymentMethod from "pages/Payments/PaymentMethod/PaymentMethod";
import PaymentMethodSavedConfirmation from "pages/Payments/PaymentMethod/PaymentMethodSavedConfirmation/PaymentMethodSavedConfirmation";
import PaymentConfirmation from "pages/Payments/PaymentConfirmation/PaymentConfirmation";
import Subscription from "pages/Subscription/Subscription";
import StripeConnectAccount from "pages/Payouts/StripeConnectAccount";

// footer
import Careers from "pages/Careers/Careers";
import ContactUs from "pages/ContactUs/ContactUs";
import Feedback from "pages/Feedback/Feedback";
import HelpCenter from "pages/HelpCenter/HelpCenter";
import TermsNConditions from "pages/Policies/TermsNConditions/TermsNConditions";
import PrivacyPolicy from "pages/Policies/PrivacyPolicy/PrivacyPolicy";
import TutorPolicy from "pages/Policies/TeacherPolicy/TutorPolicy";
import TuteePolicy from "pages/Policies/TuteePolicy/TuteePolicy";

import FourOFour from "pages/FourOFour/FourOFour";
import Maintenance from "pages/Maintenance/Maintenance";

export default function HomeRoutes() {
	return (
		<BrowserRouter>
			<NavBar />

			<Switch>
				{/* ROUTES */}
				<Route exact strict path="/">
					<LandingPage />
				</Route>

				<Route exact strict path="/practice-category">
					<Topic />
				</Route>
				<Route path="/exercise*" render={(props) => <Practice routerProp={props} />}>
				</Route>

				<Route exact strict path="/topics-info-list">
					<TopicsInfoList />
				</Route>
				<Route path="/topic-info*" render={(props) => <TopicInfo routerProp={props.location.state} />}>
				</Route>

				<Route exact strict path="/tutoring*" render={(props) => <Tutoring routerProp={props} />}>
				</Route>

				<Route exact strict path="/knowledge-tree" render={(props) => <KnowledgeTree routerProp={props.location.state}/>}>
				</Route>

				{/* header */}
				<Route exact strict path="/create-full-account">
					<FullAccount />
				</Route>

				<Route exact strict path="/profile*">
					<Profile />
				</Route>

				{/* classes */}
				{/* need to eventually create class route nesting */}
				<Route exact strict path="/class-list">
					<ClassList />
				</Route>
				<Route path="/class-overview/:class" render={(props) => <ClassOverview routerProp={props} />}>
				</Route>
				<Route exact strict path="/create-class">
					<CreateClass />
				</Route>
				<Route path="/learn*" render={(props) => <Learn routerProp={props} />}>
				</Route>
				<Route exact strict path="/class-request">
					<ClassRequest />
				</Route>
				{/* videos */}
				<Route exact strict path="/upload-video">
					<UploadVideo />
				</Route>
				<Route exact strict path="/edit-videos">
					<EditVideosList />
				</Route>
				<Route exact strict path="/video-list">
					<VideoList />
				</Route>

				<Route exact strict path="/groups">
					<Groups />
				</Route>

				{/* Payments & transaction pages */}
				<Route exact strict path="/quotes">
					<Quotes />
				</Route>
				<Route exact strict path="/checkout" render={(props) => <Checkout routerProp={props} />} />
				<Route exact strict path="/payment-method">
					<PaymentMethod />
				</Route>
				<Route path="/payment-saved-confirmation*" render={(props) => <PaymentMethodSavedConfirmation routerProp={props} />}>
				</Route>
				<Route path="/payment-confirmation*" render={(props) => <PaymentConfirmation routerProp={props} />}>
				</Route>
				<Route exact strict path="/subscription">
					<Subscription />
				</Route>
				<Route path="/stripe-connect*" render={(props) => <StripeConnectAccount routerProp={props} />} >
				</Route>

				{/* top */}
				<Route exact strict path="/about-us">
					<Home />
					<About />
				</Route>
				{/* footer */}
				<Route exact strict path="/careers">
					<Careers />
				</Route>
				<Route exact strict path="/contact-us">
					<ContactUs />
				</Route>
				<Route exact strict path="/feedback">
					<Feedback />
				</Route>
				<Route exact strict path="/help-center">
					<HelpCenter />
				</Route>
				{/* bottom */}
				<Route exact strict path="/terms-and-conditions">
					<TermsNConditions />
				</Route>
				<Route exact strict path="/privacy-policy">
					<PrivacyPolicy />
				</Route>
				<Route exact strict path="/tutor-policy">
					<TutorPolicy />
				</Route>
				<Route exact strict path="/tutee-policy">
					<TuteePolicy />
				</Route>

				{/* Maintenance & 404 */}
				<Route path="/maintenance">
					<Maintenance />
				</Route>
				<Route path="*">
					<FourOFour />
				</Route>
			</Switch>

			<Footer />
		</BrowserRouter>
	)
}

HomeRoutes.propTypes = {
	location: PropTypes.object
}
