import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import { connect } from "react-redux";
import {
	updateShinyNeuronsBalance, clearPaymentInfo, setIsSubscriber
} from "redux/actions/actionTypes";
import paymentsSelector from 'redux/selectors/paymentsSelector';

import Spinner from "templates/Spinner";

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { AUTO_REDIRECT_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import { finalizePaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";
import { saveUserChargeablePaymentMethodAPI } from "apis/controllers/person/PersonPaymentInfoController";


function PaymentConfirmation(props) {
	// Constants that need to be loaded first.
	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const validIdentity = isValidIdentity(ownerId);
	// Hooks + Query Params
	const history = useHistory();

	const urlParams = new URLSearchParams(window.location.search);
	const clientSecret = urlParams.get("payment_intent_client_secret");

	// State
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (validIdentity) {
			finalizePaymentIntent();

			if (urlParams.get("method") != null) {
				saveChargeablePaymentMethod();
			}
		}
	}, [history]);

	function finalizePaymentIntent() {
		const paymentPurpose = localStorage.getItem("paymentPurpose");
		const payload = {
			ownerId: ownerId,
			clientSecret: clientSecret,
			paymentIntentId: urlParams.get("payment_intent"),
			paymentAmount: localStorage.getItem("price"),
			paymentPurpose: paymentPurpose,
			paymentDate: Date.now(),
			// rest of fields are for subscriptions
			subscriptionPlan: localStorage.getItem("subscriptionPlan"),
			subscriptionId: localStorage.getItem("subscriptionId"),
			productId: localStorage.getItem("subscriptionId"),
			productPriceId: localStorage.getItem("productPriceId")
		};
		finalizePaymentIntentAPI(payload, resp => {
			if (paymentPurpose === "shinyNeurons") {
				props.updateShinyNeuronsBalance(resp);
			}
			else if (paymentPurpose === "subscription") {
				props.setIsSubscriber(true);
			}

			setTimeout(() => {
				setIsLoading(false);
				props.clearPaymentInfo();

				if (["shinyNeurons", "subscription"].includes(paymentPurpose)) {
					history.push("/");
				}
				else {
					const redirectionParts = urlParams.get("confirmationRedirection").split("_");
					const subPageParts = redirectionParts[1].split(":");
					history.push(`/${redirectionParts[0]}?${subPageParts[0]}=${subPageParts[1]}&tutorId=${localStorage.getItem("tutorId")}&sessionId=${localStorage.getItem("sessionId")}`);
				}
			}, AUTO_REDIRECT_TIMEOUT * 1.25);
		});
	}

	function saveChargeablePaymentMethod() {
		const pathVariables = {
			method: urlParams.get("method")
		};
		const payload = {
			ownerId: ownerId,
			paymentIntentId: urlParams.get("payment_intent")
		};
		saveUserChargeablePaymentMethodAPI(pathVariables, payload, resp => {
			// not displaying anything, since this component will only be used if the saving of the payment method was successful
		});
	}

	return (
		<div className="payment-confirm-container">
			<Helmet>
				<title>{messageMap("paymentConfirmation.title", "headerTag")}</title>
				<meta name="description" content={messageMap("paymentConfirmation.description", "headerTag")}></meta>
			</Helmet>

			<div className="redirect-message">
				{
					urlParams.get("confirmationRedirection") === "home" ?
					(
						/* emoji is added directly, because it gets lost if placed in message maps */
						`${messageMap("payments.confirmation.checkoutThankYou", "generic")}😄`
					)
					: urlParams.get("confirmationRedirection").includes("tutoring") ? (
						`${messageMap("payments.confirmation.tutoringPaymentThankYou", "generic")}😄`
					) : (
						`${messageMap("payments.confirmation.subscriptionThankYou1", "generic")}💪🚀 ${messageMap("payments.confirmation.subscriptionThankYou2", "generic")}`
					)
				}
			</div>

			{
				isLoading && <Spinner/>
			}
		</div>
	);
}

PaymentConfirmation.propTypes = {
	// Redux selector
	// from account
	ownerId: PropTypes.string,
	shinyNeurons: PropTypes.string,
	// from payment
	chargeId: PropTypes.string,
	paymentPurpose: PropTypes.string,
	tutorId: PropTypes.string,
	sessionId: PropTypes.string,
	subscriptionId: PropTypes.string,
	subscriptionPlan: PropTypes.string,
	productId: PropTypes.string,
	productPriceId: PropTypes.string,
	// Redux reducer
	updateShinyNeuronsBalance: PropTypes.func.isRequired,
	clearPaymentInfo: PropTypes.func.isRequired,
	setIsSubscriber: PropTypes.func.isRequired
};

export default connect(
	paymentsSelector,
	{ 
		updateShinyNeuronsBalance, clearPaymentInfo, setIsSubscriber
	}
)(PaymentConfirmation);