import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";


function HeadlineCTA(props) {
	return (
		<div className="headline-container">
			<div className="headline">
				{props.headline}
			</div>

			{
				props.ctaRedirect
				? (
					<Link to={props.ctaRedirect} className="cta" onClick={defaultLinkAction} >
						{props.ctaText}
					</Link>
				)
				: (
					<button className="cta" onClick={props.ctaHandler} >
						{props.ctaText}
					</button>
				)
			}
		</div>
	);
}

HeadlineCTA.propTypes = {
	headline: PropTypes.string.isRequired,
	ctaText: PropTypes.string.isRequired,
	ctaRedirect: PropTypes.string,
	ctaHandler: PropTypes.func
};

export default HeadlineCTA;
