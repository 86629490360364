export const tooltipMessageMap = {
	editVideos: {
		edit: "Edit",
		delete: "Delete Permanently",
		module: "Bundle into a module",
		class: "Bundle into a class"
	},
	uploadVideos: {
		mentalModel: "A mental model (a picture of how your video's concepts tie together) helps viewers accelerate their learning by as much as 50%.",
		topic: `Assign a topic to your video to dynamically associate it with 
						practice problems, informational text, and a node in our knowledge quests
						in the future.`,
	},
	account: {
		address: "Please only fill relevant fields.",
		country: "This cannot be changed later on."
	},
	financials: {
		stripeConnect: "Create Stripe Connect account to receive payouts.",
		stripeLinking: "Please create a Stripe Connect account to withdraw your earnings.",
		unverifiedStripeConnect: "Your Stripe Connect account is currently unverified",
		belowMinimumWithdrawal: "Your earnings must be above the following to withdraw: ",
		earnings: `These are your video earnings. Tutoring earnings are transferred directly to 
			you by Stripe. Please allow up to 6 business days for the payout to settle. If this is 
			your first payout, please allow up to 14 business days.`
	},
	tutor: {
		finishTutorProfile: "Please finish creating your tutor profile before applying.",
		interviewCountLImit: "You can only have 1 interview request at a time.",
		missingCalendlyToken: "Unable to fetch calendly token. Please report incident to support@exerinstitute.com.",
		noSavedTutors: "No saved tutors found. Please save tutors from your past tutoring sessions.",
		hasNowConflict: "There is already a tutoring request scheduled for 'now'. Please delete or cancel this before requesting another request.",
		occupation: "Use your highest title that is relevant to the subject(s) you'll be teaching",
		stripeConnectPayments: "This needs to be finished to receive payments from clients.",
		fifteenMinuteTrial: "Allowing 15-minute trials automatically deducts 15 minutes from the total payment for first-time clients.",
		schedule: "Your availability in your timezone.",
		blockedRateEditing: "Please finish all your scheduled and live tutoring sessions before editing your rates."
	},
	stripe: {
		connect: {
			businessUrl: "The business's publicly available website, mobile application, or social media profile.",
			banking: {
				account: "The account number should have the following general format: ",
				routing: "The routing number should have the following general format: ",
			},
			documents: {
				d0: "We only allow the following file formats: .png, .jpg, .jpeg",
				d1: "Photos and scans of documents and IDs must be in color and in good quality",
				d2: "Identity and legal documents must not be expired",
				d3: "Documents must not be cropped and all borders must be visible",
				d4: "Screenshots are not acceptable",
				d5: "If the country of residence differs from the country of the account, a passport is required"
			}
		}
	}
};