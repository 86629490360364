import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import account from "redux/selectors/accountSelector";
import {clearNotificationsAction, unclearNotificationAction} from "redux/actions/actionTypes";

import {markSeenPromotion} from "apis/controllers/customer/PromotionsController";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import starAsset from "assets/icons/notification/star.svg";


const PROMO_CODE_TO_MSG = {
	"promo.educator.incentives.comm": messageMap("promo.educator.incentives.adsCommSubMsg", "notification")
};

const PROMO_CODE_TO_FULL_MSG = {
	"promo.educator.incentives.comm": messageMap("promo.educator.incentives.adsCommSubMsgFull", "notification")
}

const CODE_TO_POP_UP_CTA = {
	"promo.educator.incentives.comm": "signUp"
};

const CODE_TO_NOTIFICATION_TYPE = {
	"promo.educator.incentives.comm": "COMPANY_PROMOTION"
};

const NOTIFICATION_TYPE_TO_NOTIFICATION_ICON = {
	COMPANY_PROMOTION: "starAsset"
};

const NOTIFICATION_ICON_VALUE_TO_ASSET = {
	"starAsset": starAsset
};

const NOTIFICATION_ICON_TO_ALT_TEXT = {
	"starAsset": messageMap("notifications.star", "image")
};

// NOTIFICATION TYPES:
// community update types
// 1.) like on your comment (LIKE ICON) - COMMUNITY_LIKE
// 2.) comment on your comment and/or your video (COMMENT ICON) - COMMUNITY_COMMENT
// 3.) report/criticism on your video (EXCLAMATION ICON) - COMMUNITY_REPORT
// 4.) praise on your video (PARTY? ICON) - COMMUNITY_PRAISE

// channel subscription update types
// 1.) New video (VIDEO ICON) - SUBSCRIPTION_VIDEO
// 2.) Announcement (BULLHORN ICON) - SUBSCRIPTION_ANNOUNCEMENT

// company update types
// 1.) promotion (STAR ICON) - COMPANY_PROMOTION
// 2.) release announcement (ROCKET ICON) - COMPANY_RELEASE
// 3.) general announcement (NEWSPAPER ICON) - COMPANY_GENERAL


/**
 * @description 
 * @param {Function} msgClickHandler handler when user clicks on the notification
 * @param {Function} popUpCTAHandler CTA handler of notification modal pop-up.
 * This is not the dropdown that shows up when user hovers over the bell icon.
 * @param {Function} clearNotificationsHandler? handler to include additional actions when user clears notifications
 * @returns 
 */
function Notification(props) {
	const [notificationList, setNotificationList] = useState(null),
				[notificationCodes, setNotificationCodes] = useState([]);

	useEffect(() => {
		createNotificationsDropdown();
	}, [props.ownerId]);

	function createNotificationsDropdown() {
		props.promoCodes.forEach(promoCodes => {
			let promotions = [];

			if (promoCodes.length) {
				props.unclearNotificationAction();
			}

			Array.isArray(promoCodes) && promoCodes.forEach(promoCode => {
				const notificationAssetIconValue = NOTIFICATION_TYPE_TO_NOTIFICATION_ICON[CODE_TO_NOTIFICATION_TYPE[promoCode]];
				const notificationAsset = NOTIFICATION_ICON_VALUE_TO_ASSET[notificationAssetIconValue];
				const notificationIconAltTxt = NOTIFICATION_ICON_TO_ALT_TEXT[notificationAssetIconValue];

				promotions.push(
					<li key={promoCode} tabIndex={0} role="button"
							onClick={e => showNotificationDetails(promoCode)}
							onKeyPress={e => onKeyDown(e, showNotificationDetails, [promoCode])}>
						<img src={notificationAsset} alt={notificationIconAltTxt}></img>
						<div className="notification-item">
							<div className="promo-msg">
								{PROMO_CODE_TO_MSG[promoCode]}
							</div>
							<div className="learn-more">
								{messageMap("notification.learnMore", "notification")}
							</div>
						</div>
						<div className="chevron-right"></div>
					</li>
				);
			});

			setNotificationCodes(promoCodes);
			setNotificationList(promotions);
		});
	}

	function showNotificationDetails(promoCode) {
		const fullMsgTxt = PROMO_CODE_TO_FULL_MSG[promoCode],
					title = fullMsgTxt.title,
					args = {
						subheader: fullMsgTxt.subHeader,
						body: fullMsgTxt.body,
						footer: fullMsgTxt.footer,
						ctaHandler: props.popUpCTAHandler,
						ctaHandlerArgs: CODE_TO_POP_UP_CTA[promoCode]
					};

		props.msgClickHandler("notification", title, args);
	}


	function clearNotifications() {
		setNotificationList(null);
		props.clearNotificationsHandler();
		props.clearNotificationsAction();

		const payload = {
			ownerId: props.ownerId,
			codes: notificationCodes
		};
		// Should clear notifications in addition to clearing promotions
		markSeenPromotion(payload, response => {
			console.log(response);
		});
	}

	return (
		<div className="notification">
			<div className="header">
				{messageMap("notification.title", "notification")}
			</div>

			<div>
				{
					notificationList != null &&
					(
						<Fragment>
							<div className="subheader">
								{messageMap("notification.new", "notification")}
							</div>
							<ul>
								{notificationList}
							</ul>
						</Fragment>
					)
				}
			</div>

			{
				// TODO: Since the list of notifications will include promotions and that we want users to
				// 				see these promotions again somewhere else in the platform and that our only notification
				//				so far is just this 1 promotion, prevent users from clearing any notifications right now.
				// (props.ownerId)
				// ?
				// (
				// 	<div className="clear-notifications"
				// 				onClick={clearNotifications} onKeyPress={e => onKeyDown(e, clearNotifications, [e])}
				// 				tabIndex={0} role="button">
				// 		{messageMap("clear.notifications", "button")}
				// 	</div>
				// )
				// :
				// null
			}
		</div>
	);
}

Notification.propTypes = {
	promoCodes: PropTypes.array.isRequired,
	msgClickHandler: PropTypes.func.isRequired,
	popUpCTAHandler: PropTypes.func.isRequired,
	clearNotificationsHandler: PropTypes.func,

	// Redux props
	clearNotificationsAction: PropTypes.func.isRequired,
	unclearNotificationAction: PropTypes.func.isRequired,
	ownerId: PropTypes.string
};

export default connect(
	account,
	{clearNotificationsAction, unclearNotificationAction}
)(Notification);