import React, { useState, useRef, Fragment } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Alert, { SUCCESS } from "templates/Alert";
import Modal from "templates/Modal";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { KEY_TO_URL_MAP } from "./utilities/FooterUtilities";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { saveGeneralOrBugFeedbackAPI } from "apis/controllers/customer/FeedbackController";

import exerLogoBannerAsset from "assets/icons/common/exer_logo_name.svg";
import facebookLogoAsset from "assets/images/home/facebook.svg";
import linkedInLogoAsset from "assets/images/home/linkedIn.svg";
import twitterLogoAsset from "assets/images/home/twitter.svg";


function Footer(props) {

	const [alert, setAlert] = useState(null),
		[domModal, setDomModal] = useState(""),
		[domModal1, setDomModal1] = useState("");

	const issueDetailsRef = useRef();

	function checkAdditionalLinkAction(event, linkType) {
		if (linkType !== "reportIssue") {
			defaultLinkAction();
		}

		if (linkType === "reportIssue") {
			event.preventDefault();
			openReportIssueModal();
		}
		else if (linkType === "becomeATutor") {
			if (props.ownerId == null) {
				event.preventDefault();
				askUserToSignUpFirst(setDomModal, setDomModal1);
			}
		}
	}

	function openReportIssueModal() {
		if (props.ownerId) {
			setDomModal(
				<Modal title={messageMap("account.issue.reportTitle", "generic")} modalClass="report-issue"
					closeType={"xButton"} closeHandler={(e) => closeModal(e)} submitHandler={submitIssue} >
					<div className="report-text">
						{messageMap("account.issue.describeIssue", "generic")}
					</div>
					<textarea className="report-description" onChange={e => issueDetailsRef.current = e.target.value}>
					</textarea>
				</Modal>
			);
		}
		else {
			askUserToSignUpFirst(setDomModal, setDomModal1);
		}
	}
	function submitIssue() {
		const payload = {
			ownerId: props.ownerId,
			feedback: issueDetailsRef.current,
			type: "bug"
		};

		saveGeneralOrBugFeedbackAPI(payload, resp => {
			setAlert(
				<Alert customClass="footer" closeHandler={closeAlert} type={SUCCESS} msg={messageMap(resp, "api")} />
			);
		});
	}

	function closeAlert() {
		setAlert(null);
	}
	function closeModal(e) {
		if (["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			setTimeout(() => {
				setDomModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<Fragment>
			{alert}
			{domModal}
			{domModal1}

			<footer className="footer-container">
				<div className="top-footer">
					<div className="group-links">
						<div className="group-category">
							{messageMap("footer.groupCategories.platform", "generic")}
						</div>

						<div className="group-flex">
							<div className="group-col-block">
								<div className="group-col-container">
									{
										["exploreTutor", "becomeATutor", "videos"].map(key =>
											<Link key={key} className="link" to={KEY_TO_URL_MAP[key]}
												onClick={e => checkAdditionalLinkAction(e, key)}>
												{messageMap(`links.footer.${key}`, "button")}
											</Link>
										)
									}
								</div>
							</div>
							<div className="group-col-block">
								<div className="group-col-container">
									{
										["articles", "practice"].map(key =>
											<Link key={key} className="link" to={KEY_TO_URL_MAP[key]}
												onClick={e => checkAdditionalLinkAction(e, key)}>
												{messageMap(`links.footer.${key}`, "button")}
											</Link>
										)
									}
								</div>
							</div>
						</div>
					</div>

					<div className="group-links">
						<div className="group-category">
							{messageMap("footer.groupCategories.company", "generic")}
						</div>

						<div className="group-flex">
							<div className="group-col-block">
								<div className="group-col-container">
									{
										["aboutUs", "reportIssue"].map(key =>
											<Link key={key} className="link" to={KEY_TO_URL_MAP[key]}
												onClick={e => checkAdditionalLinkAction(e, key)}>
												{messageMap(`links.footer.${key}`, "button")}
											</Link>
										)
									}
								</div>
							</div>
						</div>
					</div>

					<div className="group-links">
						<div className="group-category">
							{messageMap("footer.groupCategories.resources", "generic")}
						</div>

						<div className="group-flex">
							<div className="group-col-block">
								<div className="group-col-container">
									{
										["subscriptions", "tuteePolicy", "tutorPolicy"].map(key =>
											<Link key={key} className="link" to={KEY_TO_URL_MAP[key]}
												onClick={e => checkAdditionalLinkAction(e, key)}>
												{messageMap(`links.footer.${key}`, "button")}
											</Link>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="divider"></div>

				<div className="bottom-footer">
					<div className="banner-container">
						<img className="banner" src={exerLogoBannerAsset} alt={messageMap("companyLogo", "image")}></img>
					</div>

					<div className="legal">
						<Link className="link" to="/terms-and-conditions"
							onClick={e => checkAdditionalLinkAction(e, "termsNConditions")}>
							{messageMap("links.footer.termsAndConditions", "button")}
						</Link>
						<div className="vertical-line">
							|
						</div>
						<Link className="link" to="/privacy-policy"
							onClick={e => checkAdditionalLinkAction(e, "privacyPolicy")}>
							{messageMap("links.footer.privacy", "button")}
						</Link>
					</div>

					<div className="social-media-links">
						<a target="_blank" rel="noreferrer" href="https://www.facebook.com/ExerInstitute">
							<img src={facebookLogoAsset} alt="Facebook logo"></img>
						</a>
						<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/exerinstitute">
							<img src={linkedInLogoAsset} alt="LinkedIn logo"></img>
						</a>
						<a target="_blank" rel="noreferrer" href="https://twitter.com/ExerInstitute">
							<img src={twitterLogoAsset} alt="Twitter logo"></img>
						</a>
					</div>
				</div>
			</footer>
		</Fragment>
	);
}

Footer.propTypes = {
	// redux states
	ownerId: PropTypes.string
};

export default connect(
	account
)(Footer);
