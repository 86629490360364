import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import profileSelector from "redux/selectors/profileSelector";

import PageLayout from "templates/PageLayout";
import {ACCOUNT, TUTORING, GROUPS, FINANCIALS, SUBSCRIPTION} from "templates/utilities/PageSubMenu";
// Sub Pages
import AccountsSubPage from "pages/Profile/subPages/AccountsSubPage";
import TutoringSubPage from "./subPages/TutoringSubPage";
import SubscriptionSubPage from "pages/Profile/subPages/SubscriptionSubPage";
import GroupsSubPage from "pages/Profile/subPages/GroupsSubPage";
import FinancialsSubPage from "pages/Profile/subPages/FinancialsSubPage";

import messageMap from "Utilities/MessageMaps";
import { PROFILE_PAGE_MENU } from "templates/utilities/PageSubMenu";

import { getAccountSettingsAPI } from "apis/controllers/person/AccountsController";


function Profile(props) {

	const [groupNameDisplay, setGroupNameDisplay] = useState(null);

	const [confirmUpdate, setConfirmUpdate] = useState(null),
		[alert, setAlert] = useState(null),
		[modal, setModal] = useState(),
		[modal1, setModal1] = useState();

	// Sub Page stats
	const [accountsSubPage, setAccountsSubPage] = useState(),
		[tutoringSubPage, setTutoringSubPage] = useState(),
		[subscriptionSubPage, setSubscriptionSubPage] = useState(),
		[groupSubPage, setGroupSubPage] = useState(),
		[financialsSubPage, setFinancialsSubPage] = useState();

	// displayRefs
	const accountSubPageRef = useRef(),
		tutoringSubPageRef = useRef(),
		subscriptionSubPageRef = useRef(),
		groupsSubPageRef = useRef(),
		financialsSubPageRef = useRef();

	useEffect(() => {
		getUserAccount();
	}, [props.ownerId]);

	useEffect(() => {
		navigateToSubPage();
	}, [props.profileSubNav]);

	function navigateToSubPage() {
		const urlLocation = window.location.search;
		if (urlLocation.includes("show")) {
			const urlParams = new URLSearchParams(urlLocation);
			subMenuButtonClickHandler(urlParams.get("show"));
		}
	}

	function getUserAccount() {
		const payload = {
			ownerId: localStorage.getItem("ownerId")
		};

		getAccountSettingsAPI(payload, response => {
			const {
				accountVerified, username,
				profilePictureUploadLocation, requiredFields,
				areRequiredPersonalInformationFilledForTutors,
				// personal info
				firstName, lastName, dateOfBirth,
				firstNameKana, lastNameKana, firstNameKanji, lastNameKanji,
				// location
				line1Address, line2Address, cityAddress, stateAddress, country,
				postalCodeAddress, postalAddressKana, postalAddressKanji,
				// contact info
				email, phoneNumber,
				// financials
				earnings, minimumCurrencyWithdrawal, currencySymbol,
				isSubscriber,
				groupIds, isTutor,
				// banking
				accountNumberSample, routingNumberSample
			} = response;

			setAccountsSubPage(
				<AccountsSubPage reRenderAccounts={getUserAccount}
					setConfirmUpdate={setConfirmUpdate} setModal={setModal}
					setAlert={setAlert} closeAlert={closeAlert}
					profilePictureUploadLocation={profilePictureUploadLocation} isTutor={isTutor} requiredFields={requiredFields}
					// personal information
					firstName={firstName} lastName={lastName}
					dob={dateOfBirth}
					firstNameKana={firstNameKana} lastNameKana={lastNameKana}
					firstNameKanji={firstNameKanji} lastNameKanji={lastNameKanji}
					// contact information
					emailAddress={email} phone={phoneNumber}
					// location
					streetAddress1={line1Address} streetAddress2={line2Address} postalCode={postalCodeAddress}
					city={cityAddress} state={stateAddress}
					country={country}
					postalCodeKana={postalAddressKana} postalCodeKanji={postalAddressKanji}
					// login
					acctUsername={username}
					earnings={earnings} minimumCurrencyWithdrawal={minimumCurrencyWithdrawal} />
			);

			setTutoringSubPage(
				<TutoringSubPage setModal={setModal} setModal1={setModal1} setAlert={setAlert}
					accountVerified={accountVerified} firstName={firstName} lastName={lastName}
					country={country} profilePictureUploadLocation={profilePictureUploadLocation}
					requiredFields={requiredFields}
					accountNumberSample={accountNumberSample} routingNumberSample={routingNumberSample} />
			);

			// TODO: Move the currency symbol from FE to BE
			setFinancialsSubPage(
				<FinancialsSubPage earnings={earnings} currencySymbol={currencySymbol}
					minimumCurrencyWithdrawal={minimumCurrencyWithdrawal}
					setAlert={setAlert} closeAlert={closeAlert}
					country={country}
					requiredFields={requiredFields}
					accountNumberSample={accountNumberSample} routingNumberSample={routingNumberSample}
					areRequiredPersonalInformationFilled={areRequiredPersonalInformationFilledForTutors} />
			);

			setSubscriptionSubPage(
				<SubscriptionSubPage earnings={earnings} currencySymbol={currencySymbol}
					minimumCurrencyWithdrawal={minimumCurrencyWithdrawal}
					isSubscriber={isSubscriber}
					setAlert={setAlert} closeAlert={closeAlert} setModal={setModal} />
			);

			setGroupSubPage(
				<GroupsSubPage isMemberOfGroup={groupIds.length > 0}
					setGroupNameDisplay={setGroupNameDisplay}
					setAlert={setAlert} closeAlert={closeAlert} />
			);
		});
	}

	function closeAlert() {
		setAlert(null);
	}

	function subMenuButtonClickHandler(clickedMenuKey) {
		const menuKeyToRefMap = {
			[ACCOUNT]: accountSubPageRef,
			[TUTORING]: tutoringSubPageRef,
			[SUBSCRIPTION]: subscriptionSubPageRef,
			[GROUPS]: groupsSubPageRef,
			[FINANCIALS]: financialsSubPageRef,
		};

		Object.keys(menuKeyToRefMap).forEach(key => {
			menuKeyToRefMap[key].current.className = "profile-page hide";
		});

		menuKeyToRefMap[clickedMenuKey].current.className = "profile-page";
	}

	return (
		<div className="account-settings">
			<Helmet>
				<title>{messageMap("profile.title", "headerTag")}</title>
				<meta name="description" content={messageMap("profile.description", "headerTag")}></meta>
			</Helmet>

			{alert}
			{confirmUpdate}
			{modal}
			{modal1}
			{groupNameDisplay}

			<PageLayout subPageType={PROFILE_PAGE_MENU} pageItemClickHandler={subMenuButtonClickHandler}>
				<div>
					<div className="profile-page" ref={accountSubPageRef}>
						{accountsSubPage}
					</div>

					<div className="profile-page hide" ref={tutoringSubPageRef}>
						{tutoringSubPage}
					</div>

					<div className="profile-page hide" ref={subscriptionSubPageRef}>
						{subscriptionSubPage}
					</div>

					<div className="profile-page hide" ref={groupsSubPageRef}>
						{groupSubPage}
					</div>

					<div className="profile-page hide" ref={financialsSubPageRef}>
						{financialsSubPage}
					</div>
				</div>
			</PageLayout>
		</div>
	);
}

Profile.defaultProps = {
	currencySymbol: "$"
}

Profile.propTypes = {
	currencySymbol: PropTypes.string,

	// Redux props
	profileSubNav: PropTypes.string,
	ownerId: PropTypes.string
};

export default connect(
	profileSelector
)(Profile);