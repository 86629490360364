import React, { useEffect, useState, useRef, Fragment }  from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import Alert, { ERROR } from "templates/Alert";

import {
	dayOfWeekToIntMap, monthMap, timeSlotsMap,
	getAdjustedMinutes
} from "templates/utilities/CalendarUtilities";
import { createBookingTimeSlots } from "templates/utilities/CalendarUtilities";
import messageMap from "Utilities/MessageMaps";
import { 
	calculateTimezoneHourDifferenceAndRelativity, adjustHourAccordingToTimezoneDifference
} from "pages/Tutoring/utilities/TutorProfileUtility";

import chevronAsset from "assets/icons/common/chevron.svg";


function Calendar(props) {

	// today's date
	const [currentDate, setCurrentDate] = useState(new Date()),
		// modified dates
		[newDate, setNewDate] = useState(new Date()),
		[newMonthDate, setNewMonthDate] = useState(),
		[chosenTimeSlot, setChosenTimeSlot] = useState(),
		[chosenDuration, setChosenDuration] = useState(),
		// DOM
		[slotDropdown, setSlotDropdown] = useState(),
		[days, setDays] = useState(),
		[availableTimeSlots, setAvailableTimeSlots] = useState();

	// value refs
	const tutorScheduleRef = useRef(),
		bookedTimeSlotsRef = useRef(),
		dropdownVisibilityRef = useRef();

	useEffect(() => {
		parseTutorAvailability();
	}, []);

	useEffect(() => {
		createDays(newDate);
		createTimeSlots(null);

		setSlotDropdown(
			<Dropdown key={newDate.getDate()} customDropdownItemAttribute="sessionLength" dropdownItemClickHandler={createTimeSlots}
				customContainerClass="dropdown-session-interval" dropdownOptions={timeSlotsMap} preselectedKey={props.preSelectedTimeRange} />
		);
	}, [newDate]);

	function parseTutorAvailability() {
		let weekAvailability = {};
		const [hourTimezoneDifference, addOrSubtract] = calculateTimezoneHourDifferenceAndRelativity(props.tutorTimezone);

		props.schedule.split("|").forEach(schedule => {
			const tuple = schedule.split(":");
			if (tuple[1].includes("unavailable")) {
				weekAvailability[dayOfWeekToIntMap[tuple[0]]] = -1;
			}
			else {
				const fromMinToHourTuple = tuple[2].split("_");
				let firstHour = adjustHourAccordingToTimezoneDifference(hourTimezoneDifference, addOrSubtract, Number(tuple[1]));
				let secondHour = adjustHourAccordingToTimezoneDifference(hourTimezoneDifference, addOrSubtract, Number(fromMinToHourTuple[1]));
				firstHour = firstHour < 10 ? `0${firstHour}` : firstHour > 24 ? firstHour - 24 : firstHour;
				// adjust again if hour was over 24
				firstHour = firstHour < 10 ? `0${firstHour}` : firstHour;
				secondHour = secondHour < 10 ? `0${secondHour}` : secondHour > 24 ? secondHour - 24 : secondHour;
				secondHour = secondHour < 10 ? `0${secondHour}` : secondHour;

				weekAvailability[dayOfWeekToIntMap[tuple[0]]] = {
					from: `${firstHour}_${fromMinToHourTuple[0]}`,
					to: `${secondHour}_${tuple[3]}`
				};
			}
		});

		let bookedSessionSlots = {};
		props.bookedTimeSlots.forEach(timeSlot => {
			const bookedSlot = new Date(Number(timeSlot.date));
			// for some reason, month starts at 0, and Date starts at 1
			const monthDate = bookedSlot.getMonth() + 1;
			const dayDate = bookedSlot.getDate();
			const hoursDate = bookedSlot.getHours();
			const minutesDate = bookedSlot.getMinutes();

			if (bookedSessionSlots[monthDate] == null) {
				bookedSessionSlots[monthDate] = {};
			}
			if (bookedSessionSlots[monthDate][dayDate] == null) {
				bookedSessionSlots[monthDate][dayDate] = {};
			}
			if (bookedSessionSlots[monthDate][dayDate][hoursDate] == null) {
				bookedSessionSlots[monthDate][dayDate][hoursDate] = {};
			}

			bookedSessionSlots[monthDate][dayDate][hoursDate][minutesDate] = timeSlot.duration;
		});

		tutorScheduleRef.current = weekAvailability;
		bookedTimeSlotsRef.current = bookedSessionSlots;
	}

	function createDays(newDate) {
		const newDateFullYear = newDate.getFullYear();
		const newDateMonth = newDate.getMonth();

		const numDaysThisMonth = new Date(newDateFullYear, newDateMonth + 1, 0).getDate();
		const monthsStartingDay = new Date(newDateFullYear, newDateMonth, 1);

		let weekRows = [];
		let days = [];
		// beginning of month blank squares
		if (monthsStartingDay.getDay() !== 0) {
			for (let i = 0; i < monthsStartingDay.getDay(); ++i) {
				days.push(
					<button key={`starting-empty-${i}`} className="day blank"></button>
				);
			}
		}
		// dated squares
		let preselectedDayCounter = 1;
		for (let i = 0; i < numDaysThisMonth; ++i) {
			if (days.length === 7) {
				weekRows.push(
					<div key={weekRows.length} className="week-container">
						{days}
					</div>
				);
				days = [];
			}

			let dayClass = "day";
			if (tutorScheduleRef.current[days.length % 7] === -1) {
				dayClass = "day unavailable-day";
			}
			else if (newDateMonth === currentDate.getMonth()) {
				if (currentDate.getDate() === i + 1) {
					dayClass = "day current";
				}
				else if (i + 1 < currentDate.getDate()) {
					dayClass = "day past-day";
				}
			}
			else if (tutorScheduleRef.current[days.length % 7] !== -1 && preselectedDayCounter) {
				dayClass = "day current";
				--preselectedDayCounter;
			}
			days.push(
				<button key={i} className={dayClass} onClick={e => createTimeSlotsForDay(e, dayClass)} >
					{i + 1}
				</button>
			);
		}
		// end of month blank squares
		if (days.length !== 7) {
			while (days.length !== 7) {
				days.push(
					<button key={`ending-empty-${days.length}`} className="day blank"></button>
				);
			}
		}
		weekRows.push(
			<div key={weekRows.length} className="week-container">
				{days}
			</div>
		);

		setNewMonthDate(monthMap[newDate.getMonth()]);
		setDays(weekRows);
	}

	function goToPrevMonth() {
		const newDateFullYear = newDate.getFullYear();
		const newDateMonth = newDate.getMonth();

		if (newDateFullYear > currentDate.getFullYear() || newDateMonth - 1 >= currentDate.getMonth()) {
			const newMonthDate = new Date(newDateFullYear, newDateMonth - 1, 1);

			setNewMonthDate(monthMap[newDateMonth - 1]);
			setNewDate(newMonthDate);
		}
	}
	function goToNextMonth() {
		const newDateMonth = newDate.getMonth();
		const newMonthDate = new Date(newDate.getFullYear(), newDateMonth + 1, 1);

		setNewMonthDate(monthMap[newDateMonth + 1]);
		setNewDate(newMonthDate);
	}

	function saveSelectedTimeSlot(e, selectedTimestamp) {
		clearSelectedTimeSlots();
		e.target.className = "session-slot clicked";

		setChosenTimeSlot(selectedTimestamp);
	}

	function clearSelectedTimeSlots() {
		document.querySelectorAll(`button[class="session-slot clicked"]`).forEach(prevSelectSlot => {
			prevSelectSlot.className = "session-slot";
		});
	}

	function createTimeSlots(e) {
		if (e == null) {
			setAvailableTimeSlots(generateTimeSlots(tutorScheduleRef.current, bookedTimeSlotsRef.current, props.preSelectedTimeRange));
			setChosenDuration(props.preSelectedTimeRange);
		}
		else {
			const sessionLength = e.target.getAttribute("sessionLength");
			setAvailableTimeSlots(generateTimeSlots(tutorScheduleRef.current, bookedTimeSlotsRef.current, Number(sessionLength)));
			setChosenDuration(sessionLength);
		}
	}

	function createTimeSlotsForDay(e, dayType) {
		if (["day current", "day"].includes(dayType)) {
			document.querySelectorAll(`button[class="day current"]`).forEach(prevSelectedDay => {
				prevSelectedDay.className = "day";
			});
			e.target.className = "day current";

			clearSelectedTimeSlots();
			setChosenTimeSlot(null);
			setNewDate(new Date(newDate.getFullYear(), newDate.getMonth(), e.target.innerText));
		}
	}

	function generateTimeSlots(weekAvailability, bookedSessionSlots, sessionInterval) {
		const newDateMonth = newDate.getMonth();
		const currentDay = newDate.getDay();
		let newDateDay = currentDay;

		if (newDateMonth === currentDate.getMonth() && newDate.getDate() < currentDate.getDate()) {
			newDateDay = currentDate.getDay();
		}
		while(tutorScheduleRef.current[newDateDay] === -1) {
			++newDateDay;
			newDateDay = newDateDay % 7;
		}

		let timeSlots = [];
		if (weekAvailability[newDateDay] !== -1) {
			const monthDate = newDateMonth;
			const dayOfMonth = newDate.getDate();

			let daysBookedSessionsSlots = [];
			if (bookedSessionSlots[monthDate] && bookedSessionSlots[monthDate][dayOfMonth]) {
				for (const [key, value] of Object.entries(bookedSessionSlots[monthDate][dayOfMonth])) {
					const hour = Number(key);

					for (const [minStart, minDuration] of Object.entries(value)) {
						const bookedStartingTime = (hour * 60) + Number(minStart);

						for (let i = bookedStartingTime; i < bookedStartingTime + minDuration; i += 15) {
							daysBookedSessionsSlots.push(i);
						}
					}
				}
			}

			const fromTime = weekAvailability[newDateDay].from.split("_");
			const toTime = weekAvailability[newDateDay].to.split("_");

			const adjustedStartingMinutes = getAdjustedMinutes(fromTime[0], fromTime[1], "starting");
			const adjustedEndingMinutes = getAdjustedMinutes(toTime[0], toTime[1], "ending");
			const isCurrentDayAvailable = weekAvailability[currentDay] !== -1;

			// for cases when the availability is from PM to AM
			if (adjustedEndingMinutes < 720) {
				timeSlots = createBookingTimeSlots(
					0, adjustedEndingMinutes,
					sessionInterval, daysBookedSessionsSlots,
					monthDate + 1, dayOfMonth, newDate,
					isCurrentDayAvailable,
					setChosenTimeSlot, saveSelectedTimeSlot
				);
				timeSlots = timeSlots.concat(createBookingTimeSlots(
					adjustedStartingMinutes, 1440,
					sessionInterval, daysBookedSessionsSlots,
					monthDate + 1, dayOfMonth, newDate,
					isCurrentDayAvailable,
					setChosenTimeSlot, saveSelectedTimeSlot
				));
			}
			else {
				timeSlots = createBookingTimeSlots(
					adjustedStartingMinutes, adjustedEndingMinutes,
					sessionInterval, daysBookedSessionsSlots,
					monthDate + 1, dayOfMonth, newDate,
					isCurrentDayAvailable,
					setChosenTimeSlot, saveSelectedTimeSlot
				);
			}

			dropdownVisibilityRef.current = "";

			return timeSlots;
		}
		else {
			timeSlots = (
				<div className="unavailable-slots">
					{messageMap("tutoringPage.tutorList.pane.unavailable", "generic")}
				</div>
			);

			dropdownVisibilityRef.current = "hide";
		}

		return timeSlots;
	}

	function passSelectionToNext() {
		if (chosenTimeSlot) {
			const timestamp = chosenTimeSlot.split("_");

			props.nextStepHandler({
				month: timestamp[0],
				dayOfMonth: timestamp[1],
				dayOfWeek: timestamp[2],
				hour: timestamp[3],
				minutes: timestamp[4],
				amPm: timestamp[5],
				duration: chosenDuration,
				rate: props.topicRates[chosenDuration],
				subject: props.subject,
				topic: props.topic
			});
		}
		// this will only get hit if the current day isn't available for the tutor and when the user 
		// doesn't select an available day
		// the ideal scenario is to automatically select the tutor's nearest available day, but because 
		// we're rendering the days before the timeSlots and that they're rendered separately, we'll just
		// inform the user at this point
		else {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("tutoringPage.tutorList.pane.modal.validDay", "generic")} />
			);
		}
	}
	function closeAlert() {
		props.setAlert(null);
	}

	return (
		<Fragment>
			<div className="calendar-component">

				<div className="calendar">
					<div className="month-navigation-container">
						<button className="month-nav-button" onClick={goToPrevMonth} >
							<img className="nav-chevron left" src={chevronAsset} alt={`${messageMap("chevron.left.text", "image")}. ${messageMap("chevron.left.actions.prevMonth", "image")}`} />
						</button>
						<div className="current-month">
							{newMonthDate}
						</div>
						<button className="month-nav-button" onClick={goToNextMonth} >
							<img className="nav-chevron right" src={chevronAsset} alt={`${messageMap("chevron.right.text", "image")}. ${messageMap("chevron.left.actions.nextMonth", "image")}`} />
						</button>
					</div>

					<div className="day-of-week-container">
						{
							["S", "M", "T", "W", "T", "F", "S"].map((day, key) =>
								<div key={`day_abr${key}`} className="day-of-week">
									{day}
								</div>
							)
						}
					</div>

					{days}
				</div>

				<div className="day-availabilities">
					<div className={`session-lengths-dropdown ${dropdownVisibilityRef.current}`}>
						{slotDropdown}
					</div>
					<div className="time-slots">
						{availableTimeSlots}
					</div>
				</div>
			</div>

			<div className="calendar-actions">
				<button className="action-button" onClick={passSelectionToNext} >
					{messageMap("tutoringPage.tutorList.pane.modal.continue", "generic")}
				</button>
				<div className="step-indicator">
					{messageMap("tutoringPage.tutorList.pane.modal.step12", "generic")}
				</div>
			</div>
		</Fragment>
	);
}

Calendar.propTypes = {
	tutorTimezone: PropTypes.string.isRequired,
	preSelectedTimeRange: PropTypes.number.isRequired,
	subject: PropTypes.string.isRequired,
	topic: PropTypes.string.isRequired,
	topicRates: PropTypes.object.isRequired,
	schedule: PropTypes.string.isRequired,
	bookedTimeSlots: PropTypes.array.isRequired,
	setAlert: PropTypes.func.isRequired,
	// props connecting current component to next modal component
	nextStepHandler: PropTypes.func.isRequired,
	nextData: PropTypes.object
};

export default Calendar;

