import React, { useState, useEffect, useRef, Fragment }  from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setTutoringSubNav } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Alert, { ERROR, SUCCESS } from "templates/Alert";
import Dropdown from "templates/Dropdown";
import Modal from "templates/Modal";
import SideFilter from "templates/SideFilter";
import TutorCard from "pages/Tutoring/subPages/components/TutorCard";
import LearnMoreModal from "pages/Tutoring/subPages/components/LearnMoreModal";
import PublicTutorProfile from "./PublicTutorProfile";
import TutorComparisonModal from "pages/Tutoring/subPages/modals/TutorComparisonModal";
import Pagination from "templates/Pagination";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { promiseAll, POST, GET } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";
import { LANGUAGE_FILTER, SUBJECT_FILTER, PRICE_FILTER } from "templates/utilities/FilterUtility";
import { 
	LESS_THAN_10, BETWEEN_10_AND_20_EXCLUSIVE, BETWEEN_25_AND_50_EXCLUSIVE, MORE_THAN_50 
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { SESSIONS } from "templates/utilities/PageSubMenu";
import { goToPageHandler } from "pages/Classes/Videos/VideoListUtilities";
import { 
	TUTEE_USER, 
	OPEN_STATE, PENDING_STATE, RECEIVED_DIRECTION,
	VIEW_APPLICANTS, VIEW_SAVED, VIEW_TUTORS
} from "../utilities/TutoringRequestConstants";
import { AUTH_TOKEN, TUTOR_LIMIT_PER_SESSION } from "Utilities/Constants/MediaConstants";
import { SESSIONS_PAST_SESSIONS } from "templates/utilities/PageTabs";
import { REQUESTS } from "templates/utilities/PageSubMenu";
import { teachableSubjects, topicsInSubjects } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { getAccountSettingsAPI, saveAccountSettingsAPI, GET_ACCOUNT_SETTINGS } from "apis/controllers/person/AccountsController";
import { GET_TUTOR_SESSION_REQUESTS } from "apis/controllers/tutoring/SessionRequestController";
import { 
	getTutorProfileListAPI, GET_TUTOR_PROFILE_LIST, getTutorEntriesCountAPI,
	getTutorProfileAPI
} from "apis/controllers/tutoring/TutorProfileController";
import { GET_REVIEWS_BY_OWNER_ID } from "apis/controllers/tutoring/TutoringReviewController";


/**
 * @param {Boolean} useFilter to indicate if a filter should be included
 * @param {String} purpose for what specific reason the list is being used for
 */
function TutorsList(props) {

	const identityIsValid = isValidIdentity(props.ownerId);
	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const urlLocation = window.location.href;
	const paginationRegEx = new RegExp("(?<=page=)[0-9]");

	const history = useHistory();

	const [pageNumbersDom, setPageNumbersDom] = useState(null);
	const [filtersDom, setFiltersDom] = useState();
	const [tutorList, setTutorList] = useState(null),
		[tutorListClassName, setTutorListClassName] = useState(""),
		[filterMap, setFilterMap] = useState({}),
		[tutorProfilePane, setTutorProfilePane] = useState(),
		[allowTutorComparison, setAllowTutorComparison] = useState("not-allowed"),
		[hideTutorComparison, setHideTutorComparison] = useState("hide"),
		// for paginations
		[currentPage, setCurrentPage] = useState(
			urlLocation.includes("page") ? paginationRegEx.exec(urlLocation)[0] - 1 : 0
		),
		[pageSize, setPageSize] = useState(10);
	// specific to viewing tutor applications
	const [requestsDropdown, setRequestsDropdown] = useState(),
		[showApplicationActions, setShowApplicationActions] = useState(false),
		[clickedTutors, setClickedTutors] = useState([]),
		[tutorLimitPerRequest, setTutorLimitPerRequest] = useState();

	// value refs
	const savedTutorsRef = useRef(),
		sessionIdToTutorIdsRef = useRef(),
		sessionIdToTopicRateRef = useRef(),
		currentSessionIdRef = useRef(),
		newTutorLimitPerRequestRef = useRef();
	// dom refs
	const tutorProfileRef = useRef(),
		tutorListRef = useRef();

	useEffect(() => {
		// if user just wants to see a list of tutors, doesn't matter if they're logged in or not
		if (props.purpose === VIEW_TUTORS) {
			getSavedTutors(createTutorProfileList);
			setFiltersDom(
				<SideFilter filterCategories={[LANGUAGE_FILTER, SUBJECT_FILTER, PRICE_FILTER]} filterHandler={filterList} purpose={props.purpose} />
			);
		}
		// if user is logged in, the user isn't a tutor, and wants to see tutors who applied to their request
		else if (identityIsValid) {
			if (props.purpose === VIEW_APPLICANTS) {
				getSavedTutorsAndTutorApplicants();
			}
			else if (props.purpose === VIEW_SAVED) {
				getSavedTutors(createTutorProfileList);
			}
		}

		const urlParams = new URLSearchParams(window.location.search);
		const tutorOwnerId = urlParams.get("id");
		if (tutorOwnerId != null) {
			const pathVariables = {
				tutorOwnerId: tutorOwnerId
			};
			getTutorProfileAPI(pathVariables, resp => {
				showFullProfile(null, resp);
			});
		}
	}, [props.ownerId, currentPage]);

	useEffect(() => {
		if (props.purpose === VIEW_TUTORS) {
			createPagination(null);
		}
	}, []);

	function createPagination(newFilterMap) {
		const payload = {
			filterMap: newFilterMap
		};

		getTutorEntriesCountAPI(payload, pageCount => {
			const numberOfPages = pageCount / pageSize;

			if (numberOfPages > 0) {
				const finalPageNumber = Math.ceil(numberOfPages);

				setPageNumbersDom(
					<Pagination key={`${pageCount}_${newFilterMap != null ? "filter" : ""}`} baseUrl="/tutoring?show=tutorsList"
						itemCount={pageCount}
						currentPage={currentPage} 
						pageSize={pageSize} totalPageCount={finalPageNumber}
						pageHandler={goToPage} />
				);
			}
		});
	}

	function goToPage(pageNumber, totalPageNumber) {
		goToPageHandler(pageNumber, currentPage, totalPageNumber, setCurrentPage);
	}

	function getSavedTutors(callback) {
		if (identityIsValid) {
			const payload = {
				ownerId: localStorage.getItem("ownerId")
			};

			getAccountSettingsAPI(payload, resp => {
				savedTutorsRef.current = resp.savedTutors;
				callback(null);
			});
		}
		else {
			callback(null);
		}
	}

	function getSavedTutorsAndTutorApplicants() {
		const sessionPathVariables = {
			sessionStates: [OPEN_STATE, PENDING_STATE],
			sentOrReceived: RECEIVED_DIRECTION,
			intendedUser: TUTEE_USER,
			tutorOwnerId: ""
		};

		const apiArr = [
			{
				api: GET_ACCOUNT_SETTINGS,
				type: POST,
				payload: {
					ownerId: ownerId
				}
			}, {
				api: $replace(GET_TUTOR_SESSION_REQUESTS, sessionPathVariables),
				type: POST,
				payload: ownerId
			}
		];

		promiseAll(apiArr, resp => {
			savedTutorsRef.current = resp[0].data.savedTutors;

			let dropdownOptions = {};
			let requestIdToTopicRateMap = {};
			let sessionIdToTutorIds = {};
			resp[1].data.forEach(request => {
				const requestId = request.id;

				request.tutorApplicants.forEach(tutorApplicantOwnerId => {
					if (sessionIdToTutorIds[requestId] == null) {
						sessionIdToTutorIds[requestId] = [];
					}
					sessionIdToTutorIds[request.id].push(tutorApplicantOwnerId);
				});

				dropdownOptions[request.id] = `${teachableSubjects[request.subject]} : ${topicsInSubjects[request.subject][request.topic]}`;
				requestIdToTopicRateMap[request.id] = request.tutorIdToTutorTopicRate;
			});

			let firstSessionId = Object.keys(sessionIdToTutorIds)[0];
			if (Object.keys(sessionIdToTutorIds).length) {
				const filter = {
					"ownerIdKey": sessionIdToTutorIds[firstSessionId]
				};

				currentSessionIdRef.current = firstSessionId;
				sessionIdToTutorIdsRef.current = sessionIdToTutorIds;
				sessionIdToTopicRateRef.current = requestIdToTopicRateMap;
				setShowApplicationActions(true);
				createTutorProfileList(filter);
				createPagination(filter);
				setTutorLimitPerRequest(resp[0].data.tutorLimitPerSession);
				setFilterMap(filter);
				setRequestsDropdown(
					<Dropdown key={Object.keys(dropdownOptions).join("_")}
						dropdownOptions={dropdownOptions} preselectedKey={firstSessionId}
						customDropdownItemAttribute="sessionId" 
						dropdownItemClickHandler={changeTutorListGroup} customContainerClass="session-groups" />
				);
				setTutorListClassName("applications-list");
				if ((props.purpose === VIEW_APPLICANTS && Object.keys(requestIdToTopicRateMap[firstSessionId]).length !== 0)
					|| ([VIEW_SAVED, VIEW_TUTORS].includes(props.purpose))) {
					createTutorProfileList(filter);
					createPagination(filter);
				}
			}
			else {
				setShowApplicationActions(false);
				setTutorList(
					<div className="empty-list-container">
						<div className="empty-list">
							{messageMap("tutoringPage.tutorList.emptyApplications1", "generic")}
							<Link to="/tutoring?show=requests" className="here-link" 
								onClick={navigateToSubPage} >
								{messageMap("here.text", "button")}
							</Link>
							{` ${messageMap("tutoringPage.tutorList.emptyApplications2", "generic")}`}
						</div>
					</div>
				);
				setTutorListClassName("empty");
				setAllowTutorComparison("not-allowed");
				setHideTutorComparison("hide");
				setPageNumbersDom(null);
			}
		});
	}

	function changeTutorListGroup(e) {
		const sessionId = e.target.getAttribute("sessionId");
		const tutorId = sessionIdToTutorIdsRef.current[sessionId];

		currentSessionIdRef.current = sessionId;
		const newFilterMap = {
			"ownerIdKey": tutorId
		};
		setFilterMap(newFilterMap);

		if (tutorId != null) {
			createTutorProfileList(newFilterMap);
		}
		else {
			showEmptyTutorList("emptyTutoringApplicants");
		}
	}

	function collectClickedTutors(tutorId, removeOrAdd) {
		let currentAllowTutorComparison = clickedTutors;
		if (removeOrAdd === "add") {
			currentAllowTutorComparison.push(tutorId);
		}
		else {
			currentAllowTutorComparison.splice(currentAllowTutorComparison.indexOf(tutorId), 1);
		}

		setClickedTutors(currentAllowTutorComparison);
		setAllowTutorComparison(currentAllowTutorComparison.length === 2 ? "" : "not-allowed");
	}

	function compareTutors() {
		if (clickedTutors.length === 2) {
			const tutorProfilePathVariables = {
				pageCount: 0,
				pageSize: 2
			};
			const reviewsPathVariables = {
				ownerIds: clickedTutors
			};
			const payload = {
				filterMap: {
					"ownerIdKey": clickedTutors
				}
			};
			const apiArr = [
				{
					api: $replace(GET_TUTOR_PROFILE_LIST, tutorProfilePathVariables),
					type: POST,
					payload: payload
				}, {
					api: $replace(GET_REVIEWS_BY_OWNER_ID, reviewsPathVariables),
					type: GET
				}
			];

			promiseAll(apiArr, resp => {
				props.setModal(
					<Modal modalClass="tutoring tutor-comparisons" closeType="xButton" closeHandler={closeModal}
						title={messageMap("tutoringPage.tutorList.modal.compareTutors", "generic")}>
						<TutorComparisonModal tutorProfiles={resp[0].data} tutoringReviews={resp[1].data}
							setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
					</Modal>
				);
			});
		}
	}

	function changeCapacity() {
		props.setModal(
			<Modal closeHandler={closeModal} submitText={messageMap("update.text", "button")}
				title={messageMap("tutoringPage.tutorList.modal.changeCapacity", "generic")}
				subHeader={messageMap("tutoringPage.tutorList.modal.maxApplicants", "generic")}
				submitHandler={saveNewTutorLimit} modalClass="tutoring change-capacity" >
				<input className="capacity-input" type="number" min="5" max="20" onChange={updateTutorLimit} />
			</Modal>
		);
	}

	function updateTutorLimit(e) {
		newTutorLimitPerRequestRef.current = e.target.value;
	}

	function saveNewTutorLimit() {
		if (newTutorLimitPerRequestRef.current < 5 || newTutorLimitPerRequestRef.current > 20) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring" 
					msg={messageMap("tutoring.sessionLimit", "validation")} />
			);
		}
		else {
			const payload = {
				[AUTH_TOKEN]: props.authToken,
				[TUTOR_LIMIT_PER_SESSION]: newTutorLimitPerRequestRef.current
			};
			let fieldsToValidate = [];
			for (const [key, value] of Object.entries(payload)) {
				value && fieldsToValidate.push(key);
			}
			saveAccountSettingsAPI({ownerId: ownerId}, payload, fieldsToValidate, resp => {
				const responseCodes = resp.responseCodes;
				let alertType = ERROR;
				let allMsgs = [];
				responseCodes.forEach(respCode => {
					if (respCode.includes("success")) {
						alertType = SUCCESS;
					}
					allMsgs.push(messageMap(respCode, "api"));
				});

				props.setAlert(
					<Alert closeHandler={closeAlert} type={alertType} customClass="tutoring" 
						msg={allMsgs} />
				);
				setTutorLimitPerRequest(newTutorLimitPerRequestRef.current);
			});
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function createTutorProfileList(newFilterMap = {}) {
		const pathVariables = {
			pageCount: currentPage,
			pageSize: pageSize
		};
		const payload = {
			filterMap: newFilterMap
		};
		const timestamp = (new Date()).getTime();

		getTutorProfileListAPI(pathVariables, payload, resp => {
			let tutorListDom = [];
			let savedTutorOwnerIds = [];

			resp.forEach(tutorProfile => {
				let isSaved = false;
				const tutorProfileId = tutorProfile.tutorProfileId;
				if (savedTutorsRef.current != null && savedTutorsRef.current.includes(tutorProfileId)) {
					isSaved = true;
				}

				let sessionPrice = null;
				if (currentSessionIdRef.current != null) {
					sessionPrice = sessionIdToTopicRateRef.current[currentSessionIdRef.current][tutorProfileId];
				}

				const tutorCard = (
					<TutorCard key={`${tutorProfileId}_${isSaved}_${timestamp}`} purpose={props.purpose}
						// only for deciding on tutors 
						// TODO: need to remove a dropdown topic after user chooses to work with a tutor
						requestId={currentSessionIdRef.current} refreshList={getSavedTutorsAndTutorApplicants}
						setClickedTutors={collectClickedTutors} tutorOwnerId={tutorProfile.tutorOwnerId}
						// mostly for viewing tutors
						tutorId={tutorProfileId}
						firstName={tutorProfile.firstName} lastName={tutorProfile.lastName}
						occupation={tutorProfile.occupation} subjectList={tutorProfile.subjectToTopicsMap}
						isSaved={isSaved} profilePicture={tutorProfile.profilePictureUploadLocation}
						setAlert={props.setAlert} setModal={props.setModal} setModal1={props.setModal1} setSpinner={props.setSpinner}
						priceDetailsHandler={e => showPriceDetails(e, tutorProfile)}
						learnMoreHandler={e => showFullProfile(e, tutorProfile)}
						// for paying for tutoring session, in case user's saved payment method gets declined
						sessionId={currentSessionIdRef.current} sessionPrice={sessionPrice} />
				);

				if (props.purpose === VIEW_SAVED) {
					if (isSaved) {
						tutorListDom.push(tutorCard);
						savedTutorOwnerIds.push(tutorProfile.tutorOwnerId);
					}
				}
				else {
					tutorListDom.push(tutorCard);
				}
			});

			if (props.purpose === VIEW_SAVED) {
				const filter = {
					"ownerIdKey": savedTutorOwnerIds
				};
				createPagination(filter);

				if (tutorListDom.length) {
					setTutorList(tutorListDom);
					setFiltersDom(
						<SideFilter filterCategories={[LANGUAGE_FILTER, SUBJECT_FILTER, PRICE_FILTER]} filterHandler={filterList} purpose={props.purpose} />
					);
					setHideTutorComparison("");
				}
				else {
					setTutorList(
						<div className="save-container">
							<Link className="save-link" to={`/tutoring?show=sessions&sub=${SESSIONS_PAST_SESSIONS}`}
								onClick={e => navigateToSubPage(REQUESTS)}>
								<button className="save-trusted-tutors-button">
									{messageMap("tutoring.savedTutors.saveTutors", "button")}
								</button>
							</Link>
						</div>
					);
				}
			}
			else {
				if (tutorListDom.length) {
					setTutorList(tutorListDom);
					setHideTutorComparison("");
				}
				else {
					showEmptyTutorList("emptyTutoringList");
				}
			}
		});
	}

	function showEmptyTutorList(emptyKey) {
		setHideTutorComparison("hide");
		setTutorList(
			<div className="empty-list-container">
				{messageMap(`tutoringPage.tutorList.${emptyKey}`, "generic")}
			</div>
		);
		setPageNumbersDom(null);
	}

	function navigateToSubPage(domKey) {
		defaultLinkAction();
		props.setTutoringSubNav(domKey);

		// taken from pageLayout
		let clickedPageLinks = document.querySelectorAll(`a[class="page-sub-menu-button chosen"]`);
		clickedPageLinks.forEach(pageLink => {
			pageLink.className = "page-sub-menu-button";
		});
		clickedPageLinks = document.querySelectorAll(`a[class="page-sub-menu-button"]`);
		clickedPageLinks.forEach(pageLink => {
			if (pageLink.getAttribute("domkey") === SESSIONS) {
				pageLink.className = "page-sub-menu-button chosen";
			}
		});
	}

	function showFullProfile(e, tutorDetails) {
		if (e == null || e.target.getAttribute("domkey") === "profileDetails") {
			let isSaved = false;
			if (savedTutorsRef.current != null && savedTutorsRef.current.includes(tutorDetails.tutorProfileId)) {
				isSaved = true;
			}

			hideModal();
			setTutorProfilePane(
				<PublicTutorProfile tutorDetails={tutorDetails} showBookOption={true} isSaved={isSaved} closePane={closePane}
					setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
			);

			setTimeout(() => {
				window.scrollTo(0, 0);
				tutorProfileRef.current.className = "public-tutor-profile";
				tutorListRef.current.className = "tutor-list-container hide";

				window.history.replaceState(null, "", `/tutoring?show=tutorsList&id=${tutorDetails.tutorOwnerId}`);
			}, 250);
		}
	}

	function closePane() {
		setTutorProfilePane(null);
		tutorProfileRef.current.className = "public-tutor-profile hide";
		tutorListRef.current.className = "tutor-list-container";
	}

	function showPriceDetails(e, tutorDetails) {
		if (e.target.getAttribute("domkey") === "priceBreakdown") {
			props.setModal(
				<Modal closeHandler={closeModal}
					title={`${tutorDetails.firstName} ${tutorDetails.lastName}`}
					submitText={messageMap("tutoringPage.tutorList.modal.viewFullDetails", "generic")}
					modalClass="tutoring" closeType="xButton" submitHandler={e => showFullProfile(e, tutorDetails)}>

					<LearnMoreModal tutorDetails={tutorDetails} setAlert={props.setAlert}
						setModal={props.setModal} setModal1={props.setModal1} />
				</Modal>
			);
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			props.setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}
	
	function filterList(e, filterType, checkedFilters) {
		let sanitizedFilters = checkedFilters.filter(filter => filter != null);
		if (filterType === "priceKey") {
			for (let i = 0; i < sanitizedFilters.length; ++i) {
				const value = sanitizedFilters[i];

				// these are java regex meant for the BE
				if (value === LESS_THAN_10) {
					sanitizedFilters[i] = "0[0-9].[0-9][0-9]";
				}
				else if (value === BETWEEN_10_AND_20_EXCLUSIVE) {
					sanitizedFilters[i] = "[1][0-9].[0-9][0-9]";
				}
				else if (value === BETWEEN_25_AND_50_EXCLUSIVE) {
					sanitizedFilters[i] = "[234][0-9].[0-9][0-9]";
				}
				else if (value === MORE_THAN_50) {
					sanitizedFilters[i] = "[56789][0-9].[0-9][0-9]";
				}
			}
		}

		let currentFilters = filterMap;

		if (!Object.keys(filterMap).includes(filterType)) {
			currentFilters[filterType] = {};
		}
		
		if (!sanitizedFilters.length) {
			delete currentFilters[filterType];
		}
		else {
			currentFilters[filterType] = sanitizedFilters;
		}

		setFilterMap(currentFilters);
		createTutorProfileList(currentFilters);
		createPagination(currentFilters);
	}

	return (
		<Fragment>
			<div className="tutor-list-page">
				<Helmet>
					<title>{messageMap("tutorList.title", "headerTag")}</title>
					<meta name="description" content={messageMap("tutorList.description", "headerTag")}></meta>
				</Helmet>

				<div ref={tutorProfileRef} className="public-tutor-profile hide">
					{tutorProfilePane}
				</div>

				<div ref={tutorListRef} className="tutor-list-container" >
					<div className="filter-list-container">
						<div className={`filter-container ${props.useFilter ? "" : "hide"}`}>
							{filtersDom}
						</div>

						<div className={`tutor-list ${tutorListClassName}`}>
							{
								<div className="application-actions">
									<div>
										{
											props.purpose === VIEW_APPLICANTS && showApplicationActions
											? (requestsDropdown)
											: ""
										}

										<button className={`compare-button ${hideTutorComparison} ${allowTutorComparison}`}
											onClick={compareTutors}>
											{messageMap("tutoring.tutorList.compare", "button")}
										</button>
									</div>

									{
										props.purpose === VIEW_APPLICANTS && showApplicationActions
										? (
											<button className="capacity-button" onClick={changeCapacity}>
												{`${messageMap("tutoring.tutorList.changeCapacity", "button")} (${tutorLimitPerRequest})`}
											</button>
										)
										: ""
									}
								</div>
							}
							<div className="tutor-cards-container">
								{tutorList}
							</div>
						</div>
					</div>

					{pageNumbersDom}
				</div>
			</div>

		</Fragment>
	);
}

TutorsList.defaultProps = {
	useFilter: true,
	purpose: "viewTutors"
};

TutorsList.propTypes = {
	useFilter: PropTypes.bool.isRequired,
	purpose: PropTypes.string.isRequired,

	// parent setter props
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func,
	setSpinner: PropTypes.func,

	// redux props
	ownerId: PropTypes.string,
	setTutoringSubNav: PropTypes.func.isRequired,
	authToken: PropTypes.string
};

export default connect(
	account,
	{
		setTutoringSubNav
	}
)(TutorsList);
