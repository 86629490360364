// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const documentMessageMap = {
	aboutUs: {
		headline: {
			title: "- Our Purpose -",
			subheader: "To empower and cultivate people's lives."
		},
		mission: {
			title: "Our Mission",
			p1: `Everyone deserves to live life to its fullest, to explore the outer edges of the universe, 
			to forge long-lasting memories and experiences with people, and to bloom into loving and 
			compassionate people. Everyone should be afforded these opportunities, and we believe that 
			it starts with a holistic balanced approach towards education, the education of one’s mind,
			one’s body, one’s soul, and one’s heart. This is only possible if everyone is invested in our 
			students’ future; this includes every member of our team, the students’ parents, and even the students themselves.`,
			p2: `At EXER INSTITUTE, we aim to provide a thorough researched-backed 
			comprehensive approach to our students’ future, because we believe that every moment counts, 
			and that every student’s time, energy, and effort are too precious to waste on unproven teaching 
			and guidance methods.`,
			p3: `We are here to revolutionize how learning is done and how education can be of 
			service to both the students and the general population. We acknowledge that this is a 
			monumental task and that such an endeavor will be met with many questions and hesitations. 
			Still, we are making a statement that our team’s very existence is to empower and 
			cultivate people’s lives.`,
			p4: `We are developing a wide array of subjects that allows everyone to 
			develop and improve their lives to their heart's content. Some of these subjects aren’t normally provided 
			in traditional schools, but we believe that they are required to keep true to our mission.`,
			p5: `At EXER INSTITUTE, we subscribe to the idea that everyone can fulfill their life’s 
			dreams, to do what they want to do, to become who they want to become, and to experience what 
			they want to experience. We want people’s lives to thrive, and we want to be there to help make this 
			happen. Through the highs and through the lows, we want to be there supporting our students.`,
			p6: `As they go through their life, we want our students to learn, believe, and 
			embody the idea that “my life is in my hands”.`
		}
	},
	uploadVideo: {
		information: {
			gathering: {
				title: "Gathering information - research",
				subheaderA: {
					text: "Identify the knowledge you want to share.",
					point1: {
						text: "Reasons why this is important:",
						point1: {
							text: "This will help restrict how much, or how little, knowledge you want to share."
						},
						point2: {
							text: "This will also help identify how much you know about what you're trying to teach."
						},
					},
					point2: {
						text: "Example:",
						point1: {
							text: "Topic: Learning",
							point1: {
								text: "What about learning?",
								point1: {
									text: "How we form knowledge in our mind."
								}
							},
							point2: {
								text: "Are there different ways that we form knowledge in our mind? If there are, am I going to talk about all of them, or some of them?",
								point1: {
									text: "Yes, there are, but I'm only going to talk about the procedural system, specifically its anatomy and how information flows through them."
								}
							}
						}
					}
				},
				subheaderB: {
					text: "Identify your audience.",
					point1: {
						text: "Reason why this is important:",
						point1: {
							text: "This can help restrict how detailed, or how simple, your content would be."
						}
					},
					point2: {
						text: "Example:",
						point1: {
							text: "People who already have knowledge of __ but not of __."
						},
						point2: {
							text: "Since I'm expecting my viewers to have some knowledge, or no knowledge, of __, then I will have to, or don't have to, explain the concept of __."
						}
					}
				},
				subheaderC: {
					text: "Organize your information - mental models.",
					point1: {
						text: "If possible, choose the following ways to organize your information:",
						point1: {
							text: "Logical narrative: one logic following another",
							point1: {
								text: "Easiest narrative to keep track of, since the learner just needs to keep track of how one logic connects to the next."
							},
							point2: {
								text: "Example:",
								point1: {
									text: "Cause and effect scenarios"
								},
								point2: {
									text: "Mathematical proofs"
								}
							}
						},
						point2: {
							text: "Eventual narrative",
							point1: {
								text: "Medium difficulty narrative to keep track of, since there's an ongoing queue of information payload going into the long-term memory.",
							},
							point2: {
								text: "Example:",
								point1: {
									text: "Historical events"
								},
								point2: {
									text: "Stories"
								},
								point3: {
									text: "Plays"
								}
							}
						},
						point3: {
							text: "Conceptual narrative: narrative is driven by the goal of explaining the overall idea by first explaining its parts.",
							point1: {
								text: "Hardest narrative to keep track of, since it uses the most of your working memory."
							},
							point2: {
								text: "Example: when explaining the concept behind force, you would need to explain the following",
								point1: {
									text: "Definition of force"
								},
								point2: {
									text: "How force is modeled via a formula"
								},
								point3: {
									text: "What the formula means"
								},
								point4: {
									text: "How the formula was derived"
								},
								point5: {
									text: "How and when to apply the formula"
								}
							}
						},
						point4: {
							text: "Reasons why this is important: ",
							point1: {
								text: "Chunking, which is a way of grouping information, is built into these 3 types of organization, and chunking allows information to easily move to our long-term memory."
							},
							point2: {
								text: "If you're information doesn't fit into any of these types of organization, do a little of research, or see for yourself, if there's a way to organize/chunk your information."
							}
						}
					},
					point2: {
						text: "Create a mental model based on how the information is organized.",
						point1: {
							text: "A mental model is an interconnected conceptual, usually visual, summary of your topic. It details how each fact connects to other facts to form an idea."
						},
						point2: {
							text: "A mental model can be, but isn't limited to being, a timeline, graph, a set of rules, or a mind map. Please note that a mental model is different from rules-of-thumbs and proverbs."
						},
						point3: {
							text: "Reasons why this is important:",
							point1: {
								text: "They serve as your viewers' reference, and summary, when they're reviewing your content."
							},
							point2: {
								text: "Mental models, or schemas, are the result of higher-learning and is most often observed from people who exhibit mastery within subject fields. Showing this to viewers allows them to move more efficiently towards mastery."
							}
						},
						point4: {
							text: "Example:",
							point1: {
								text: "The following is an example of the procedural system's components, what type of information flows through it, and the stages through which the information flows through."
							}
						}
					}
				}
			},
			writing: {
				title: "Writing the script",
				subheaderA: {
					text: "Briefly tell listeners what you'll talk about and how it breaks down.",
					point1: {
						text: "Reason why this is important:",
						point1: {
							text: "Preliminary overviews prime, or readies, our brain to recall information related to the topic, which also makes our brain more ready and alert to learn new information."
						}
					},
					point2: {
						text: "Example (using the topic about learning):",
						point1: {
							text: "For our procedural system, we'll talk about its components, what type of information that travels through them, and how information travels through them."
						}
					}
				},
				subheaderB: {
					text: "Create relevance to viewers.",
					point1: {
						text: "How can your viewers benefit from your knowledge? or"
					},
					point2: {
						text: "Why is it important to know this?"
					},
					point3: {
						text: "At what part of your video might you create relevance?",
						point1: {
							text: "You would most often do this at the beginning of your video."
						}
					},
					point4: {
						text: "Reason why this is important:",
						point1: {
							text: "This establishes a reason for the viewer to pay more attention, to reap the benefits from watching your video."
						}
					},
					point5: {
						text: "Example (using the example of learning):",
						point1: {
							text: "By understanding how information travels in our brain, we will see why certain things that we do, such as reviewing, is very important, and how other commonly used methods of teaching and learning can be further improved."
						}
					}
				},
				subheaderC: {
					text: "Keeping viewers engaged",
					point1: {
						text: "If possible, pose a question or a directive prompt",
						point1: {
							text: "This helps your viewers revisit their understanding of the topic and to further their involvement in your content."
						},
						point2: {
							text: "You would most often do this after providing an overview of what you're going to talk about."
						}
					},
					point2: {
						text: "Example (using the topic about learning):",
						point1: {
							text: "Why do you think it's important to understand how information travels in our brain in the context of learning?"
						}
					}
				},
				subheaderD: {
					text: "Include transitions.",
					point1: {
						text: "This helps prime your viewers' concentration to be more receptive to new information, by closing their focus from the previous topic. This also alleviates the cognitive load when topics suddenly change without warning, since this gives their brain some time to prepare for another conceptual context."
					},
					point2: {
						text: "Examples can be found here: https://writing.wisc.edu/handbook/style/transitions/"
					}
				},
				subheaderE: {
					text: "Include topic summaries.",
					point1: {
						text: "Reason why this is important:",
						point1: {
							text: "Summative statements allow us to reinforce and quickly review what was discussed and improve our brain's ability to store information from our medium- to our long-term memory."
						},
						point2: {
							text: "This is most often done at the end of a topic."
						}
					}
				},
				subheaderF: {
					text: "Writing in a friendly tone.",
					point1: {
						text: "Reason why this is important:",
						point1: {
							text: "Maintaining a conversational tone doesn't directly impose onto the listeners on what they should think and do, and, instead, creates a sense of community and familiarity. This feeling of being included, and thus being more engaged, is further supported by using words such as 'we' and 'our.'"
						}
					}
				},
				subheaderG: {
					text: "Keeping it brief",
					point1: {
						text: "Remove any information that the listener doesn't need to know.",
						point1: {
							text: "Example of this includes the year, author, and publisher of the source you're using, since this is included in the video's description section."
						}
					},
					point2: {
						text: "Try to keep the script's length to at most 10 minutes. If your video is longer, consider breaking it up into multiple parts."
					},
					point3: {
						text: "This also means that we should try to aim for non-wordy scripts; if you're able to say what you want in fewer words, while maintaining a conversation tone and pace, the better."
					},
					point4: {
						text: "Reasons why this is important: ",
						point1: {
							text: "Simplifying your information delivery improves the chances of remembering the information, since the listener is freed from informational noise."
						},
						point2: {
							text: "Your viewers clicked your video based on what was indicated on your title; they might not care for any more information than that."
						}
					}
				},
				subheaderH: {
					text: "Focus on the why and the how instead of the what",
					point1: {
						text: "Reason why this is important",
						point1: {
							text: "Teaching why things behave the way they do and how things work is a lot more transferrable and useful in many contexts, than just focusing on facts."
						}
					}
				}
			}
		},
		delivery: {
			connecting: {
				title: "Tone, body language, & connecting to your viewers",
				subheaderA: {
					text: "Be authentic. Express yourself in a way that you would normally express yourself in a conversation.",
					point1: {
						text: "Reason why this is important:",
						point1: {
							text: "You're easier to relate to; you feel human. This helps erode the usual barrier between a teacher and a student."
						}
					}
				},
				subheaderB: {
					text: "Show some passion, excitement, and energy.",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "The presenter's energy subconsciously transfers to the viewers. This helps the viewers feel more engaged in your content. Conversely, if you're projecting low energy, your viewers can easily tune out and get bored."
						}
					}
				},
				subheaderC: {
					text: "Be mindful of your tone.",
					point1: {
						text: "Reason why this is important: ",
						point1: { 
							text: "The entire meaning behind your words can change depending on your tone of voice."
						}
					}
				},
				subheaderD: {
					text: "Be mindful of your pacing.",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "Your listeners are learning something completely new. If you talk too fast, it's hard for their working memory to keep up with all the new information."
						}
					}
				},
				subheaderE: {
					text: "Be mindful of what you wear.",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "Looking clean and professional can implicitly make you more credible."
						}
					}
				},
				subheaderF: {
					text: "Create a balance of showing yourself and the content.",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "Viewers want to feel connected to you, and this will help viewers stay engaged."
						}
					}
				}
			},
			production: {
				title: "Video production - less is more",
				subheaderA: {
					text: "Images and videos",
					point1: {
						text: "Consider prioritizing still images over moving images, such as movie/video clips.",
						point1: {
							text: "Reasons why this is important: ",
							point1: {
								text: "Viewing short clips places additional cognitive load to our brain compared to viewing still images, since our brain is prompted to interpret what is happening, what is about to happen, and to keep track of the many moving objects within the clip; this is more difficult compared to just interpreting what is in the picture."
							},
							point2: {
								text: "We want the viewers to concentrate on the concepts being explained."
							}
						},
						point2: {
							text: "Exception: ",
							point1: {
								text: "The clip effectively explains the interconnected concepts and presents them in a structured/organized manner, while not containing many distracting events.",
								point1: {
									text: "Seeing how everything connects allows viewers to more easily create knowledge chunks, which assists in solidifying information in their long-term memory."
								}
							}
						}
					},
					point2: {
						text: "Consider regularly transitioning what you are showing at least every 7 - 10 seconds.",
						point1: {
							text: "Reasons why this is important: ",
							point1: {
								text: "This is a very easy way to keep your viewers engaged, since you're consistently stimulating their subconscious need to make sense of what they're seeing."
							}
						},
						point2: {
							text: "Exception:",
							point1: {
								text: "Your explanation, especially if it involves equations, requires you to show the same screen for an extended period of time."
							}
						}
					}
				},
				subheaderB: {
					text: "Camera shots",
					point1: {
						text: "How close the camera should be zoomed into the speaker depends on what concept is being emphasized and what effect is the speaker aiming for.",
						point1: {
							text: "If the concept is important, or is the video's core idea, or the speaker is placing extra emphasis on it, consider doing a head shot.",
							point1: {
								text: "Reason why this is important: ",
								point1: {
									text: "It gives the perception that the listener is physically closer to the speaker, which primes the brain to focus more."
								}
							}
						},
						point2: {
							text: "For anything else, do a range between mid-waist up to should up shot.",
							point1: {
								text: "Reason why this is important: ",
								point1: {
									text: "It gives listeners the impression that they're maintaining a normal and comfortable distance between them and the speaker."
								}
							}
						}
					}
				},
				subheaderC: {
					text: "Music",
					point1: {
						text: "Minimize, or remove, background music, unless they contribute to the learning.",
						point1: {
							text: "Reason why this is important: ",
							point1: {
								text: "They can be distracting, which ultimately makes it harder for viewers to focus on what you're trying to explain."
							}
						}
					}
				},
				subheaderD: {
					text: "Background",
					point1: {
						text: "Minimize, or remove, background objects, unless they contribute to the learning, aren't distracting, and help create a relaxed learning environment."
					}
				},
				subheaderE: {
					text: "Intellectual property",
					point1: {
						text: "Include an invisible watermark on your videos to help protect against plagiarism, copyright theft, and unauthorized sharing or re-distribution.",
						point1: {
							text: "Reason why this is important: ",
							point1: {
								text: "Don't use a visible watermark, since they can be easily removed."
							}
						}
					}
				}
			}
		},
		viewers: {
			title: {
				title: "Deciding on a title",
				subheaderA: {
					text: "Phrase the title into a question, or statement.",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "This immediately signals to your viewers if your topic matters to them."
						}
					}
				},
				subheaderB: {
					text: "No clickbait titles",
					point1: {
						text: "Reason why this is important: ",
						point1: {
							text: "Not only will this disappoint your viewers, but it will also hurt your reputation for future videos."
						}
					}
				}
			},
			subheading: {
				title: "Deciding on a sub-heading (Optional)",
				subheaderA: {
					text: "This can be used to further limit what exactly about the topic you'll be talking about.",
					point1: {
						text: "Example: ",
						point1: {
							text: "The Mind: How Our Physiology Affects How We Learn"
						}
					}
				}
			},
			thumbnail: {
				title: "Deciding on a thumbnail",
				subheaderA: {
					text: "Requirements:",
					point1: {
						text: "Dimensions: Ideally 1280 (width) x 720 (height) pixels, with a minimum width of 640 pixels."
					},
					point2: {
						text: "Size: Maximum 2MB"
					},
					point3: {
						text: "Image format: JPG, PNG, WebP"
					}
				},
				subheaderB: {
					text: "Suggestions:",
					point1: {
						text: "Avoid placing anything important in the lower right-hand corner. This'll be used for timestamps."
					},
					point2: {
						text: "The thumbnail should visually tell the story of what your video is about."
					},
					point3: {
						text: "Can be an action shot"
					},
					point4: {
						text: "Use bright backgrounds.",
						point1: {
							text: "Bright backgrounds appeal to the eye and is more eye catching."
						}
					},
					point5: {
						text: "Use contrast and outline.",
						point1: {
							text: "Contrast and outlines allow for the video's subject to stand out more against the background."
						}
					}
				}
			}
		},
		accessibility: {
			subtitles: {
				title: "Please burn in your subtitles into your videos",
				subheaderA: {
					text: "Reason why this is important:",
					point1: {
						text: "Providing subtitles would allow deaf people to learn what you're teaching."
					},
					point2: {
						text: "We want to build a community where everyone has equal access to quality educational content. If you have ideas to make the content more accessible, please let us know in exerinstitute@gmail.com"
					}
				},
			},
			transcoding: {
				title: "Video transcoding",
				subheaderA: {
					text: "Please transcode your video using a codec",
					point1: {
						text: "Reasons why this is important:",
						point1: {
							text: "There is a file size limit of 128GB"
						},
						point2: {
							text: "Using a codec compresses your video file, which makes loading your videos a lot quicker; this improves your viewer's experience when viewing your video."
						}
					}
				},
				subheaderB: {
					text: "The following is the only video container we support:"
				},
				subheaderC: {
					text: "The following are the only codecs we support:"
				}
			},
			audioTranscoding: {
				title: "Transcode your audio files",
				subheaderA: {
					text: "The same reasons apply stated when transcoding your videos"
				}
			}
		},
		miscellaneous: {
			accuracy: {
				title: "Being accurate in your video title and content",
				subheaderA: {
					text: "Are they based on research, or on your assumptions?"
				},
				subheaderB: {
					text: "If they are based on your assumptions, state those assumptions, since viewers might think that such statements are fundamental truths, even though they're really not."
				},
				subheaderC: {
					text: "Examples of misleading titles:",
					point1: {
						text: "How to be a Genius Overnight!"
					},
					point2: {
						text: "How This Single Method Will Make You a Learning Machine!"
					}
				},
				subheaderD: {
					text: "Reasons why this is important: ",
					point1: {
						text: "It provides the false expectation that such sensational statements can actually be done. Even if they can be done, sensational words can often stray from objective facts, which might then lead people to question the validity of such statements and the overall credibility of one's content."
					},
					point2: {
						text: "We're here to help people improve themselves through a rigorous approach of properly presenting sound and accurate information, not to mislead and waste their time."
					}
				}
			},
			analogies: {
				title: "Analogies",
				subheaderA: {
					text: "Be mindful of your analogies, if you're using them, since analogies assumes that the similarities between the concepts and the example you're using for the analogy is clear for every viewer.",
					point1: {
						text: "Analogies are useful up to a certain point. Consider explaining this point."
					}
				}
			},
			teachingMethod: {
				title: "Depending on your topic, consider whether the topic should be explained in a declarative way, or procedural way, or both, and to what amount for each topic. We should always strive for both, but it can sometimes be impractical to do so.",
				subheaderA: {
					text: "Definitions: ",
					point1: {
						text: "Declarative teaching is a way of focusing on the facts, or concepts, and how each of these connect to form an idea."
					},
					point2: {
						text: "Procedural teaching is a way of focusing on the process to accomplish a goal."
					}
				},
				subheaderB: {
					text: "Example:",
					point1: {
						text: "If I'm teaching someone how to play a basketball, I would focus more on how one should dribble, pass, and shoot by doing a demonstration and having my students try to do it themselves; this is procedural teaching. If I were to teach more declaratively, I would focus on describing how one should dribble, pass, and shoot, as opposed to actually showing it."
					}
				},
				subheaderC: {
					text: "Reason why this is important: ",
					point1: {
						text: "This is mainly to save time and resources for the teacher from having to use an ineffective teaching method."
					}
				}
			}
		}
	},
	privacyPolicy: {
		title: "Privacy Policy",
		header: {
			date: "Last updated: June 07, 2020",
			privacy: `This Privacy Policy describes Our
			policies and procedures on the collection, use and disclosure of Your
			information when You use the Service and tells You about Your privacy
			rights and how the law protects You.`,
			data: `We use Your Personal data to provide
			and improve the Service. By using the Service, You agree to the
			collection and use of information in accordance with this Privacy
			Policy.`
		},
		section1: {
			title: "Interpretation and Definitions",
			subheaderA: {
				text: "Interpretation",
				point1: {
					text: "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
				}
			},
			subheaderB: {
				text: "Definitions",
				point1: {
					text: "For the purposes of this Privacy Policy:"
				},
				point2: {
					term: "You",
					text: " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service."
				},
				point3: {
					term: "Company",
					text: " (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to EXER INSTITUTE, 96 Washington Square Dr. Ca 95035. For the purpose of the GDPR, the Company is the Data Controller."
				},
				point4: {
					term: "Affiliate",
					text: " means an entity that controls, is controlled by or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."
				},
				point5: {
					term: "Account",
					text: " means a unique account created for You to access our Service or parts of our Service."
				},
				point6: {
					term: "Website",
					text: " refers to EXER INSTITUTE, accessible from"
				},
				point7: {
					term: "Service",
					text: " refers to the Website."
				},
				point8: {
					term: "Country",
					text: " refers to: California, United States"
				},
				point9: {
					term: "Service Provider",
					text: " means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors."
				},
				point10: {
					term: "Third-party Social Media Service",
					text: " refers to any website or any social network website through which a User can log in or create an account to use the Service."
				},
				point11: {
					term: "Facebook Fan Page",
					text: " is a public profile named EXER INSTITUTE specifically created by the Company on the Facebook social network, accessible from"
				},
				point12: {
					term: "Personal Data",
					text: " is any information that relates to an identified or identifiable individual. For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You."
				},
				point13: {
					term: "Cookies",
					text: " are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses."
				},
				point14: {
					term: "Device",
					text: " means any device that can access the Service such as a computer, a cellphone or a digital tablet."
				},
				point15: {
					term: "Usage Data",
					text: " refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
				},
				point16: {
					term: "Data Controller",
					text: ", for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data."
				},
				point17: {
					term: "Do Not Track",
					text: " (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites."
				},
				point18: {
					term: "Business",
					text: ", for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California."
				},
				point19: {
					term: "Consumer",
					text: ", for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose."
				},
				point20: {
					term: "Sale",
					text: ", for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's Personal information to another business or a third party for monetary or other valuable consideration."
				}
			}
		},
		section2: {
			title: "What information we collect and how we use it",
			subheaderA: {
				text: "Types of Data Collected",
				point1: {
					text: "Personal Data",
					point1: {
						text: "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
						point1: {
							text: "Email address"
						},
						point2: {
							text: "First name and last name"
						},
						point3: {
							text: "Phone number"
						},
						point4: {
							text: "Address, State, Province, ZIP/Postal code, City"
						},
						point5: {
							text: "Usage Data"
						}
					}
				},
				point2: {
					text: "Usage Data",
					point1: {
						text: "Usage Data is collected automatically when using the Service."
					},
					point2: {
						text: "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data."
					},
					point3: {
						text: "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data."
					},
					point4: {
						text: "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device."
					}
				},
				point3: {
					text: "Tracking Technologies and Cookies",
					point1: {
						text: "We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service."
					},
					point2: {
						text: "You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service."
					},
					point3: {
						text: "Cookies can be \"Persistent\" or \"Session\" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies:",
						link: "All About Cookies"
					},
					point4: {
						text: "We use both session and persistent Cookies for the purposes set out below:",
						subsection1: {
							title: "Necessary / Essential Cookies",
							point1: {
								text: "Type: Session Cookies"
							},
							point2: {
								text: "Administered by: Us"
							},
							point3: {
								text: "Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services."
							}
						},
						subsection2: {
							title: "Cookies Policy / Notice Acceptance Cookies",
							point1: {
								text: "Type: Persistent Cookies"
							},
							point2: {
								text: "Administered by: Us"
							},
							point3: {
								text: "Purpose: These Cookies identify if users have accepted the use of cookies on the Website."
							}
						},
						subsection3: {
							title: "Functionality Cookies",
							point1: {
								text: "Type: Persistent Cookies"
							},
							point2: {
								text: "Administered by: Us"
							},
							point3: {
								text: "Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website."
							}
						},
						subsection4: {
							title: "Tracking and Performance Cookies",
							point1: {
								text: "Type: Persistent Cookies"
							},
							point2: {
								text: "Administered by: Third-Parties"
							},
							point3: {
								text: "Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them."
							}
						}
					},
					point5: {
						text: "For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy."
					}
				}
			},
			subheaderB: {
				text: "Usage of Your Personal Data",
				point1: {
					text: "The Company may use Personal Data for the following purposes:",
					point1: {
						term: "To provide and maintain our Service",
						text: " , including to monitor the usage of our Service."
					},
					point2: {
						term: "To manage Your Account:",
						text: " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user."
					},
					point3: {
						term: "For the performance of a contract:",
						text: " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service."
					},
					point4: {
						term: "To contact You:",
						text: " To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation."
					},
					point5: {
						term: "To provide You",
						text: " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information."
					},
					point6: {
						term: "To manage Your requests:",
						text: " To attend and manage Your requests to Us."
					}
				},
				point2: {
					text: "We may share your personal information in the following situations:",
					point1: {
						term: "With Service Providers:",
						text: " We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You."
					},
					point2: {
						term: "For Business transfers:",
						text: " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company."
					},
					point3: {
						term: "With Affiliates:",
						text: " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."
					},
					point4: {
						term: "With Business partners:",
						text: " We may share Your information with Our business partners to offer You certain products, services or promotions."
					},
					point5: {
						term: "With other users:",
						text: " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile."
					}
				}
			},
			subheaderC: {
				text: "Retention of Your Personal Data",
				point1: {
					text: "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies."
				},
				point2: {
					text: "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods."
				}
			},
			subheaderD: {
				text: "Transfer of Your Personal Data",
				point1: {
					text: "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction."
				},
				point2: {
					text: "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer."
				},
				point3: {
					text: "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information."
				}
			},
			subheaderE: {
				text: "Disclosure of Your Personal Data",
				point1: {
					text: "Business Transactions",
					point1: {
						text: "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy."
					}
				},
				point2: {
					text: "Law enforcement",
					point1: {
						text: "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)."
					}
				},
				point3: {
					text: "Other legal requirements",
					point1: {
						text: "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:",
						point1: {
							text: "Comply with a legal obligation"
						},
						point2: {
							text: "Protect and defend the rights or property of the Company"
						},
						point3: {
							text: "Prevent or investigate possible wrongdoing in connection with the Service"
						},
						point4: {
							text: "Protect the personal safety of Users of the Service or the public"
						},
						point5: {
							text: "Protect against legal liability"
						}
					}
				}
			},
			subheaderF: {
				text: "Security of Your Personal Data",
				point1: {
					text: "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security."
				}
			}
		},
		section3: {
			title: "Detailed Information on the Processing of Your Personal Data",
			subpoint: {
				text: "Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose."
			},
			point1: {
				text: "Analytics",
				point1: {
					text: "We may use third-party Service providers to monitor and analyze the use of our Service.",
					point1: {
						text: "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network."
					},
					point2: {
						text: "You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity."
					},
					point3: {
						text: "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:"
					}
				}
			},
			point2: {
				text: "Email Marketing",
				point1: {
					text: "We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us."
				},
				point2: {
					text: "We may use Email Marketing Service Providers to manage and send emails to You.",
					point1: {
						text: "Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC."
					},
					point2: {
						text: "For more information on the privacy practices of Mailchimp, please visit their Privacy policy:"
					}
				}
			},
			point3: {
				text: "Payments",
				point1: {
					text: "We provide paid products and services within the Service. We use third-party services for payment processing (e.g. payment processors)."
				},
				point2: {
					text: `We will store or collect a reference in a form of an ID of Your preferred payment method for future use. We will not store or collect Your payment card details, which means we will not store your credit card number, 
						security code, and expiration date. Your credit card details are provided directly to Our third-party payment processors whose use of Your personal information is governed by their 
						Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, 
						American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.`,
					point1: {
						text: "Their Privacy Policy can be viewed at "
					},
					point2: {
						text: "Their Privacy Policy can be viewed at "
					}
				}
			},
			point4: {
				text: "Usage, Performance and Miscellaneous",
				point1: {
					text: "We may use third-party Service Providers to provide better improvement of our Service.",
					point1: {
						text: "Invisible reCAPTCHA"
					},
					point2: {
						text: "We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google."
					},
					point3: {
						text: "The reCAPTCHA service may collect information from You and from Your Device for security purposes."
					},
					point4: {
						text: "The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: "
					}
				}
			}
		},
		section4: {
			title: "GDPR Privacy",
			point1: {
				text: "Legal Basis for Processing Personal Data under GDPR",
				point1: {
					text: "We may process Personal Data under the following conditions:",
					point1: {
						term: "Consent:",
						text: " You have given Your consent for processing Personal Data for one or more specific purposes."
					},
					point2: {
						term: "Performance of a contract:",
						text: " Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof."
					},
					point3: {
						term: "Legal obligations:",
						text: " Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject."
					},
					point4: {
						term: "Vital interests:",
						text: " Processing Personal Data is necessary in order to protect Your vital interests or of another natural person."
					},
					point5: {
						term: "Public interests:",
						text: " Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company."
					},
					point6: {
						term: "Legitimate interests:",
						text: " Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company."
					}
				},
				point2: {
					text: "In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract."
				}
			},
			point2: {
				text: "Your Rights under the GDPR",
				point1: {
					text: "The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights."
				},
				point2: {
					text: "You have the right under this Privacy Policy, and by law if You are within the EU, to:",
					point1: {
						term: "Request access to Your Personal Data.",
						text: " The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You."
					},
					point2: {
						term: "Request correction of the Personal Data that We hold about You.",
						text: " You have the right to to have any incomplete or inaccurate information We hold about You corrected."
					},
					point3: {
						term: "Object to processing of Your Personal Data.",
						text: " This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes."
					},
					point4: {
						term: "Request erasure of Your Personal Data.",
						text: " You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it."
					},
					point5: {
						term: "Request the transfer of Your Personal Data.",
						text: " We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You."
					},
					point6: {
						term: "Withdraw Your consent.",
						text: " You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service."
					}
				}
			},
			point3: {
				text: "Exercising of Your GDPR Data Protection Rights",
				point1: {
					text: "You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible."
				},
				point2: {
					text: "You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA."
				}
			}
		},
		section5: {
			title: "Facebook Fan Page",
			point1: {
				text: "Data Controller for the Facebook Fan Page",
				point1: {
					text: "The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page",
					text1: ", the Company and the operator of the social network Facebook are Joint Controllers."
				},
				point2: {
					text: "The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service:"
				},
				point3: {
					text: "Visit the Facebook Privacy Policy",
					text1: " for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States."
				}
			},
			point2: {
				text: "Facebook Insights",
				point1: {
					text: "We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users."
				},
				point2: {
					text: "For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period."
				},
				point3: {
					text: "Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services."
				},
				point4: {
					text: "For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here:"
				}
			}
		},
		section6: {
			title: "CCPA Privacy",
			point1: {
				text: "Your Rights under the CCPA",
				point1: {
					text: "Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:",
					point1: {
						term: "The right to notice.",
						text: " You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used."
					},
					point2: {
						term: "The right to access / the right to request.",
						text: "The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party's direct marketing purposes."
					},
					point3: {
						term: "The right to say no to the sale of Personal Data.",
						text: " You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our \"Do Not Sell My Personal Information\" section or web page."
					},
					point4: {
						term: "The right to know about Your Personal Data.",
						text: " You have the right to request and obtain from the Company information regarding the disclosure of the following: ",
						point1: {
							text: "The categories of Personal Data collected"
						},
						point2: {
							text: "The sources from which the Personal Data was collected"
						},
						point3: {
							text: "The business or commercial purpose for collecting or selling the Personal Data"
						},
						point4: {
							text: "Categories of third parties with whom We share Personal Data"
						},
						point5: {
							text: "The specific pieces of Personal Data we collected about You"
						}
					},
					point5: {
						term: "The right to delete Personal Data.",
						text: " You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months."
					},
					point6: {
						term: "The right not to be discriminated against.",
						text: " You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by: ",
						point1: {
							text: "Denying goods or services to You"
						},
						point2: {
							text: "Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties"
						},
						point3: {
							text: "Providing a different level or quality of goods or services to You"
						},
						point4: {
							text: "Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services."
						}
					}
				}
			},
			point2: {
				text: "Exercising Your CCPA Data Protection Rights",
				point1: {
					text: "In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us."
				},
				point2: {
					text: "The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice."
				}
			},
			point3: {
				text: "Do Not Sell My Personal Information",
				subpoint1: {
					text: "We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that \"sells\" personal information as defined by the CCPA law."
				},
				subpoint2: {
					text: "If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below."
				},
				subpoint3: {
					text: "Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use."
				},
				point1: {
					text: "Website",
					point1: {
						text: "You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:",
						point1: {
							text: "From Our \"Cookie Consent\" notice banner"
						},
						point2: {
							text: "Or from Our \"CCPA Opt-out\" notice banner"
						},
						point3: {
							text: "Or from Our \"Do Not Sell My Personal Information\" notice banner"
						},
						point4: {
							text: "Or from Our \"Do Not Sell My Personal Information\" link"
						}
					},
					point2: {
						text: "The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again."
					}
				},
				point2: {
					text: "Mobile Devices",
					point1: {
						text: "Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:",
						point1: {
							text: "\"Opt out of Interest-Based Ads\" or \"Opt out of Ads Personalization\" on Android devices"
						},
						point2: {
							text: "\"Limit Ad Tracking\" on iOS devices"
						}
					},
					point2: {
						text: "You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device."
					}
				}
			}
		},
		section7: {
			title: "\"Do Not Track\" Policy as Required by California Online Privacy Protection Act (CalOPPA)",
			point1: {
				text: "Our Service does not respond to Do Not Track signals."
			},
			point2: {
				text: "However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser."
			}
		},
		section8: {
			title: "Children's Privacy",
			subpoint1: {
				text: "The Service may contain content appropriate for children under the age of 13. As a parent, you should know that through the Service children under the age of 13 may participate in activities that involve the collection or use of personal information. We use reasonable efforts to ensure that before we collect any personal information from a child, the child's parent receives notice of and consents to our personal information practices."
			},
			subpoint2: {
				text: "We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these Users. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information."
			},
			subpoint3: {
				text: "We may ask a User to verify its date of birth before collecting any personal information from them. If the User is under the age of 13, the Service will be either blocked or redirected to a parental consent process."
			},
			point1: {
				text: "Information Collected from Children Under the Age of 13",
				point1: {
					text: "The Company may collect and store persistent identifiers such as cookies or IP addresses from Children without parental consent for the purpose of supporting the internal operation of the Service."
				},
				point2: {
					text: "We may collect and store other personal information about children if this information is submitted by a child with prior parent consent or by the parent or guardian of the child."
				},
				point3: {
					text: "The Company may collect and store the following types of personal information about a child when submitted by a child with prior parental consent or by the parent or guardian of the child:",
					point1: {
						text: "First and/or last name"
					},
					point2: {
						text: "Date of birth"
					},
					point3: {
						text: "Gender"
					},
					point4: {
						text: "Grade level"
					},
					point5: {
						text: "Email address"
					},
					point6: {
						text: "Telephone number"
					},
					point7: {
						text: "Parent's or guardian's name"
					},
					point8: {
						text: "Parent's or guardian's email address"
					}
				},
				point4: {
					text: "For further details on the information We might collect, You can refer to the \"Types of Data Collected\" section of this Privacy Policy. We follow our standard Privacy Policy for the disclosure of personal information collected from and about children."
				}
			},
			point2: {
				text: "Parental Access",
				point1: {
					text: "A parent who has already given the Company permission to collect and use his child personal information can, at any time:",
					point1: {
						text: "Review, correct or delete the child's personal information"
					},
					point2: {
						text: "Discontinue further collection or use of the child's personal information"
					}
				},
				point2: {
					text: "To make such a request, You can write to Us using the contact information provided in this Privacy Policy."
				}
			}
		},
		section9: {
			title: "Your California Privacy Rights (California's Shine the Light law)",
			point1: {
				text: "Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes."
			},
			point2: {
				text: "If you'd like to request more information under the California Shine the Light law, and if you are a California resident, You can contact Us using the contact information provided below."
			}
		},
		section10: {
			title: "California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)",
			point1: {
				text: "California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted."
			},
			point2: {
				text: "To request removal of such data, and if you are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account."
			},
			point3: {
				text: "Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances."
			}
		},
		section11: {
			title: "Links to Other websites",
			point1: {
				text: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit."
			},
			point2: {
				text: "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
			}
		},
		section12: {
			title: "Changes to this Privacy Policy",
			point1: {
				text: "We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page."
			},
			point2: {
				text: "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy."
			},
			point3: {
				text: "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
			}
		},
		section13: {
			title: "Contact Us",
			point1: {
				text: "If you have any questions about this Privacy Policy, You can contact us:"
			},
			point2: {
				text: "By email: support@exerinstitute.com"
			},
			point3: {
				text: "By visiting this page on our website: "
			}
		}
	},
	teacherPolicy: {
		title: "Tutor Policy",
		header: {
			date: "Last updated: December 1, 2024",
			toTeach1: `If you intend to offer Tutoring Services on the Exer Institute Platform, you must read and agree to be bound by these policies for Tutors ("`,
			toTeach2: "Tutor Policy",
			toTeach3: `"). Terms that are capitalized but not defined herein shall have the meanings ascribed to them in the Exer Institute's Terms of Service, which can be accessed at `,
			toTeach4: "https://exerinstitute.com/terms-and-conditions",
			toTeach5: " (the \"",
			toTeach6: "Exer Institute Terms",
			toTeach7: "\").",
			unauthorizedUse: `Should you not fully accept this Tutor Policy, you are not authorized to provide Tutoring Services on the Exer Institute Platform and will not be eligible to receive any benefits from Exer Institute for any Tutoring Services rendered.`,
			policyGoal: "This Tutor Policy aims to uphold the highest standards of conduct among Tutors in their interactions with Tutees by establishing clear expectations. It is intended to guide Tutors in their daily conduct when providing Tutor Services and engaging with Tutees. Recognizing that our Tutors hail from diverse cultural backgrounds, this Tutor Policy endeavors to adopt universally accepted standards of tolerance and respect. While it does not address every possible situation that may arise in Tutor-Tutee interactions on the Exer Institute Platform, it provides principles of ethical and professional behavior to guide Tutors in aligning with Exer Institute's expectations."
		},
		section1: {
			title: "Tutor Application and Service Outline",
			point1: {
				text: "Tutor Application",
				point1: {
					text1: `Hover over your profile icon and click 'Profile Settings'. Then choose 'Tutoring' on the left-hand 
						menu and click the 'Become a tutor' button. Complete and submit the form, including any required files, through 
						the Exer Institute Platform (“`,
					text2: "Tutor Application",
					text3: "”) to become a “",
					text4: "Tutor",
					text5: `”. Exer Institute will review your Tutor Application for approval. If approved, you will be able to create and manage a Tutor profile on the Exer Institute Platform and commence providing Tutor Services,” including conducting and offering paid sessions (“`,
					text6: "Session",
					text7: "”) to Tutees."
				},
				point2: {
					text: `To the extent permitted by applicable law, Exer Institute may obtain (i) background check reports from public records or through third-party vendors concerning matters such as criminal convictions or sex offender registrations, and/or (ii) identity verification check reports. As a condition of applying to become a Tutor, you agree to and authorize the use of your personal information, such as your full name and date of birth, to obtain such reports. Furthermore, you agree to and authorize the provision of a copy of the official identification you submitted to a third-party vendor to obtain such identity verification check reports.`
				}
			},
			point2: {
				text: "Tutor Services Outline",
				point1: {
					text: `Tutors need to create a description of their Tutor Services on the Exer Institute Platform. The service description needs to abide by the following:`
				},
				point2: {
					text: `You need to provide a description of your tutoring services, including the structure of your Sessions.`
				},
				point3: {
					text: `You need to be able to meet the expectations indicated in the description.`
				},
				point4: {
					text: `You do not advertise third-party tutoring platforms, services, software, or applications.`
				},
				point5: {
					text: `You do not offer services with the intent to commit fraud or services in exchange for beneficial feedback or at no cost.`
				},
				point6: {
					text: `You do not include personal contact information, including but not limited to email addresses, telephone numbers, or social media account details.`
				}
			}
		},
		section2: {
			title: "Guidelines for Tutor Services",
			point1: {
				text: "Tutor Service Request",
				point1: {
					text: "When receiving a Tutor Service request, you agree to abide by the following:"
				},
				point2: {
					text: "Utilize the Exer Institute Platform to promptly respond to all Tutor Service requests from Tutees."
				},
				point3: {
					text: "Responding to Tutor Service requests includes accepting or declining a request, or proposing a new date/time through chat."
				},
				point4: {
					text: `To help protect you in case of disputes, please maintain a record of communications between you and the Tutee. 
						The record should contain your availability to provide any Tutor Services, your feedback to the Tutee, any 
						schedule changes, any delays during the Session, and the completion of Sessions. Additionally, you can choose 
						to download the Zoom Session's recording. The recording and access to Zoom whiteboard gets automatically deleted 
						and removed after 30 days from the Session.`
				}
			},
			point2: {
				text: "Session Policy",
				point1: {
					text: "Tutors must abide by the following when providing a Session:"
				},
				point2: {
					text1: "Commitment to Scheduled Sessions: ",
					text2: `A confirmed Session is a binding commitment with a Tutee. Only cancel or reschedule as a last resort and after communicating with your Tutee. If you will be unavailable for a certain period, update your availability on your Exer Institute Account profile and notify your Tutees of your absence.`
				},
				point3: {
					text1: "Resolving Disputes: ",
					text2: `In case of a dispute between Tutor and Tutee, Exer Institute will request both parties to provide evidence containing all relevant information, which includes but is not limited to dates, times, and other communication details as indicated in Section Tutor Service Request.`
				},
				point4: {
					text1: "Punctuality: ",
					text2: "Begin all Sessions at the scheduled time, and initiate contact with the Tutee, especially for first-time Tutees."
				},
				point5: {
					text1: "Pre-Session Connection: ",
					text2: "Communicate with the Tutee on the Exer Institute platform before the Session's scheduled start time. Avoid last-minute connections."
				},
				point6: {
					text1: "Late Tutees: ",
					text2: `If a Tutee is late for a scheduled Session, wait for 5 minutes from the scheduled start time. The Tutor is not obligated to compensate for lost time due to the Tutee's late arrival.`
				},
				point7: {
					text1: "Ending Sessions on Time: ",
					text2: `Politely inform Tutees when their session is nearing its end by reminding them before the Session time concludes. You may courteously explain that you need to prepare for an upcoming Session with another Tutee.`
				},
				point8: {
					text1: "Rescheduling Due to Tutee Absence: ",
					text2: `If a Tutee fails to attend a Session and requests to reschedule, it is at the Tutor's discretion to accept or decline the request.`
				},
				point9: {
					text1: "15-minute trial: ",
					text2: `If you indicate in your profile that you allow 15-minute trials, tutees that you never tutored in 
						the past will automatically get the first 15 minutes discounted from the total cost.`
				}
			},
			point3: {
				text: "Rescheduling and Cancelling Policy",
				point1: {
					text: `If you are unable to provide your Tutor Services to the Tutee at the previously agreed time, you agree to immediately explain to the Tutee regarding your reasons for being unable to provide the Tutor Services at the scheduled time. If an emergency prevents you from informing the Tutee in advance, you are required to provide an explanation to the Tutee as soon as possible.`
				},
				point2: {
					text: `A Tutor may cancel or reschedule any scheduled Tutor Service more than 24 hours before the scheduled start time, without needing to provide consent from the Tutee. All cancellations or rescheduling must be handled through the Exer Institute Platform.`
				}
			},
			point4: {
				text: "Payment and Feedback",
				point1: {
					text: "After providing your Tutor Services, you agree to abide by the following:"
				},
				point2: {
					text: "Utilize the Exer Institute Payment Service for handling all financial transactions related to your Tutor Services."
				},
				point3: {
					text: "Provide objective and helpful feedback for the Tutee."
				},
				point4: {
					text: `Every time a tutee hires you for a tutoring session, you receive a charge on your Stripe Connect account. 
						Stripe pays out charges to your Connect account on a rolling basis, usually daily. Please allow up to 7 business 
						days for pay outs to come through after a tutoring session finishes. For risk mitigation, your first payout can take
						7 - 14 business days. If you still have not received your payout after 14 days, please contact support@exerinstitute.com.`
				},
				point5: {
					text: `Depending on the number of Sessions you have logged and whether the Tutee is a platform subscriber, you can earn 
						between 85% to 95% of your posted rate. If the Tutee is a platform subscriber, you would receive more of your posted rate.`
				},
				point6: {
					text: `There are three payment tiers and each tier is associated with a better Tutor payout. Tutors advance from one 
						tier to the next by logging more Sessions. Tutors advance to tier 2 after finishing 75 Sessions. Tutors advance to tier 3
						after finishing a total of 200 Sessions (this is includes the previously finished 75 Sessions).`
				}
			},
			point5: {
				text: "Additional Guidelines",
				point1: {
					text: "You also agree to abide by the following additional guidelines:"
				},
				point2: {
					text: `Uphold the privacy of Exer Institute Tutees per the Exer Institute Privacy Policy by not requesting any personal information from Tutees;`
				},
				point3: {
					text: "Uphold the privacy of your own personal information;"
				},
				point4: {
					text: "Save all invoice and payment records relating to your Tutor Services;"
				},
				point5: {
					text: "Assist Exer Institute in resolving any disputes;"
				},
				point6: {
					text: "Report any violations of the Exer Institute Terms or of Exer Institute Policies."
				}
			}
		},
		section3: {
			title: "Service Standards",
			point1: {
				text: "Be Respectful",
				point1: {
					text: "Given that Tutees come from diverse backgrounds, it is important to be mindful and respectful of their learning difficulties."
				},
				point2: {
					text: "Do not demean, berate, yell at, or insult a Tutee."
				},
				point3: {
					text: "Do not use offensive language with a Tutee."
				},
				point4: {
					text: "Maintain composure with your Tutee."
				}
			},
			point2: {
				text: "Be Professional",
				point1: {
					text: `Tutors must proactively communicate with and manage Tutees. In addition to adhering to the Tutor Services guidelines described in Section 2 (Tutor Services Guidelines), Tutors are expected to provide Tutor Services in a professional manner, which includes:`
				},
				point2: {
					text1: `Responsiveness: `,
					text2: `When receiving a Tutor Service request from a Tutee, you should respond within 48 hours or before the start time proposed by the Tutee, whichever is sooner. You will receive notifications through the Exer Institute Platform when a Tutee sends you a message or requests a Tutor Service. We also recommend that you regularly check your Exer Institute Account to check and respond to messages and Tutor Service requests.`
				},
				point3: {
					text1: "Schedule Management: ",
					text2: `Ensure that your schedule and status reflect your Tutor Services availability. If you plan to take time off from providing Tutor Services, update your schedule accordingly.`
				},
				point4: {
					text1: "Tutee Privacy: ",
					text2: `Unless provided with a Tutee's prior written or recorded consent, recording audio or video of a Tutee is strictly prohibited.`
				},
				point5: {
					text1: "Feedback: ",
					text2: `Exer Institute provides a feedback and rating system for Tutees to express their opinions on the instruction's quality. If a Tutee's feedback or rating violates Exer Institute Terms or Exer Institute Policies, please contact Exer Institute customer service at support@exerinstitute.com.`
				}
			}
		},
		section4: {
			title: "Tutor Policy Violations",
			point1: {
				text: "You are to execute your Tutor Services that satisfies the standards described above. You will violate this Tutor Policy if you:"
			},
			point2: {
				text: "Failed to perform your Tutor Services;"
			},
			point3: {
				text: "Failed to make reasonable efforts to resolve a dispute with a Tutee;"
			},
			point4: {
				text: "Caused great discontent from your Tutees; or"
			},
			point5: {
				text: "Violated Exer Institute Policies or Exer Institute Terms."
			},
			point6: {
				text: "Exer Institute reserves the right to take any of the following actions at any time, at its sole discretion:"
			},
			point7: {
				text: "Remove your listed Tutor Services;"
			},
			point8: {
				text: "Adjust your Exer Institute Account privileges;"
			},
			point9: {
				text: "Adjust your Tutor Wallet balance and/or freeze or suspend your Tutor Wallet;"
			},
			point10: {
				text: "Suspend or terminate your Exer Institute Account; or"
			},
			point11: {
				text: "Contact law enforcement or seek legal remedies."
			}
		},
		section5: {
			title: "Tutor Content",
			point1: {
				text: "The provisions with this section concern all Tutors offering Tutor Services on the Exer Institute Platform, in addition to the provisions outlined in the Content section of the Exer Institute Terms."
			},
			point2: {
				text: "Content Ownership",
				point1: {
					text: `Any articles, videos, or materials utilized in your Tutor Services or published on the Exer Institute Platform are considered Tutor Content. You retain the copyright to your Tutor Content, and Exer Institute does not assert any ownership rights over any Tutor Content.`
				}
			},
			point3: {
				text: "Tutee License to Tutor Content",
				point1: {
					text: `You hereby grant each Tutee who purchased your Tutor Services the right to view, edit, and reproduce your Tutor Content solely for personal and noncommercial educational purposes. You acknowledge that all license grants, warranties, representations, and other provisions outlined in the Content section of the Exer Institute Terms apply to all of your Tutor Content published on the Exer Institute Platform.`
				}
			},
			point4: {
				text: "Exer Institute License to Tutor Content",
				point1: {
					text: `In addition to the license granted to Exer Institute to view, edit, reproduce, distribute, and use your Tutor Content as per Content of the Exer Institute Terms, you hereby grant Exer Institute a license to use your Tutor Content, information regarding your Tutor Services, and your profile name for marketing, advertising, and promotion of the Exer Institute Platform across any medium. Upon receiving a written request from such Tutor via Exer Institute customer support, Exer Institute will remove any Tutor Content from any of its official public profiles.`
				}
			}
		},
		section6: {
			title: "Additional Restrictions",
			point1: {
				text: "In addition to adhering to the restrictions outlined in the Content of the Exer Institute Terms, you agree not to enable, encourage, or assist Tutees or other Tutors to:"
			},
			point2: {
				text: "Provide inaccurate, or plagiarized information on the Exer Institute Platform, especially in your Tutor profile, course content, and feedback."
			},
			point3: {
				text: "Offer your Tutor Services for free in exchange for positive feedback and/or ratings from Tutees."
			},
			point4: {
				text: `Provide your Tutor Services to any "fake" Tutees created by yourself or any third party to artificially inflate your session counts and/or improve your ratings.`
			},
			point5: {
				text: "Falsify your background and credentials."
			},
			point6: {
				text: `Engage with Tutees outside of the Exer Institute Platform. If a Tutee requests Tutor Services outside of the Exer Institute Platform, you may report the Tutee via Exer Institute customer support. You can also remind them that sessions must be created, held, and paid for through the Exer Institute Platform.`
			},
			point7: {
				text: `Violation of these restrictions may result in Exer Institute suspending or deactivating your Exer Institute Account. Exer Institute also reserves the right to withhold any earnings and you may lose access to your data relating to your past actions on the Exer Institute Platform.`
			}
		},
		section7: {
			title: "Customer Support",
			point1: {
				text: "If you have any questions, need assistance, or wish to report a violation of any policies and guidelines specified above, please contact Exer Institute customer service using the following email:"
			},
			point2: {
				text: "Email: support@exerinstitute.com"
			}
		}
	},
	tuteePolicy: {
		title: "Tutee Policy",
		header: {
			date: "Last updated: September 12, 2024",
			toUse1: `If intend to use Exer Institute's Tutoring Services, you must read and agree to be bound by these policies for Tutees (“`,
			toUse2: "Tutee Policy",
			toUse3: `”). Terms that are capitalized but not defined herein shall have the meanings ascribed to them in the Exer Institute's Terms of Service, which can be accessed at `,
			toUse4: "https://exerinstitute.com/terms-and-conditions",
			toUse5: " (the “",
			toUse6: "Exer Institute Terms",
			toUse7: "”).",
			unauthorizedUse: `Should you not fully accept this Tutee Policy, you are not authorized to request tutoring services on the Exer Institute Platform.`,
			policyGoal: `This Tutee Policy aims to uphold the highest standards of conduct among Tutees in their interactions with Tutors by establishing clear 
				expectations. It is intended to guide tutees in their daily conduct when engaging with Tutors. Recognizing that our Tutees hail from diverse cultural 
				backgrounds, this Tutee Policy endeavors to adopt universally accepted standards of tolerance and respect. While it does not address every possible 
				situation that may arise in Tutee-Tutor interactions on the Exer Institute Platform, it provides principles of ethical and professional behavior to 
				guide Tutees in aligning with Exer Institute's expectations.`
		},
		section1: {
			title: "Guidelines for using the Tutoring Services",
			point1: {
				text: "Tutoring Service Request",
				point1: {
					text: "When creating a tutoring request, you agree to abide by the following:"
				},
				point2: {
					text: "Utilize the Exer Institute Platform to provide any necessary information to the Tutor you decided to have the session with. This gives the Tutor a chance to prepare in advance, to ensure that your time is well-spent."
				},
				point3: {
					text: `To help protect you in case of disputes, please maintain a record of communications between you and the Tutee. 
						The record should contain your availability to provide any Tutor Services, your feedback to the Tutee, any 
						schedule changes, any delays during the Session, and the completion of Sessions. Additionally, you can choose 
						to download the Zoom Session's recording. The recording and access to Zoom whiteboard gets automatically deleted 
						and removed after 30 days from the Session.`
				}
			},
			point2: {
				text: "Session Policy",
				point1: {
					text: "Tutees must abide by the following when agreeing to a tutoring session:"
				},
				point2: {
					text1: "Commitment to Scheduled Sessions: ",
					text2: "A confirmed Session is a binding commitment with a Tutor."
				},
				point3: {
					text1: "Resolving Disputes: ",
					text2: "In case of a dispute between Tutor and Tutee, Exer Institute will request both parties to provide evidence containing all relevant information, which includes but is not limited to dates, times, and other communication details as indicated in Section Tutor Service Request."
				}
			},
			point3: {
				text: "Rescheduling and Cancelling Policy",
				point1: {
					text1: `If you are unable to attend a tutoring session at the previously agreed time, you agree to immediately explain to the Tutor your 
						reasons for being unable to attend the session. If an emergency prevents you from informing the Tutor in advance, you are required to 
						provide an explanation to the Tutor as soon as possible.`,
					text2: `A Tutee may cancel or reschedule any scheduled sessions more than the Tutor's posted cancellation over hours, without needing to 
						provide consent from the Tutor. All cancellations or rescheduling must be handled through the Exer Institute Platform.`,
					text3: "Penalties will be incurred according to the Tutor’s cancellation policy."
				}
			},
			point4: {
				text: "Payment and Feedback",
				point1: {
					text: "After attending a tutoring session, you agree to abide by the following:"
				},
				point2: {
					text: "Utilize the Exer Institute Payment Service for handling all financial transactions related to paying for the tutoring services."
				},
				point3: {
					text: "Provide objective and helpful feedback for the Tutor."
				}
			},
			point5: {
				text: "Additional Guidelines",
				point1: {
					text: "You also agree to abide by the following additional guidelines:"
				},
				point2: {
					text: "Uphold the privacy of Exer Institute Tutors per the Exer Institute Privacy Policy by not requesting any personal information from Tutors;"
				},
				point3: {
					text: "Uphold the privacy of your own personal information;"
				},
				point4: {
					text: "Assist Exer Institute in resolving any disputes;"
				},
				point5: {
					text: "Report any violations of the Exer Institute Terms or of Exer Institute Policies."
				}
			}
		},
		section2: {
			title: "Code of Conduct",
			point1: {
				text: "Interaction Standards",
				point1: {
					text: "Given that Tutors come from diverse backgrounds, it is important to be mindful and respectful of your behavior:"
				},
				point2: {
					text: "Do not use offensive language with a Tutor"
				},
				point3: {
					text: "Maintain composure with your Tutor"
				},
				point4: {
					text1: "Tutor Privacy: ",
					text2: "Unless provided with a Tutor’s prior written or recorded consent, recording audio or video of a Tutor is strictly prohibited."
				},
				point5: {
					text1: "Feedback: ",
					text2: "Exer Institute provides a feedback and rating system for Tutors to express their opinions on the session. If a Tutor’s feedback or rating violates Exer Institute Terms or Exer Institute Policies, please contact Exer Institute customer service at support@exerinstitute.com."
				}
			},
			point2: {
				text: "Academic Honesty Policy",
				point1: {
					text: "Exer Institute provides tutoring services to facilitate learning, not cheating. Tutees agree to abide by the following policies:"
				},
				point2: {
					text: "Tutees agree to not hire tutors through Exer Institute to complete any forms of homework, projects, quizzes, and tests"
				},
				point3: {
					text: "Tutees also agree to not hire Tutors to conduct actions on the Tutee’s behalf that violates academic honesty policies described in Exer Institute platform, the Tutee’s academic institution, or of their workplace."
				}
			}
		},
		section3: {
			title: "Tutee Policy Violations",
			point1: {
				text: "In addition to the standards described above, you will violate this Tutee Policy if you:"
			},
			point2: {
				text: "Failed to make reasonable efforts to resolve a dispute with a Tutor;"
			},
			point3: {
				text: "Caused great discontent from your Tutors; or"
			},
			point4: {
				text: "Violated Exer Institute Policies or Exer Institute Terms."
			},
			point5: {
				text: "Exer Institute reserves the right to take any of the following actions at any time, at its sole discretion:"
			},
			point6: {
				text: "Adjust your Exer Institute Account privileges;"
			},
			point7: {
				text: "Suspend or terminate your Exer Institute Account; or "
			},
			point8: {
				text: "Contact law enforcement or seek legal remedies."
			}
		},
		section4: {
			title: "Customer Support",
			point1: {
				text: "If you have any questions, need assistance, or wish to report a violation of any policies and guidelines specified above, please contact Exer Institute customer service using the following email:"
			},
			point2: {
				text: "Email: support@exerinstitute.com"
			}
		}
	},
	termsNConditions: {
		title: "Terms and Conditions",
		header: {
			date: "Last updated: June 01, 2020",
			tNC: "Please read these terms and conditions carefully before using Our Service."
		},
		section1: {
			title: "Interpretation and Definitions",
			point1: {
				text: "Interpretation",
				point1: {
					text: "The words of which the initial letter is capitalized have meanings defined under the following conditions."
				},
				point2: {
					text: "The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
				}
			},
			point2: {
				text: "Definitions",
				point1: {
					text: "For the purposes of these Terms and Conditions:",
					point1: {
						term: "Affiliate",
						text: " means an entity that controls, is controlled by or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."
					},
					point2: {
						term: "Account",
						text: " means a unique account created for You to access our Service or parts of our Service."
					},
					point3: {
						term: "Company",
						text: " (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to EXER INSTITUTE, 96 Washington Square, Dr, Ca 95035."
					},
					point4: {
						term: "Content",
						text: " refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content."
					},
					point5: {
						term: "Country",
						text: " refers to: California, United States"
					},
					point6: {
						term: "Device",
						text: " means any device that can access the Service such as a computer, a cellphone or a digital tablet."
					},
					point7: {
						term: "Feedback",
						text: " means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service."
					},
					point8: {
						term: "Goods",
						text: " refer to the items offered for sale on the Service."
					},
					point9: {
						term: "Orders",
						text: " mean a request by You to purchase Goods from Us."
					},
					point10: {
						term: "Promotions",
						text: " refer to contests, sweepstakes or other promotions offered through the Service."
					},
					point11: {
						term: "Service",
						text: " refers to the Website."
					},
					point12: {
						term: "Subscriptions",
						text: " refer to the services or access to the Service offered on a subscription basis by the Company to You."
					},
					point13: {
						term: "Free Trial",
						text: " refers to a limited period of time that may be free when purchasing a Subscription."
					},
					point14: {
						term: "Terms and	Conditions",
						text: " (also referred as \"Terms\") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service."
					},
					point15: {
						term: "Third-party Social Media Service",
						text: " means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service."
					},
					point16: {
						term: " Website",
						text: " refers to EXER INSTITUTE, accessible from "
					},
					point17: {
						term: "You",
						text: " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
					}
				}
			}
		},
		section2: {
			title: "Acknowledgement",
			point1: {
				text: "These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service."
			},
			point2: {
				text: "Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service."
			},
			point3: {
				text: "By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service."
			},
			point4: {
				text: "You may not use the Services if you are younger than 13 years old, unless you have the agreement of your parent or legal guardian. "
			},
			point5: {
				text: "Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service."
			}
		},
		section3: {
			title: "Placing Orders for Goods",
			subpoint1: {
				text: "By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into binding contracts."
			},
			point1: {
				text: "Your Information",
				point1: {
					text: "If You wish to place an Order for Goods available on the Service, You may be asked to supply certain information relevant to Your Order including, without limitation, Your name, Your email, Your phone number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information."
				},
				point2: {
					text: "You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct and complete."
				},
				point3: {
					text: "By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order."
				}
			},
			point2: {
				text: "Order Cancellation",
				subpoint1: {
					text: "We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:",
					point1: {
						text: "Goods availability"
					},
					point2: {
						text: "Errors in the description or prices for Goods"
					},
					point3: {
						text: "Errors in Your Order"
					}
				},
				subpoint2: {
					text: "We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected."
				},
				point1: {
					text: "Your Order Cancellation Rights",
					point1: {
						text: "Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our Returns Policy."
					},
					point2: {
						text: "Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn more about your right to cancel Your Order."
					},
					point3: {
						text: "Your right to cancel an Order only applies to Goods that are returned in the same condition as You received them. You should also include all of the products instructions, documents and wrappings. Goods that are damaged or not in the same condition as You received them or which are worn simply beyond opening the original packaging will not be refunded. You should therefore take reasonable care of the purchased Goods while they are in Your possession."
					},
					point4: {
						text: "We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement."
					},
					point5: {
						text: "You will not have any right to cancel an Order for the supply of any of the following Goods:",
						point1: {
							text: "The supply of Goods made to Your specifications or clearly personalized."
						},
						point2: {
							text: "The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over."
						},
						point3: {
							text: "The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery."
						},
						point4: {
							text: "The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items."
						},
						point5: {
							text: "The supply of digital content which is not supplied on a tangible medium if the performance has begun with Your prior express consent and You have acknowledged Your loss of cancellation right."
						}
					}
				}
			},
			point3: {
				text: "Availability, Errors and Inaccuracies",
				point1: {
					text: "We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Goods on the Service and in Our advertising on other websites."
				},
				point2: {
					text: "We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice."
				}
			},
			point4: {
				text: "Prices Policy",
				point1: {
					text: "The Company reserves the right to revise its prices at any time prior to accepting an Order."
				},
				point2: {
					text: "The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher foreign exchange costs and any other matter beyond the control of the Company. In that event, You will have the right to cancel Your Order."
				}
			},
			point5: {
				text: "Payments",
				point1: {
					text: "All Goods purchased are subject to a one-time payment. Payment can be made through various payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or online payment methods (Stripe, for example)."
				},
				point2: {
					text: "Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order."
				}
			}
		},
		section4: {
			title: "Subscriptions",
			point1: {
				text: "Subscription period",
				point1: {
					text: "The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription."
				},
				point2: {
					text: "At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it."
				}
			},
			point2: {
				text: "Subscription cancellations",
				point1: {
					text: "You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period."
				}
			},
			point3: {
				text: "Billing",
				point1: {
					text: "You shall provide the Company with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information."
				},
				point2: {
					text: "Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice."
				}
			},
			point4: {
				text: "Fee Changes",
				point1: {
					text: "The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period."
				},
				point2: {
					text: "The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective."
				},
				point3: {
					text: "Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount."
				}
			},
			point5: {
				text: "Refunds",
				point1: {
					text: "Except when required by law, paid Subscription fees are non-refundable."
				},
				point2: {
					text: "Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company."
				}
			},
			point6: {
				text: "Free Trial",
				point1: {
					text: "The Company may, at its sole discretion, offer a Subscription with a Free trial for a limited period of time."
				},
				point2: {
					text: "You may be required to enter Your billing information in order to sign up for the Free trial."
				},
				point3: {
					text: "If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free trial has expired. On the last day of the Free Trial period, unless You cancelled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected."
				},
				point4: {
					text: "At any time and without notice, the Company reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free trial offer."
				}
			}
		},
		section5: {
			title: "Promotions",
			point1: {
				text: "Any Promotions made available through the Service may be governed by rules that are separate from these Terms."
			},
			point2: {
				text: "If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply."
			}
		},
		section6: {
			title: "User Accounts",
			point1: {
				text: "When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service."
			},
			point2: {
				text: "You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service."
			},
			point3: {
				text: "You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account."
			},
			point4: {
				text: "You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene."
			}
		},
		section7: {
			title: "Content",
			point1: {
				text: "Your Right to Post Content",
				point1: {
					text: "Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness."
				},
				point2: {
					text: "By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms."
				},
				point3: {
					text: "You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person."
				}
			},
			point2: {
				text: "Content Restrictions",
				point1: {
					text: "The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account."
				},
				point2: {
					text: "You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:",
					point1: {
						text: "Unlawful or promoting unlawful activity."
					},
					point2: {
						text: "Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups. "
					},
					point3: {
						text: "Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling."
					},
					point4: {
						text: "Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person."
					},
					point5: {
						text: "Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights."
					},
					point6: {
						text: "Impersonating any person or entity including the Company and its employees or representatives."
					},
					point7: {
						text: "Violating the privacy of any third person."
					},
					point8: {
						text: "False information and features."
					}
				},
				point3: {
					text: "The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content."
				}
			},
			point3: {
				text: "Content Backups",
				point1: {
					text: "Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data."
				},
				point2: {
					text: "Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed."
				},
				point3: {
					text: "The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state."
				}
			},
			point4: {
				text: "Security Breach",
				point1: {
					text: "Any security breaches that was incurred by any person, both external and internal to the Company, that placed users’ private information at risk will prompt an immediate notification via email to all users in the event of."
				}
			}
		},
		section8: {
			title: "Copyright Policy",
			point1: {
				text: "Intellectual Property Infringement",
				point1: {
					text: "We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person."
				},
				point2: {
					text: "If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at support@exerinstitute.com and include in Your notice a detailed description of the alleged infringement."
				},
				point3: {
					text: "You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright."
				}
			},
			point2: {
				text: "DMCA Notice and DMCA Procedure for Copyright Infringement Claims",
				point1: {
					text: "You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):",
					point1: {
						text: "An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest."
					},
					point2: {
						text: "A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work."
					},
					point3: {
						text: "Identification of the URL or other specific location on the Service where the material that You claim is infringing is located."
					},
					point4: {
						text: "Your address, telephone number, and email address."
					},
					point5: {
						text: "A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law."
					},
					point6: {
						text: "A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf."
					}
				},
				point2: {
					text: "You can contact our copyright agent via email at support@exerinstitute.com. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service."
				}
			}
		},
		section9: {
			title: "Intellectual Property",
			point1: {
				text: "The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors."
			},
			point2: {
				text: "The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries."
			},
			point3: {
				text: "Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company."
			}
		},
		section10: {
			title: "Your Feedback to Us",
			point1: {
				text: "You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction."
			}
		},
		section11: {
			title: "Links to Other Websites",
			point1: {
				text: "Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company."
			},
			point2: {
				text: "The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services."
			},
			point3: {
				text: "We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit."
			}
		},
		section12: {
			title: "Termination",
			point1: {
				text: "We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions."
			},
			point2: {
				text: "Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service."
			}
		},
		section13: {
			title: "Limitation of Liability",
			point1: {
				text: "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service."
			},
			point2: {
				text: "To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose."
			},
			point3: {
				text: "Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law."
			}
		},
		section14: {
			title: "\"AS IS\" and \"AS AVAILABLE\" Disclaimer",
			point1: {
				text: "The Service is provided to You \"AS IS\" and \"AS AVAILABLE\" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected."
			},
			point2: {
				text: "Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components."
			},
			point3: {
				text: "Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law."
			}
		},
		section15: {
			title: "Governing Law",
			point1: {
				text: "The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws."
			}
		},
		section16: {
			title: "Disputes Resolution",
			point1: {
				text: "If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company."
			}
		},
		section17: {
			title: "For European Union (EU) users",
			point1: {
				text: "If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in."
			}
		},
		section18: {
			title: "United States Federal Government End Use Provisions",
			point1: {
				text: "If You are a U.S. federal government end user, our Service is a \"Commercial Item\" as that term is defined at 48 C.F.R. §2.101."
			}
		},
		section19: {
			title: "United States Legal compliance",
			point1: {
				text: "You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a \"terrorist supporting\" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties."
			}
		},
		section20: {
			title: "Severability and waiver",
			point1: {
				text: "Severability",
				point1: {
					text: "If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect."
				}
			},
			point2: {
				text: "Waiver",
				point1: {
					text: "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach."
				}
			}
		},
		section21: {
			title: "Translation Interpretation",
			point1: {
				text: "These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute."
			}
		},
		section22: {
			title: "Changes to These Terms and Conditions",
			point1: {
				text: "We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion."
			},
			point2: {
				text: "By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service."
			}
		},
		section23: {
			title: "Contact Us",
			point1: {
				text: "If you have any questions about these Terms and Conditions, You can contact us:"
			},
			point2: {
				text: "By email: support@exerinstitute.com"
			},
			point3: {
				text: "By visiting this page on our website: "
			}
		}
	}
};