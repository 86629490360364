import request, { POST } from "Utilities/Fetches";

const BASE = "/services/subscription";

const GET_SUBSCRIPTION = BASE + "/get";
const CREATE_SUBSCRIPTION = BASE + "/create";
const CANCEL_SUBSCRIPTION = BASE + "/cancel";
const CONTACT_SALES = BASE + "/post/contactSalesForm";


/**
 * @param {Object} payload {
 *  ownerId: String
 * }
 */
export function getSubscriptionAPI(payload, callback) {
  request(GET_SUBSCRIPTION, POST, payload, callback);
}

/**
 * @param {Object} payload {
 *  ownerId: String,
 *  subscriptionPlan: String,
 *  billingProcessor: String
 * }
 */
export function createSubscriptionAPI(payload, callback) {
  request(CREATE_SUBSCRIPTION, POST, payload, callback);
}

/**
 * @param {Object} payload {
 *  ownerId: String
 * }
 */
export function cancelSubscriptionAPI(payload, callback) {
  request(CANCEL_SUBSCRIPTION, POST, payload, callback);
}

/**
 * @param {Object} payload = SUBSCRIPTION_VO
 * Parks the form information under SUBSCRIPTION_VO key - "billingDetails".
 */
export function subscriptionContactSalesAPI(payload, callback) {
  request(CONTACT_SALES, POST, payload, callback);
}