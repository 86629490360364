import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import defaultLinkAction from "Utilities/LinkActions";

import greenRightArrowAsset from "assets/icons/common/arrowRightGreen.svg";


function BenefitItem(props) {
	return (
		<div className="more-details-item-container">
			<div className="details">
				<div className="details-header">
					{props.header}
				</div>
				<div className="details-info">
					{props.info}
				</div>
				<Link to={props.linkPath} className="details-action" onClick={defaultLinkAction}>
					<div className="details-action-text">
						{props.linkText}
					</div>
					<img className="green-arrow-icon" src={greenRightArrowAsset} alt={messageMap("arrows.rightGreen", "image")} />
				</Link>
			</div>

			<img src={props.imgSrc} alt={props.imgAlt} className="detail-image"/>
		</div>
	);
}

BenefitItem.propTypes = {
	header: PropTypes.string.isRequired,
	info: PropTypes.string.isRequired,
	linkText: PropTypes.string.isRequired,
	linkPath: PropTypes.string.isRequired,

	imgSrc: PropTypes.string.isRequired,
	imgAlt: PropTypes.string.isRequired
};

export default BenefitItem;

