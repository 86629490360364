import React, {useEffect, useState, useRef, Fragment} from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import { 
	ALL_TUTORS, SAVED_TUTORS, TUTEE_USER ,
	OPEN_STATE, PENDING_STATE, SCHEDULED_STATE
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { REQUESTS_OPEN_REQUESTS } from "templates/utilities/PageTabs";
import { 
	MODAL_CLOSE_TIMEOUT, SAVE_RELOAD_TIMEOUT
} from "Utilities/Constants/TimeoutConstants";

import Alert, {ERROR, SUCCESS, INFORMATION} from "templates/Alert";
import Tooltip from "templates/Tooltip";
import Modal from "templates/Modal";
import SubjectTopicDropdowns from "pages/Profile/subPages/components/tutorComponents/SubjectTopicDropdowns";
import BookSessionModal from "pages/Tutoring/subPages/components/BookSessionModal";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import { 
	saveTutorRequestAPI, editTutorRequestAPI, getTutorRequestsAPI
} from "apis/controllers/tutoring/SessionRequestController";
import { getAccountSettingsAPI } from "apis/controllers/person/AccountsController";
import { getUserChargeablePaymentMethodAPI } from "apis/controllers/person/PersonPaymentInfoController";

import chevronAsset from "assets/icons/common/chevron.svg";


function NewRequest(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const history = useHistory();

	const [hasFirstAndLastName, setHasFirstAndLastName] = useState(false),
		[hashChargeablePaymentMethods, setHashChargeablePaymentMethods] = useState(false),
		[subjectsToTopicsMap, setSubjectsToTopicsMap] = useState(props.subjectsToTopicsMap),
		[urgencyPreference, setUrgencyPreference] = useState(props.urgency),
		[requestDuration, setRequestDuration] = useState(props.urgencyDuration),
		[requestsGroupPreference, setRequestsGroupPreference] = useState(props.requestsGroupPreference),
		[description, setDescription] = useState(props.description ? props.description : ""),
		[nonCompletedRequestSchedules, setNonCompletedRequestSchedules] = useState(),
		[hasSavedTutors, setHasSavedTutors] = useState(false),
		[hasNowConflict, setHasNowConflict] = useState(false),
		[cappedRequestLimit, setCappedRequestLimit] = useState(false),
		// generated DOM
		[nowDurationDOM, setNowDurationDOM] = useState(),
		[chosenSessionDateDOM, setChosenSessionDateDOM] = useState(),
		[nowUrgencyTooltipDOM, setNowUrgencyTooltipDOM] = useState(),
		[savedTutorsTooltipDOM, setSavedTutorsTooltipDOM] = useState();

	const nowUrgencyRef = useRef(),
		laterUrgencyRef = useRef(),
		chosenDateTime = useRef(props.urgency && props.urgency !== "now" ? new Date(Number(props.urgency)) : ""),
		durationNowMinutesRef = useRef(props.urgencyDuration),
		durationLaterMinutesRef = useRef(props.urgencyDuration),
		allTutorsRequestRef = useRef(),
		savedTutorsRequestRef = useRef();

	useEffect(() => {
		const callback = () => {
			getSavedTutors(populateEditProps);
			getPersonalRequests();
		};

		checkUserTutoringEligibility(callback);
	}, [props.ownerId]);


	// TODO: disable send button if not logged in and if there is no saved payment method
	function checkUserTutoringEligibility(callback) {
		getUserChargeablePaymentMethodAPI(ownerId, resp => {
			setHashChargeablePaymentMethods(resp);
			callback();
		});
	}

	function populateEditProps() {
		if (props.requestsGroupPreference) {
			props.requestsGroupPreference === "allTutors" ? (allTutorsRequestRef.current.checked = true) : (savedTutorsRequestRef.current.checked = true);

			if (props.urgency === "now") {
				setNowDurationDOM(
					<div>
						{`${messageMap("tutoringPage.request.modal.scheduleNow", "generic")} ${props.urgencyDuration} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
					</div>
				);
				nowUrgencyRef.current.checked = true;
			}
			else {
				setChosenSessionDateDOM(
					<div>
						{`${messageMap("tutoringPage.request.modal.scheduleAt", "generic")} ${chosenDateTime.current.toLocaleString()} ${messageMap("tutoringPage.request.modal.for", "generic")} ${props.urgencyDuration} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
					</div>
				);
				laterUrgencyRef.current.checked = true;
			}
		}
	}

	function getSavedTutors(callback) {
		if (ownerId != null) {
			const payload = {
				ownerId: ownerId,
				fields: ["savedTutors"]
			};
			getAccountSettingsAPI(payload, resp => {
				if (resp.savedTutors != null && resp.savedTutors.length) {
					setHasSavedTutors(true);
				}
				if (resp.firstName && resp.lastName) {
					setHasFirstAndLastName(true);
					callback();
				}
			});
		}
	}

	function getPersonalRequests() {
		const pathVariables = {
			sessionStates: [OPEN_STATE, PENDING_STATE, SCHEDULED_STATE],
			sentOrReceived: "",
			intendedUser: TUTEE_USER
		};
		getTutorRequestsAPI(pathVariables, ownerId, resp => {
			let currentRequestsSchedules = [];
			let nonLiveAndNoneCompletedSessionsCount = 0;

			resp.forEach(request => {
				const requestUrgency = request.urgency;
				if (requestUrgency === "now") {
					currentRequestsSchedules.push("now");
				}
				else {
					for (let i = 0; i < request.duration / 15; ++i) {
						currentRequestsSchedules.push(Number(requestUrgency) + 60000 * 15 * i);
					}
				}
				if (request.state === OPEN_STATE) {
					++nonLiveAndNoneCompletedSessionsCount;
				}
			});

			if (currentRequestsSchedules.includes("now")) {
				setHasNowConflict(true);
			}
			else if (nonLiveAndNoneCompletedSessionsCount >= 10) {
				setCappedRequestLimit(true);
			}

			setNonCompletedRequestSchedules(currentRequestsSchedules);
		});
	}

	function setUrgencyChoice(urgencyChoice) {
		if (urgencyChoice === "now") {
			if (hasNowConflict) {
				nowUrgencyRef.current.checked = false;
			}
			else {
				nowUrgencyRef.current.checked = true;
				laterUrgencyRef.current.checked = false;
	
				setChosenSessionDateDOM(null);
				showNowDurationModal();
			}
		}
		else if (urgencyChoice === "later") {
			nowUrgencyRef.current.checked = false;
			laterUrgencyRef.current.checked = true;

			setNowDurationDOM(null);
			showLaterSchedule();
		}

		setUrgencyPreference(urgencyChoice);
	}

	function setRequestsChoice(requestChoice) {
		let shouldSaveChoice = false;

		if (requestChoice === ALL_TUTORS) {
			allTutorsRequestRef.current.checked = true;
			savedTutorsRequestRef.current.checked = false;

			shouldSaveChoice = true;
		}
		else if (requestChoice === SAVED_TUTORS) {
			if (!hasSavedTutors) {
				savedTutorsRequestRef.current.checked = false;
			}
			else {
				allTutorsRequestRef.current.checked = false;
				savedTutorsRequestRef.current.checked = true;

				shouldSaveChoice = true;
			}
		}

		shouldSaveChoice && setRequestsGroupPreference(requestChoice);
	}

	function toggleMissingSavedTutorsTooltip(hideOrShow) {
		if (!hasSavedTutors) {
			setSavedTutorsTooltipDOM(
				hideOrShow === "show" ?
				<Tooltip classStr="tooltip-bottom-middle saved-tutors" subheader={messageMap("tutor.noSavedTutors", "tooltip")} />
				: null
			);
		}
	}
	
	function toggleNowUrgencyConflictTooltip(hideOrShow) {
		if (hasNowConflict) {
			setNowUrgencyTooltipDOM(
				hideOrShow === "show" ?
				<Tooltip classStr="tooltip-bottom-middle now-urgency" subheader={messageMap("tutor.hasNowConflict", "tooltip")} />
				: null
			);
		}
	}

	function validateAndDisplayNowSchedule() {
		if (durationNowMinutesRef.current.value === "") {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("schedule.missing.duration", "validation")} />
			);
		}
		else {
			setNowDurationDOM(
				<div>
					{`${messageMap("tutoringPage.request.modal.scheduleNow", "generic")} ${durationNowMinutesRef.current.value} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
				</div>
			);
			setRequestDuration(durationNowMinutesRef.current.value);
			
			if (props.mode === "editRequest") {
				hideModal("modal1");
			}
			else {
				hideModal("default");
			}
		}
	}
	
	function validateAndDisplayLaterSchedule() {
		setChosenSessionDateDOM(
			<div>
				{`${messageMap("tutoringPage.request.modal.scheduleAt", "generic")} ${chosenDateTime.current.toLocaleString()} ${messageMap("tutoringPage.request.modal.for", "generic")} ${durationLaterMinutesRef.current.value} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
			</div>
		);
		setRequestDuration(durationLaterMinutesRef.current.value);
		
		if (props.mode === "editRequest") {
			hideModal("modal1");
		}
		else {
			hideModal("default");
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			if (props.mode === "editRequest") {
				hideModal("modal1");
			}
			else {
				hideModal("default");
			}

			laterUrgencyRef.current.checked = false;
			setUrgencyPreference(null);
		}
	}
	function hideModal(modalType) {
		const modalMap = {
			default: props.setModal,
			modal1: props.setModal1
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function showNowDurationModal() {
		const modalDom = (
			<Modal closeHandler={closeModal} submitHandler={validateAndDisplayNowSchedule}
							modalClass="tutoring" submitText={messageMap("tutoring.modal.setSchedule", "button")}>
				<div className="schedule-option">
					<label htmlFor="duration" className="schedule-label">
						{messageMap("tutoringPage.request.modal.duration", "generic")}
					</label>
					<div className="arrowed-input-container">
						<input id="duration" className="schedule-value duration"
							type="number" min="15" max="240" step="15"
							ref={durationNowMinutesRef}>
						</input>
						<div className="inline-chevron-div">
							<div className="chevron-container">
								<img src={chevronAsset} alt={messageMap("chevron.up", "image")}
									className="chevron-up" tabIndex={0} role="button"
									onClick={e => updateNowDuration("increase")}
									onKeyDown={e => onKeyDown(e, updateNowDuration, ["increase"])}></img>
								<img src={chevronAsset} alt={messageMap("chevron.down", "image")}
									className="chevron-down" tabIndex={0} role="button"
									onClick={e => updateNowDuration("decrease")}
									onKeyDown={e => onKeyDown(e, updateNowDuration, ["decrease"])}></img>
							</div>
						</div>
						<div>
							{messageMap("tutoringPage.request.modal.minutes", "generic")}
						</div>
					</div>
				</div>
			</Modal>
		);

		if (props.mode === "editRequest") {
			props.setModal1(modalDom);
		}
		else {
			props.setModal(modalDom);
		}
	}

	function showLaterSchedule() {
		const modalDom = (
			<BookSessionModal closeModal={closeModal} setAlert={props.setAlert}
				submitHandler={validateAndDisplayLaterSchedule} bookedTimes={nonCompletedRequestSchedules}
				// refs
				chosenDateTime={chosenDateTime} durationLaterMinutesRef={durationLaterMinutesRef} />
		);

		if (props.mode === "editRequest") {
			props.setModal1(modalDom);
		}
		else {
			props.setModal(modalDom);
		}
	}

	function updateNowDuration(durationDirection) {
		if ((durationDirection === "decrease" && Number(durationNowMinutesRef.current.value) === 15)
			|| (durationDirection === "increase" && Number(durationNowMinutesRef.current.value) === 240)) {
			return;
		}
		else if (durationNowMinutesRef.current.value <= 240 && durationNowMinutesRef.current.value >= 0) {
			if (durationDirection === "increase") {
				durationNowMinutesRef.current.value = Number(durationNowMinutesRef.current.value) + 15;
			}
			else if (durationDirection === "decrease") {
				durationNowMinutesRef.current.value = Number(durationNowMinutesRef.current.value) - 15;
			}
		}
	}

	function saveDescription(e) {
		const descriptionValue = e.target.value;

		if (checkHasExplicitWords(descriptionValue)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			setDescription(descriptionValue);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function saveTutorRequest() {
		if (ownerId == null) {
			askUserToSignUpFirst(props.setModal, props.setLoginModal);

			return;
		}

		if (cappedRequestLimit) {
			props.setAlert(
				<Alert type={INFORMATION} closeHandler={closeAlert} customClass="tutoring" 
					msg={messageMap("tutoringPage.request.limitReached", "generic")} />
			);

			return;
		}

		const errorMsgs = validateRequest();

		if (errorMsgs.length) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={errorMsgs.join(" ")} timeout={20000}/>
			);
		}
		else {
			const subject = Object.keys(subjectsToTopicsMap)[0];


			if (urgencyPreference === "later" && chosenDateTime.current === "") {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
						msg={messageMap("schedule.invalidAppointment", "validation")} />
				);
				return;
			}
			const urgency = urgencyPreference === "later" ? chosenDateTime.current.getTime() : urgencyPreference;

			let payload = {
				createdAt: Date.now(),
				ownerId: ownerId,
				subject: subject,
				topic: subjectsToTopicsMap[subject],
				urgency: urgency,
				duration: Number(requestDuration),
				receiverPreference: requestsGroupPreference,
				description: description
			};

			if (props.mode === "newRequest") {
				saveTutorRequestAPI(payload, resp => {
					let alertType;
					let alertMsgs = [];

					if (resp["SUCCESS"]) {
						alertType = SUCCESS;
						resp["SUCCESS"].split("_").forEach(msg => {
							alertMsgs.push(messageMap(msg, "api"));
						});

						props.reRenderList(Date.now(), true);
						history.push(`/tutoring?show=requests&sub=${REQUESTS_OPEN_REQUESTS}`, { shallow: true });
					}
					else if (resp["ERROR"]) {
						alertType = ERROR;
						resp["ERROR"].split("_").forEach(msg => {
							alertMsgs.push(messageMap(msg, "api"));
						});
					}

					props.setAlert(
						<Alert type={alertType} closeHandler={closeAlert} customClass="tutoring" 
							msg={alertMsgs.join(" ")} />
					);

					getPersonalRequests();
				});
			}
			else if (props.mode === "editRequest") {
				payload["id"] = props.requestId;

				editTutorRequestAPI(payload, resp => {
					let alertType;
					let alertMsgs = [];

					if (resp === "tutor.requests.edited.noDifference") {
						alertType = INFORMATION;
						alertMsgs.push(messageMap(resp, "api"));
					}
					else if (resp === "tutor.requests.edited.failed") {
						alertType = ERROR;
						alertMsgs.push(messageMap(resp, "api"));
					}
					else {
						alertType = SUCCESS;
						resp.split("_").forEach(msg => {
							alertMsgs.push(messageMap(msg, "api"));
						});
						alertMsgs.push(messageMap("tutor.requests.edited.pageReload", "api"));
					}

					props.setAlert(
						<Alert type={alertType} closeHandler={closeAlert} customClass="tutoring" 
							msg={alertMsgs.join(" ")} />
					);
					hideModal("default");

					getPersonalRequests();

					if (alertType === SUCCESS) {
						setTimeout(() => {
							window.location.reload();
						}, SAVE_RELOAD_TIMEOUT);
					}
				});
			}
		}
	}

	function validateRequest() {
		let errorsMsgs = [];

		if (subjectsToTopicsMap == null) {
			errorsMsgs.push(messageMap("schedule.missing.subjectTopic", "validation"));
		}
		if (urgencyPreference == null) {
			errorsMsgs.push(messageMap("schedule.missing.urgency", "validation"));
		}
		if (requestsGroupPreference == null) {
			errorsMsgs.push(messageMap("schedule.missing.requestReceiver", "validation"));
		}
		if (description == null || description === "") {
			errorsMsgs.push(messageMap("schedule.missing.description", "validation"));
		}

		return errorsMsgs;
	}

	return (
		<Fragment>
			<Helmet>
				<title>{messageMap("requestTutoring.title", "headerTag")}</title>
				<meta name="description" content={messageMap("requestTutoring.description", "headerTag")}></meta>
			</Helmet>

			{
				hasFirstAndLastName && hashChargeablePaymentMethods
				? (
					<Fragment>
						<div className="limits">
							{cappedRequestLimit && messageMap("tutoringPage.request.limitReached", "generic")}
						</div>

						<SubjectTopicDropdowns defaultLabel={`${messageMap("tutoringPage.request.subject", "generic")} *`}
							separateTopicLabel={`${messageMap("tutoringPage.request.topic", "generic")} *`}
							setSubjectsToTopics={setSubjectsToTopicsMap} singleTopic={true} isApplication={false} useNewLine={true}
							// passed props for editing only
							preSelectedSubject={props.subject} preSelectedTopic={props.topic} />

						<div className="page-field urgency">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("tutoringPage.request.assistanceUrgency", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements urgency">
								<div className="inline-elements">
									<div className="multi-line-inlines">
										<div className="radio-checkbox-container"
											onMouseOver={e => toggleNowUrgencyConflictTooltip("show")} onFocus={e => toggleNowUrgencyConflictTooltip("show")}
											onMouseLeave={e => toggleNowUrgencyConflictTooltip("hide")} onBlur={e => toggleNowUrgencyConflictTooltip("hide")}>
											<div>
												{nowUrgencyTooltipDOM}
											</div>
											<input id="nowRadio" className={`page-field-value radio-input later-radio-input ${hasNowConflict && "not-allowed"}`}
												type="radio"
												onClick={e => setUrgencyChoice("now")}
												onKeyDown={e => onKeyDown(e, setUrgencyChoice, ["now"], true)}
												ref={nowUrgencyRef}></input>
											<label htmlFor="nowRadio" className={`page-field-label later-radio-label ${hasNowConflict && "not-allowed"}`}>
												{messageMap("tutoring.requests.now", "labelPlaceholder")}
											</label>
										</div>
										<div>
											{nowDurationDOM}
										</div>
									</div>
								</div>
								<div className="inline-elements">
									<div className="multi-line-inlines">
										<div className="radio-checkbox-container">
											<input id="laterRadio" className="page-field-value radio-input later-radio-input"
												type="radio"
												onClick={e => setUrgencyChoice("later")}
												onKeyDown={e => onKeyDown(e, setUrgencyChoice, ["later"], true)}
												ref={laterUrgencyRef}>
											</input>
											<label htmlFor="laterRadio" className="page-field-label later-radio-label">
												{messageMap("tutoring.requests.later", "labelPlaceholder")}
											</label>
										</div>
										<div>
											{chosenSessionDateDOM}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="page-field">
							<label className="page-field-label">
								<div className="div-label">
									{messageMap("tutoringPage.request.sendTo", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								<div className="multi-line-inlines">
									<div className="radio-checkbox-container">
										<input id="allTutorsRadio" className="page-field-value radio-input later-radio-input"
											type="radio"
											onClick={e => setRequestsChoice(ALL_TUTORS)}
											onKeyDown={e => onKeyDown(e, setRequestsChoice, [ALL_TUTORS], true)}
											ref={allTutorsRequestRef}>
										</input>
										<label htmlFor="allTutorsRadio" className="page-field-label request-group-label">
											{messageMap("tutoring.requests.allTutors", "labelPlaceholder")}
										</label>
									</div>
								</div>
								<div className="multi-line-inlines">
									<div className="radio-checkbox-container"
												onMouseOver={e => toggleMissingSavedTutorsTooltip("show")} onFocus={e => toggleMissingSavedTutorsTooltip("show")}
												onMouseLeave={e => toggleMissingSavedTutorsTooltip("hide")} onBlur={e => toggleMissingSavedTutorsTooltip("hide")}>
										<div>
											{savedTutorsTooltipDOM}
										</div>
										<input id="savedTutorsRadio" className={`page-field-value radio-input later-radio-input ${!hasSavedTutors && "not-allowed"}`}
											type="radio"
											onClick={e => setRequestsChoice(SAVED_TUTORS)}
											onKeyDown={e => onKeyDown(e, setRequestsChoice, [SAVED_TUTORS], true)}
											ref={savedTutorsRequestRef}>
										</input>
										<label htmlFor="savedTutorsRadio" className={`page-field-label request-group-label ${!hasSavedTutors && "not-allowed"}`}>
											{messageMap("tutoring.requests.savedTutors", "labelPlaceholder")}
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className="page-field">
							<label className="page-field-label" htmlFor="additionalDetails">
								<div className="div-label">
									{messageMap("tutoringPage.request.assistanceDescription", "generic")}
								</div>
								*
							</label>
							<div className="inline-elements">
								<textarea id="additionalDetails" className="page-field-value textarea" type="text"
									required aria-required="true"
									maxLength={500}
									onChange={saveDescription} value={description}>
								</textarea>
							</div>
						</div>

						<button className={`send-request-button ${cappedRequestLimit ? "not-allowed" : ""}`}
								onClick={saveTutorRequest}>
							{
								props.mode === "editRequest"
								? messageMap("save.edits", "button")
								: messageMap("tutoring.request.create", "button")
							}
						</button>
					</Fragment>
				)
				: (
					!hasFirstAndLastName && !hashChargeablePaymentMethods
					? (
						<div className="set-first-last-name-payment-method">
							{messageMap("tutoringPage.request.setFirstLastNamePaymentMethodFirst1", "generic")}
							<button className="sign-up-button"
								onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
								{messageMap("here.text", "button")}
							</button>
							{messageMap("tutoringPage.request.setFirstLastNamePaymentMethodFirst2", "generic")}
						</div>
					)
					: (
						!hasFirstAndLastName && hashChargeablePaymentMethods
						? (
							<Fragment>
								<div className="set-first-last-name">
									{messageMap("tutoringPage.request.setFirstLastName", "generic")}
								</div>
								<Link to="/profile?show=account" className="profile-settings-nav-button"
									onClick={defaultLinkAction}>
									{messageMap("tutoring.request.goToProfile", "button")}
								</Link>
							</Fragment>
						)
						: (
							hasFirstAndLastName && !hashChargeablePaymentMethods
							&& (
								<Fragment>
									{/* // TODO: Include payment method here once Stripe payouts integration is done */}
									<div className="set-chargeable-payment-method">
										{messageMap("tutoringPage.request.setPaymentMethodFirst", "generic")}
									</div>
									<Link to="/profile?show=financials" className="profile-settings-nav-button"
									onClick={defaultLinkAction}>
										{messageMap("tutoring.request.goToProfile", "button")}
									</Link>
								</Fragment>
							)
						)
					)
				)
			}
		</Fragment>
	);
}

NewRequest.defaultProps = {
	mode: "newRequest"
};

NewRequest.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func,
	setModal1: PropTypes.func,
	setLoginModal: PropTypes.func,
	reRenderList: PropTypes.func.isRequired,
	mode: PropTypes.string,

	// editing props
	requestId: PropTypes.string,
	urgency: PropTypes.object,
	urgencyDuration: PropTypes.number,
	subjectsToTopicsMap: PropTypes.object,
	subject: PropTypes.string,
	topic: PropTypes.string,
	requestsGroupPreference: PropTypes.string,
	description: PropTypes.string,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(NewRequest);

