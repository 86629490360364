import React from 'react';
import { TUTORING } from "templates/utilities/PageSubMenu";

import { convertArrayToObject } from "../../Utilities/ObjectUtility";
import messageMap from "Utilities/MessageMaps";
import { CART_ITEM_CATEGORY_KEY, CART_ITEM_DESCRIPTION_KEY } from 'pages/Checkout/CheckoutUtility';
import { correctFormFieldsInRed } from 'templates/utilities/ModalUtilities';
// Tier constants
const FREE = "free";
const PRO = "pro";
const ENTERPRISE = "enterprise";

// Feature constants
export const FEATURE_MATRIX_ROW_LABELS = ["support",
  // "videos", "articles",
  "practiceProblems",
  TUTORING, 
  // "studyGroups", "practiceResources", "cosmetics", 
  "adFree"];
const FEATURES = convertArrayToObject(FEATURE_MATRIX_ROW_LABELS);

// Cart search param constants
export const SUBSCRIPTION_CART_PARAM_VALUE = "subscription";

// Exer brings you constants
export const EXER_INSTITUTE_BRINGS_YOU = ["topTeachers", "qualityVideos",
  "accessible", "comprehensive", "design", "fun"];
const exerBringsYouImageLookup = {
  [EXER_INSTITUTE_BRINGS_YOU[0]]: "apple",
  [EXER_INSTITUTE_BRINGS_YOU[1]]: "play",
  [EXER_INSTITUTE_BRINGS_YOU[2]]: "cash",
  [EXER_INSTITUTE_BRINGS_YOU[3]]: "bulb",
  [EXER_INSTITUTE_BRINGS_YOU[4]]: "browser",
  [EXER_INSTITUTE_BRINGS_YOU[5]]: "smilers",
};

// Exports
export const TIERS = {
  [FREE]: FREE,
  [PRO]: PRO,
  [ENTERPRISE]: ENTERPRISE
};

export const SUBSCRIPTION_CARD_ATTRIBUTES = ["buttonText", "features", "period", "price", "subHeader", "title"];// Price will be retreived in the future.

export const FEATURE_MATRIX_ROW_VALUES = {
  [TIERS[FREE]]: {
    [FEATURES.support]: "someDelay",
    // [FEATURES.videos]: "freePaid",
    // [FEATURES.articles]: "freePaid",
    [FEATURES.practiceProblems]: "unlimited",
    [FEATURES[TUTORING]]: "paid",
    // [FEATURES.studyGroups]: "unlimited",
    // [FEATURES.practiceResources]: "blank",
    // [FEATURES.cosmetics]: "paid",
    [FEATURES.adFree]: "blank"
  },
  [TIERS[PRO]]: {
    [FEATURES.support]: "someDelay",
    // [FEATURES.videos]: "140monthlyCredits",
    // [FEATURES.articles]: "140monthlyCredits",
    [FEATURES.practiceProblems]: "unlimited",
    [FEATURES[TUTORING]]: "proSubscription.tutoring",
    // [FEATURES.studyGroups]: "unlimited",
    // [FEATURES.practiceResources]: "proSubscription.practiceResources",
    // [FEATURES.cosmetics]: "proSubscription.cosmetics",
    [FEATURES.adFree]: "noAds"
  },
  [TIERS[ENTERPRISE]]: {
    [FEATURES.support]: "247",
    // [FEATURES.videos]: "unlimited",
    // [FEATURES.articles]: "unlimited",
    [FEATURES.practiceProblems]: "unlimited",
    [FEATURES[TUTORING]]: "unlimited",
    // [FEATURES.studyGroups]: "unlimited",
    // [FEATURES.practiceResources]: "unlimited",
    // [FEATURES.cosmetics]: "enterpriseSubscription.cosmetics",
    [FEATURES.adFree]: "noAds"
  }
};

export function importShinyNeuron(index) {
  return require(`assets/images/currencies/shiny_neurons/shiny_neuron_${index}.svg`);
}

export function getAlt(index) {
  return index + messageMap("shinyNeurons.hexagonEnclosedShinyNeurons", "image");
}

export function importSubscriptionIcons(category) {
  const imgKey = exerBringsYouImageLookup[category];
  return {
    thumbnailSrc: require(`assets/images/subscription/${imgKey}.svg`),
    thumbnailAlt: messageMap(`subscription.${imgKey}`, "image")
  }
}

const contactSalesInputKeys = ["name", "email", "phone", "organization", "potentialUsers"];

function getMessageMapValue(inputKey, type) {
  return messageMap(`subscription.contactSales.${inputKey}.${type}`, "generic")
}

// Abstracting constants for input types
const VALID_INPUT_TYPES = ["email", "phone"];

function getInputType(inputKey) {
  return VALID_INPUT_TYPES.includes(inputKey) ? inputKey : "";
}

function createStateObject(inputKey) {
  return { state: inputKey };
}

export const contactSalesInputsSchema = {
  stateProps: contactSalesInputKeys.map(createStateObject),
  
  inputs: contactSalesInputKeys.map((inputKey) => ({
    label: getMessageMapValue(inputKey, "label"),
    placeholder: getMessageMapValue(inputKey, "placeholder"),
    type: getInputType(inputKey),
    value: inputKey
  })),
  
  inputsToValidate: VALID_INPUT_TYPES.map(createStateObject), // Reuses logic
  
  errorMsg: correctFormFieldsInRed
};