import React from "react";

import messageMap from "Utilities/MessageMaps";

import { OVER, BETWEEN, UNDER } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";

import { getTimezoneOffset } from "templates/utilities/CalendarUtilities";

import emptyStarAsset from "assets/icons/ratings/empty-star.svg";
import halfStarAsset from "assets/icons/ratings/half-star.svg";
import fullStarAsset from "assets/icons/ratings/full-star.svg";


const SORT_RATING_BY = "sortBy";
export const HIGHEST_RATING = "highestRating";
export const LOWEST_RATING = "lowestRating";
export const MOST_RECENT_RATING = "recentRating";

export const ratingsSortMap = {
	[SORT_RATING_BY]: messageMap("tutoringPage.publicTutorProfile.sortBy", "generic"),
	[HIGHEST_RATING]: messageMap("tutoringPage.publicTutorProfile.highestRating", "generic"),
	[LOWEST_RATING]: messageMap("tutoringPage.publicTutorProfile.lowestRating", "generic"),
	[MOST_RECENT_RATING]: messageMap("tutoringPage.publicTutorProfile.recentRating", "generic")
};

export function normalizeExternalLink(extLink) {
	if ((!extLink.includes("https") && !extLink.includes("www")) || (!extLink.includes("www"))) {
		extLink = `https://www.${extLink}`;
	}
	else if (!extLink.includes("https")) {
		extLink = `https://${extLink}`;
	}

	return extLink;
}

export function calculateTutorAvgRating(reviews, customIconClass = "") {
	let ratingsDom = [];

	if (reviews.length) {
		const totalRatings = reviews.reduce((partialSum, review) => partialSum + review.rating, 0);
		const average = totalRatings / reviews.length;
	
		for (let i = 0; i < 5; ++i) {
			if (i <= average) {
				const starDiff = average - i;
				if (starDiff >= 1) {
					ratingsDom.push(
						<img key={`full${i}`} className={customIconClass} src={fullStarAsset} alt={messageMap("ratings.full", "image")} />
					);
				}
				else if (0.3 < starDiff && starDiff < 0.7) {
					ratingsDom.push(
						<img key={`partial${i}`} className={customIconClass} src={halfStarAsset} alt={messageMap("ratings.half", "image")} />
					);
				}
				else {
					ratingsDom.push(
						<img key={`empty${i}`} className={customIconClass} src={emptyStarAsset} alt={messageMap("ratings.empty", "image")} />
					);
				}
			}
			else {
				ratingsDom.push(
					<img key={`empty${i}`} className={customIconClass} src={emptyStarAsset} alt={messageMap("ratings.empty", "image")} />
				);
			}
		}
	}

	return ratingsDom;
}

export function getTutorRatesRange(teachingRates) {
	let minRate = Infinity;
	let maxRate = -1;
	teachingRates.split("|").forEach(topicRate => {
		const price = Number(topicRate.split("_")[1]);
		if (price < minRate) {
			minRate = price;
		}
		if (price > maxRate) {
			maxRate = price;
		}
	});

	return `$${minRate.toFixed(2)}-${maxRate.toFixed(2)} ${messageMap("tutoringPage.tutorList.pane.perHour", "generic")}`;
}

export function getTutorSchedule(schedules, tutorTimezone) {
	let scheduleDom = [];

	const [hourTimezoneDifference, addOrSubtract] = calculateTimezoneHourDifferenceAndRelativity(tutorTimezone);

	schedules.split("|").forEach(daySchedule => {
		const tuple = daySchedule.split(":");
		const day = tuple[0];
		let times = tuple[1];
		if (tuple.length === 4) {
			const splitTimes = tuple[2].split("_");

			const firstHour = adjustHourAccordingToTimezoneDifference(hourTimezoneDifference, addOrSubtract, Number(tuple[1]));
			const secondHour = adjustHourAccordingToTimezoneDifference(hourTimezoneDifference, addOrSubtract, Number(splitTimes[1]));

			let firstAdjustedHour = firstHour < 10 ? `0${firstHour}` : firstHour > 12 ? firstHour - 12 : firstHour;
			let firstMinutes = Number(splitTimes[0]);
			firstMinutes = (firstMinutes + "").length === 1 ? "0" + String(firstMinutes) : firstMinutes;
			let secondAdjustedHour = secondHour < 10 ? `0${secondHour}` : secondHour > 12 ? secondHour - 12 : secondHour;
			let secondMinutes = Number(tuple[3]);
			secondMinutes = (secondMinutes + "").length === 1 ? "0" + String(secondMinutes) : secondMinutes;

			let firstTimeAmPm = firstHour < 12 || firstHour > 24 || (firstHour === 24 && firstMinutes > 0) ? "AM" : "PM";
			let secondTimeAmPm = secondHour < 12 || secondHour > 24 || (secondHour === 24 && secondMinutes > 0) ? "AM" : "PM";

			times = `${firstAdjustedHour}:${firstMinutes}${firstTimeAmPm} - ${secondAdjustedHour}:${secondMinutes}${secondTimeAmPm}`;
		}
		else if (times === "unavailable") {
			times = messageMap("tutoringPage.tutorList.pane.unavailable", "generic");
		}

		scheduleDom.push(
			<div key={day} className="day-schedule">
				<div>
					{`${day}:`}
				</div>
				<div className="availability">
					{times}
				</div>
			</div>
		);
	});

	return scheduleDom;
}

/**
 * @description Calculates the time difference between the tutor and the current user
 * @param {String} tutorTimezone i.e. GMT+09:00
 */
export function calculateTimezoneHourDifferenceAndRelativity(tutorTimezone) {
	let timezoneRegEx = new RegExp("(?<=GMT).*");
	const tutorTimezoneOffset = Number(timezoneRegEx.exec(tutorTimezone)[0].split(":")[0]);
	const currentUserTimezoneOffset = Number(timezoneRegEx.exec(getTimezoneOffset())[0].split(":")[0]);

	// if tutor's timezone is ahead of current user's timezone, add the hours to adjust
	const addOrSubtract = tutorTimezoneOffset >= currentUserTimezoneOffset ? "add" : "subtract";
	const hourTimezoneDifference = Math.abs(tutorTimezoneOffset - currentUserTimezoneOffset);

	return [hourTimezoneDifference, addOrSubtract];
}

export function adjustHourAccordingToTimezoneDifference(hourTimezoneDifference, addOrSubtract, hourToAdjust) {
	if (addOrSubtract === "add") {
		hourToAdjust = hourToAdjust + hourTimezoneDifference;

		if (hourToAdjust > 24) {
			hourToAdjust -= 24;
		}
	}
	else {
		hourToAdjust = hourToAdjust - hourTimezoneDifference;

		if (hourToAdjust < 0) {
			hourToAdjust += 24;
		}
	}

	return hourToAdjust;
}

export function transformTeachingRatesStringToObject(teachingRatesString) {
	let rateObject = {};

	if (teachingRatesString && typeof teachingRatesString === "string") {
		teachingRatesString.split("|").forEach(rate => {
			const [key, value] = rate.split("_");
			rateObject[key] = Number(value);
		});
	}

	return rateObject;
}

export function transformCancellationPoliciesStringToObject(cancellationPolicyStr, isTutorOrTutee) {
	let cancellationPolicyObject = {};

	if (cancellationPolicyStr && typeof cancellationPolicyStr === "string") {
		if (isTutorOrTutee === "tutee") {
			cancellationPolicyStr = cancellationPolicyStr.slice(cancellationPolicyStr.indexOf("-") + 1);
		}

		cancellationPolicyStr.split("|").forEach(policy => {
			const policyRule = policy.split(":");
			const policyType = policyRule[0];
			cancellationPolicyObject[policyType] = {};
			const hoursPercentage = policyRule[1].split("_");

			if ([OVER, UNDER].includes(policyType)) {
				cancellationPolicyObject[policyType]["hours"] = hoursPercentage[0];
				cancellationPolicyObject[policyType][isTutorOrTutee === "tutee" ? "fee" : "percentage"] = hoursPercentage[1];
			}
			else if (policyType === BETWEEN) {
				cancellationPolicyObject[policyType] = {
					1: {
						hours: hoursPercentage[0]
					},
					2: {
						hours: hoursPercentage[1]
					},
					[isTutorOrTutee === "tutee" ? "fee" : "percentage"]: hoursPercentage[2]
				}
			}
		});
	}

	return cancellationPolicyObject;
}

export function getTutorCancellationPolicy(policyObject) {
	let policiesDom = [];

	for (const [key, value] of Object.entries(policyObject)) {
		let innerText = messageMap(`profilePage.tutoring.modal.cancellation.${key.toLowerCase()}`, "generic");
		if (key === OVER) {
			innerText = innerText + `
				 ${value.hours} ${messageMap("profilePage.tutoring.modal.cancellation.hoursIs", "generic")} 
			`;
		}
		else if (key === BETWEEN) {
			innerText = innerText + `
				 ${value[1].hours} ${messageMap("profilePage.tutoring.modal.cancellation.to", "generic")} ${value[2].hours} ${messageMap("profilePage.tutoring.modal.cancellation.hoursIs", "generic")} 
			`;
		}
		else if (key === UNDER) {
			innerText = innerText + `
				 ${value.hours} ${messageMap("profilePage.tutoring.modal.cancellation.hoursIs", "generic")} 
			`;
		}
		innerText = innerText + `${value.percentage}${messageMap("profilePage.tutoring.modal.cancellation.deductedCost", "generic")}`;

		policiesDom.push(
			<div key={key} className="policy">
				{innerText}
			</div>
		);
	}

	return policiesDom;
}
